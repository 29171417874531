import React from 'react';
import { t } from 'i18next'
import { Empty } from '@pankod/refine-antd';
import styled from "styled-components";

const NoDataDisplay = () => {
  return (
    <Empty description={<EmptyDataDescription>{t('shared:статусы.Нет данных')}</EmptyDataDescription>} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
  );
};

export default NoDataDisplay;

const EmptyDataDescription = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
    color:#00000073;
`
