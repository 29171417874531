import React, { useEffect } from 'react';
import { IAccount } from '../../ExchangePoints/interface';
import { DescriptionWrapper, SpaceBlock } from './styles';
import { Col, Row } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { cashFormatter } from '../../../shared/helpers/script';
import CopyToClipboard from '../../../shared/components/ui/text/CopyToClipboard';
import DescriptionItem from './DescriptionItem';
import { truncateNumber } from '../../../shared/helpers/truncateNumber';
import { Tag } from 'antd';

type propsType = {
  accountData: IAccount | null;
  userCompanyData: any;
};

const Description = ({ accountData, userCompanyData }: propsType) => {
  const { t } = useTranslation(['accounts', 'shared']);

  let recommendedBalance = '';
  let recommendedBalanceTag: string[] = [];

  if (accountData?.min_balance) {
    recommendedBalance = `>= ${
      accountData?.currency &&
      accountData?.currency.zeros_number &&
      cashFormatter(
        truncateNumber(
          accountData?.min_balance,
          +accountData?.currency.zeros_number
        )
      )
    } ${accountData?.currency?.shortname}`;
    if (
      Math.round(parseFloat(accountData?.balance) * 100) / 100 <
      Math.round(parseFloat(accountData?.min_balance) * 100) / 100
    ) {
      recommendedBalanceTag.push('min');
    }
  }
  if (accountData?.max_balance) {
    recommendedBalance = `<= ${
      accountData?.currency &&
      accountData?.currency.zeros_number &&
      cashFormatter(
        truncateNumber(
          accountData?.max_balance,
          +accountData?.currency.zeros_number
        )
      )
    } ${accountData?.currency?.shortname}`;
    if (
      Math.round(parseFloat(accountData?.balance) * 100) / 100 >
      Math.round(parseFloat(accountData?.max_balance) * 100) / 100
    ) {
      recommendedBalanceTag.push('max');
    }
  }

  if (accountData?.min_balance && accountData?.max_balance) {
    recommendedBalance = `${
      accountData?.currency &&
      accountData?.currency.zeros_number &&
      cashFormatter(
        truncateNumber(
          accountData?.min_balance,
          +accountData?.currency.zeros_number
        )
      )
    } ${accountData?.currency?.shortname} - ${
      accountData?.currency &&
      accountData?.currency.zeros_number &&
      cashFormatter(
        truncateNumber(
          accountData?.max_balance,
          +accountData?.currency.zeros_number
        )
      )
    } ${accountData?.currency?.shortname}`;
  }

  return (
    <DescriptionWrapper>
      <Row gutter={[32, 32]}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <DescriptionItem
                label={`${t('shared:таблицы.Остаток средств')}:`}
                value={
                  accountData?.currency &&
                  `${cashFormatter(
                    truncateNumber(
                      accountData?.balance,
                      +accountData?.currency.zeros_number
                    )
                  )} ${accountData?.currency?.shortname}`
                }
                boldText={true}
                tagValue={recommendedBalanceTag}
              />
            </Col>
            <Col span={24}>
              <SpaceBlock />
              <Col>
                <DescriptionItem
                  label={`${t('Полное название счёта')}:`}
                  value={accountData?.name ? accountData?.name : '-'}
                />
              </Col>
              <Col>
                <DescriptionItem
                  label={`${t('shared:тексты.Тип счёта')}:`}
                  value=""
                  showAccountTypeTag={true}
                  accountTypeTag={accountData?.type}
                />
              </Col>
              {!accountData?.is_external && (
                <Col>
                  <DescriptionItem
                    label={`${t('Реквизиты счёта')}:`}
                    value={accountData?.requisites}
                    afterContent={
                      <CopyToClipboard text={accountData?.requisites || ''} />
                    }
                  />
                </Col>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div style={{ height: '48px' }} />
          <Row>
            {!accountData?.convert_main_course_exist ? (
              <div>
                <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>
                  {t('shared:таблицы.Остаток средств')}:
                </span>
                <Tag
                  // key={type}
                  style={{
                    color: 'rgba(0, 0, 0, 0.88)',
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '23px',
                    marginLeft: '8px',
                  }}
                >
                  {t('shared:тексты.Нет информации по курсу')}
                </Tag>
              </div>
            ) : (
              <DescriptionItem
                label={`${t('shared:таблицы.Остаток средств')} (${
                  userCompanyData?.currency?.shortname !== undefined
                    ? userCompanyData?.currency?.shortname
                    : ' - '
                }):`}
                value={cashFormatter(
                  truncateNumber(
                    accountData?.convert_main ?? 0,
                    userCompanyData?.currency?.zeros_number
                  )
                )}
              />
            )}
          </Row>
          {accountData?.is_external && (
            <Row>
              <DescriptionItem
                label={`${t('Реквизиты счёта')}:`}
                value={accountData?.requisites}
                afterContent={
                  <CopyToClipboard text={accountData?.requisites || ''} />
                }
              />
            </Row>
          )}
          {!accountData?.is_external && (
            <Row>
              <DescriptionItem
                label={`${t('shared:тексты.Рекомендуемый остаток')}`}
                value={recommendedBalance}
              />
            </Row>
          )}
        </Col>
      </Row>
    </DescriptionWrapper>
  );
};

export default Description;
