import styled from 'styled-components';
import { CSSProperties } from 'react';

export const Flex = styled.div<{
  gap?: CSSProperties['gap'];
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  vertical?: boolean;
}>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  justify-content: ${({ justify }) => justify ?? 'space-between'};
  align-items: ${({ align }) => align ?? 'center'};
  gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
`;
