import { FormInstance } from '@pankod/refine-antd';
import { HttpError } from '@pankod/refine-core';
import { t } from 'i18next';

/**
 * Валидатор уникальности
 */
export const uniqValidator = (uniqMessage: string) => {
  if (!uniqMessage) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(uniqMessage));
};

/**
 * Валидатор 255 длины строки
 */

export const Length255Validator = (_: any, value: string) => {
  if (!value || value.length <= 255) {
    return Promise.resolve();
  }
  return Promise.reject(
    String(t('shared:тексты.Пожалуйста введите до 255 символов точка'))
  );
};

export const Length1000Validator = (_: any, value: string) => {
  if (!value || value.length <= 1000) {
    return Promise.resolve();
  }
  return Promise.reject(String(t('Пожалуйста введите до 1000 символов точка')));
};

/**
 * Валидатор 11 символов для телефона
 */

export const phoneLength15Validator = (_: any, value: string) => {
  const cleanedValue = value.replace(/\D/g, ''); // Удаляем все символы кроме цифр

  if (cleanedValue.length <= 15 && cleanedValue.length >= 11) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error(
      String(
        t(
          'shared:ошибки.Длина номера телефона должна быть от 11 до 15 символов точка'
        )
      )
    )
  );
};

export const phoneRegexValidator = (_: any, value: string) => {
  const regex = /^\+?\d{11,15}$/;
  if (!value || regex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    String(
      t(
        'shared:ошибки.Введите корректный номер телефона от 11 до 15 символов точка'
      )
    )
  );
};

export const emailRegexValidator = (_: any, value: string) => {
  const regex =
    /^[A-Za-z0-9!#$%&'*+\-/=?^_`{|}]+@(?!.*\.\.)*(?!.*\.?$)*[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)$/gm;
  if (!value || regex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    String(t('shared:ошибки.Пожалуйста введите корректный email точка'))
  );
};
// только цифры и одна запятая
export const DigitsAndCommasValidator = (_: any, value: string) => {
  const pattern = /^[0-9]\d*(,\d+)?$/;

  if (!value || pattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error(
      String(t('shared:ошибки.Пожалуйста введите только цифры и запятую точка'))
    )
  );
};

export const moreThenZeroValidator = (_: any, value: string | number) => {
  if (value?.toString()) {
    const number = +value.toString().replace(',', '.');
    return number > 0
      ? Promise.resolve()
      : Promise.reject(
          new Error(
            String(
              t('shared:ошибки.Пожалуйста введите значение больше нуля точка')
            )
          )
        );
  }
};

export const moreThenZeroValidatorForLockableInput = (
  _: any,
  value: string | number
) => {
  if (value?.toString()) {
    const number = +value.toString().replace(',', '.');
    return number > 0
      ? Promise.resolve()
      : Promise.reject(
          new Error(
            String(
              t('shared:ошибки.Пожалуйста введите значение больше нуля точка')
            )
          )
        );
  } else {
    return Promise.resolve();
  }
};

// ограничивает количество знаков после запятой
export const DecimalPlacesValidator = (
  _: any,
  value: string,
  maxDecimalPlaces: number | null
) => {
  const pattern = /^\d+(,\d+)?$/;

  if (!value || typeof maxDecimalPlaces !== 'number') {
    return Promise.resolve();
  }

  if (pattern.test(value)) {
    const decimalPart = value?.split(',')[1];
    if (decimalPart && decimalPart.length > maxDecimalPlaces) {
      return Promise.reject(
        new Error(
          `${t('shared:тексты.Пожалуйста введите до')} ${maxDecimalPlaces} ${t(
            'shared:тексты.знаков после запятой точка'
          )}`
        )
      );
    }
  }

  return Promise.resolve();
};

export const NegativeDecimalPlacesValidator = (
  _: any,
  value: string,
  maxDecimalPlaces: number | null
) => {
  const pattern = /^\-?\d+(,\d+)?$/;

  if (!value || typeof maxDecimalPlaces !== 'number') {
    return Promise.resolve();
  }

  if (pattern.test(value)) {
    const decimalPart = value?.split(',')[1];
    if (decimalPart && decimalPart.length > maxDecimalPlaces) {
      return Promise.reject(
        new Error(
          `${t('shared:тексты.Пожалуйста введите до')} ${maxDecimalPlaces} ${t(
            'shared:тексты.знаков после запятой точка'
          )}`
        )
      );
    }
  }

  return Promise.resolve();
};

export const onlyOneNumberValidator = (_: any, value: string) => {
  if (!value || /^\d$/gm.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error());
};

export const setFieldsUniqueError = (
  message: string,
  form: FormInstance,
  error: HttpError,
  field: string,
  errorMessage?: string
) => {
  if (
    errorMessage?.includes(
      'Unable to create Invite, user is already invited in your company'
    )
  ) {
    form.setFields([
      {
        name: field,
        errors: [message],
      },
    ]);
  }
  if (
    field === 'phone_number' &&
    error.response.data.errors[field][0].includes(
      'The phone number field must be a valid number.'
    )
  ) {
    form.setFields([
      {
        name: field,
        errors: [
          `${t('shared:ошибки.The phone number field must be a valid number')}`,
        ],
      },
    ]);
    // return;
  }
  if (field === 'email' && errorMessage?.includes('Unable to create Invite,')) {
    form.setFields([
      {
        name: field,
        errors: [`${t('shared:ошибки.Такая почта уже существует')}`],
      },
    ]);
    return;
  }
  if (
    field === 'email' &&
    errorMessage?.includes('The email must be a valid email address.')
  ) {
    form.setFields([
      {
        name: field,
        errors: [`${t('shared:ошибки.Введите корректный Email')}`],
      },
    ]);
    return;
  }
  if (
    errorMessage?.includes('Invite with this username has already been created')
  ) {
    form.setFields([
      {
        name: 'username',
        errors: [
          `${t('shared:ошибки.Пользователь с таким никнеймом уже приглашен')}`,
        ],
      },
    ]);
    return;
  }

  if (error.message?.includes('Promo code is required')) {
    form.setFields([
      {
        name: 'promo_code',
        errors: [message],
      },
    ]);
    return;
  }

  if (error.response.data.errors[field] && field !== 'email') {
    form.setFields([
      {
        name: field,
        errors: [message],
      },
    ]);
  }
};
