export const linksDropdownThemes = {
  finms: {
    token: {
      colorPrimary: '#202124',
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      Menu: {
        radiusItem: 0,
        colorItemBg: '',
        colorItemBgHover: '#383844',
        colorItemText: '#767687',
        colorItemTextHover: '#FBFBFF',
        colorItemTextSelected: '#FBFBFF',
        marginXXS: 0,
        marginXS: 0,
      },
      Typography: {
        colorLink: '#404348', //TODO Поменять как будет
        colorLinkHover: '#404348',
        colorLinkActive: '#404348',
        colorPrimary: '#FBFBFF',
        colorText: '#FBFBFF',
        colorTextHeading: '#FBFBFF',
      },
      Dropdown: {
        borderRadiusLG: 4,
        borderRadiusSM: 4,
        colorBgElevated: '#FFFFFF',
        colorText: '#000000E0',
        controlHeight: 48,
        boxShadowSecondary: 'transparent',
        paddingXXS: 0,
        paddingXS: 0,
        colorBgBase: '#FFFFFF',
        controlItemBgHover: '#F6FAFF',
        controlItemBgActive: '#F6FAFF',
        controlItemBgActiveHover: '#e4e8ed',
        borderItemColor: '#F5F5F5',
      },
    },
  },
  finmsmap: {},
  exchanger: {},
  account: {
    token: {
      colorPrimary: '#202124',
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      Menu: {
        radiusItem: 0,
        colorItemBg: '',
        colorItemBgHover: '#383844',
        colorItemText: '#767687',
        colorItemTextHover: '#FBFBFF',
        colorItemTextSelected: '#FBFBFF',
        marginXXS: 0,
        marginXS: 0,
      },
      Typography: {
        colorLink: '#404348', //TODO Поменять как будет
        colorLinkHover: '#404348',
        colorLinkActive: '#404348',
        colorPrimary: '#FBFBFF',
        colorText: '#FBFBFF',
        colorTextHeading: '#FBFBFF',
      },
      Dropdown: {
        borderRadiusLG: 4,
        borderRadiusSM: 4,
        colorBgElevated: '#383844',
        colorText: '#FBFBFF',
        controlHeight: 48,
        boxShadowSecondary: 'transparent',
        paddingXXS: 0,
        paddingXS: 0,
        colorBgBase: '#2B2B36',
        controlItemBgHover: '#20202E',
        controlItemBgActive: '#20202E',
        controlItemBgActiveHover: '#20202E',
      },
    },
  },
};
