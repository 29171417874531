import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { CurrencyType } from '../../currencyPairs/CurrencyType';

type TCurrencySelect = {
  currencyKey: string;
  search: string;
  page: number;
  isLoading: boolean;
  allCurrencies: CurrencyType[];
  total: number;
};

const DEFAULT_SELECT = {
  currencyKey: '',
  search: '',
  page: 1,
  isLoading: false,
  allCurrencies: [],
  total: 0,
};

type TChartState = {
  mainCurrencySelect: TCurrencySelect;
  lastCurrencySelect: TCurrencySelect;
  setSelectState: (
    selectType: 'main' | 'last',
    property: keyof TCurrencySelect,
    value: string | number | CurrencyType | boolean
  ) => void;
  resetState: () => void;
};
export const useChartState = create<TChartState>()(
  devtools(
    (set) => ({
      mainCurrencySelect: DEFAULT_SELECT,
      lastCurrencySelect: DEFAULT_SELECT,
      setSelectState: (type, property, value) =>
        set(
          produce((draft) => {
            if (type === 'main') {
              draft.mainCurrencySelect[property] = value;
            } else {
              draft.lastCurrencySelect[property] = value;
            }
          }),
          false,
          {
            type: 'useChartState => setSelectState',
          }
        ),
      resetState: () =>
        set(
          produce((draft) => {
            draft.mainCurrencySelect = DEFAULT_SELECT;
            draft.lastCurrencySelect = DEFAULT_SELECT;
          }),
          false,
          {
            type: 'useChartState => setSelectState',
          }
        ),
    }),
    {
      anonymousActionType: 'useChartState action',
      name: 'useChartState',
    }
  )
);
