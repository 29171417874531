import create from 'zustand';
import { devtools } from 'zustand/middleware';

type TMainCurrencyExchangePoint = {
  mainCurrency: string;
  changeMainCurrency: (newCurrency: string) => void;
};

export const useMainCurrencyExchangePoint =
  create<TMainCurrencyExchangePoint>()(
    devtools(
      (set) => ({
        mainCurrency: '',
        changeMainCurrency: (newCurrency) => {
          set({ mainCurrency: newCurrency });
        },
      }),
      {
        anonymousActionType: 'useMainCurrencyExchangePoint action',
        name: 'useMainCurrencyExchangePoint',
      }
    )
  );
