import { LabelCaption, LabelText, PopoverBlue } from './CreateOffer.styles';
import { TooltipPlacement } from 'antd/es/tooltip';

interface ITooltipLabel {
  tooltipText?: string;
  labelText: string;
  caption?: string;
  tooltipOnLabel?: boolean;
  placement?: TooltipPlacement;
  requiredField?: boolean;
}

const LabelWithPopover = ({
  tooltipText,
  labelText,
  caption,
  placement = 'topRight',
  requiredField = false,
  tooltipOnLabel = false,
}: ITooltipLabel) => {
  if (tooltipOnLabel) {
    return (
      <PopoverBlue
        overlayInnerStyle={{ maxWidth: '320px' }}
        placement={placement}
        content={tooltipText}
      >
        <LabelText required={requiredField}>{labelText}</LabelText>
      </PopoverBlue>
    );
  }
  return (
    <>
      <LabelText required={requiredField}>{labelText}</LabelText>

      <PopoverBlue
        overlayInnerStyle={{ maxWidth: '320px' }}
        placement={placement}
        content={tooltipText}
      >
        <LabelCaption>{caption}</LabelCaption>
      </PopoverBlue>
    </>
  );
};

export default LabelWithPopover;
