import styled from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';

const CloseCircleFilledStyle = () => {
  return <CloseCircleFilledStyled />;
};

export default CloseCircleFilledStyle;

const CloseCircleFilledStyled = styled(CloseCircleFilled)`
  color: #ff7875;
`;
