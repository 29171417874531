import { useCustom } from '@pankod/refine-core';
import { BackendFilters, IExchangePoint } from '../../../../../../interfaces';
import { useEffect, useState } from 'react';
import { API_URL } from 'packages/keycloak-client/constants';
import { IResponseShell } from 'shared/interfaces';
import { axios } from 'shared/exios';

export const useExchangePoints = (filters?: BackendFilters[]) => {
  const [totalCount, setTotalCount] = useState(0);
  const [exchangePoints, setExchangePoints] = useState<IExchangePoint[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);

  const getNextExchangePoints = async (currentPage: number = page + 1) => {
    setIsLoading(true);

    axios
      .post<IResponseShell<IExchangePoint[]>>(
        `${API_URL}/exchange-points/search`,
        {
          limit: 15,
          page: currentPage,
          includes: [{ relation: 'accounts' }, { relation: 'currency' }],
          filters,
        }
      )
      .then(({ data: res }) => {
        setMaxPage(res.meta.last_page);
        setExchangePoints((prev) =>
          currentPage === 1 ? res.data : [...prev, ...res.data]
        );
        setPage((prev) => prev + 1);
        setTotalCount(res.meta.total);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNextExchangePoints(1);
  }, []);

  return {
    exchangePoints,
    isLoading,
    getNextExchangePoints,
    page,
    maxPage,
    totalCount,
  };
};
