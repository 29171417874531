import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {axios} from '../exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {createOptions} from 'shared/helpers/script';

type TClientsState = {
  clientsData: any;
  clientsMeta: any;
  searchClients: ({setFilters}: TSearchClientsProps) => Promise<void>;
  setClients: ({clientsData, clientsMeta}: TSetClientsProps) => void;
};

type TSetClientsProps = {
  clientsData: any;
  clientsMeta: any;
};

type TSearchClientsProps = {
  setFilters: ({options, selectId, isFromRequest}: TSetFilters) => void;
};

type TSetFilters = {
  options: any;
  selectId: string;
  isFromRequest: boolean;
};

export const useClientsState = create<TClientsState>()(
  devtools(
    (set, get) => ({
      clientsData: [],
      clientsMeta: {},
      searchClients: async ({setFilters}: TSearchClientsProps) => {
        const response = await axios.post(`${API_URL}/clients/search`, {
          limit: 10000,
          page: 1,
        });
        const options = createOptions(response.data.data, 'useClientsState');
        localStorage.setItem('clients', JSON.stringify(options));
        setFilters({options, selectId: 'clients', isFromRequest: true});
        set(
          produce((draft) => {
            draft.setClients({
              clientsData: response.data.data,
              clientsMeta: response.data.meta,
            });
          }),
          false,
          {
            type: 'useClientsState => searchUserAccounts',
          }
        );
      },
      setClients: ({clientsData, clientsMeta}: TSetClientsProps) =>
        set(
          produce((draft) => {
            draft.clientsData = clientsData;
            draft.clientsMeta = clientsMeta;
          }),
          false,
          {
            type: 'useClientsState => setClients',
          }
        ),
    }),
    {
      anonymousActionType: 'useClientsState action',
      name: 'useClientsState',
    }
  )
);
