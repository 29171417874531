import { devtools } from 'zustand/middleware';
import create from 'zustand';
import { axios } from '../exios';
import {
  API_URL,
  CHAT_URL,
  EXCHANGE_MAP_API_URL,
  FINMS_URL_API,
} from '../../packages/keycloak-client/constants';
import { getCookieByName, setCookieByName } from '../helpers/controlCookies';
import { produce } from 'immer';
import { initChatWorker } from '../workers/chatWorker';

type TChatTokensState = {
  wssChannels: string[];
  getWSChatToken: () => Promise<string>;
  getPhexChatToken: () => Promise<string>;
  // getPartnerChatToken: () => Promise<string>;
};

export const useChatTokensState = create<TChatTokensState>()(
  devtools(
    (set, get) => ({
      wssChannels: [],
      getWSChatToken: async () => {
        try {
          const res = await fetch(`${CHAT_URL}/ws/token`, {
            method: 'get',
            headers: {
              Authorization: `Bearer ${getCookieByName(
                'phexChatToken'
              )?.replace('Bearer', '')}`,
            },
          });
          const data = await res.json();
          setCookieByName('wsChatToken', data?.token);
          initChatWorker({
            token: data?.token,
            debug: true,
            name: 'edenex',
            timeout: 10000,
            getToken: get().getWSChatToken,
          });
          set(
            produce((draft) => {
              draft.wssChannels = data?.channels;
            })
          );
          return data?.token;
        } catch (e) {
          console.error('getWSChatToken error', e);
        }
      },
      getPhexChatToken: async () => {
        try {
          const res = await axios.get(`${API_URL}/chats/get-token`);
          if (res?.data?.data?.access_token) {
            setCookieByName('phexChatToken', res?.data?.data?.access_token);
            return res?.data?.data?.access_token;
          }
        } catch (e) {
          // @ts-expect-error
          console.error('getPhexChatToken error:', e?.response?.data?.message);
        }
      },
      // рудимент в финмс
      // getPartnerChatToken: async () => {
      //     try {
      //         const res = await axios.get(`${EXCHANGE_MAP_API_URL}/partner/api/chats/get-token`)
      //         if (res?.data?.data?.access_token) {
      //             setCookieByName('partnerChatToken', res?.data?.data?.access_token)
      //             return res?.data?.data?.access_token;
      //         }
      //     } catch (e) {
      //         // @ts-expect-error
      //         console.error('getPartnerChatToken error:', e?.response?.data?.message)
      //     }
      // },
    }),
    {
      anonymousActionType: 'useChatTokensState action',
      name: 'useChatTokensState',
    }
  )
);
