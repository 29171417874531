import { AddressBlock } from './AddressBlock/AddressBlock';
import { CardStyled } from '../OfferRequestConfirm.styles';
import { useTranslation } from 'react-i18next';
import { Divider } from '@pankod/refine-antd';
import DateTimeBlock from './DateTimeBlock';
import InfoBlock from './InfoBlock';
import ReceiveCashBlock from './ReceiveCashBlock';

const BottomInfoContainer = () => {
  const { t } = useTranslation(['offers', 'shared']);
  return (
    <CardStyled>
      <InfoBlock title={t('shared:таблицы.Дата и время')}>
        <DateTimeBlock />
      </InfoBlock>
      <Divider type={'vertical'} />
      <InfoBlock title={t('Адрес встречи')}>
        <AddressBlock />
      </InfoBlock>
      <Divider type={'vertical'} />
      <InfoBlock title={t('shared:тексты.Как получить наличные')}>
        <ReceiveCashBlock />
      </InfoBlock>
    </CardStyled>
  );
};

export default BottomInfoContainer;
