import { axios } from '../../exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { AxiosResponse } from 'axios';

export const getOfferCommission = async (): Promise<string> => {
  try {
    const { data: commissionData } = await axios.get<
      AxiosResponse<{
        value: string;
      }>
    >(`${API_URL}/configs/offer_commission`);

    if (commissionData) {
      return commissionData.data.value || '0';
    }
  } catch (err) {
    console.error(err);
  }
  return '0';
};
