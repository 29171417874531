import {
  ContainerStyled,
  FieldStyled,
  LinkStyled,
  NameStyled,
  TitleStyled,
  ValueStyled,
} from './InfoCard.styles';
import { ReactNode } from 'react';
import { DefaultTFuncReturn } from 'i18next';

interface InfoCardProps {
  title: {
    icon?: ReactNode;
    name?: DefaultTFuncReturn;
  };
  fields: {
    name: DefaultTFuncReturn;
    icon?: ReactNode;
    link?: string;
    value: string | ReactNode;
  }[];
  bordered?: boolean;
}
const InfoField = ({
  link,
  children,
}: {
  link?: string;
  children?: ReactNode;
}) => {
  if (link) {
    return <LinkStyled to={link}>{children}</LinkStyled>;
  }
  return <ValueStyled>{children}</ValueStyled>;
};
export const InfoCard = (props: InfoCardProps) => {
  const { title, fields, bordered } = props;
  return (
    <ContainerStyled $bordered={bordered}>
      <FieldStyled>
        {title.icon}
        <TitleStyled>{title.name}</TitleStyled>
      </FieldStyled>
      {fields.map((field) => {
        return (
          <FieldStyled>
            <NameStyled>{field.name}:</NameStyled>
            <InfoField link={field.link}>{field.value}</InfoField>
          </FieldStyled>
        );
      })}
    </ContainerStyled>
  );
};
