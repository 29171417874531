import React from 'react';
import {Tabs} from 'antd';
import styled from "styled-components";

type TNotificationSubHeaderProps = {
    tabs: any
    DefaultTab: any,
    handleTabClick: (key: number | string) => void;
}

const NotificationSubHeader = ({tabs, DefaultTab, handleTabClick}: TNotificationSubHeaderProps) => {

    const tabBarStyle = {
        borderBottom: '1px solid transparent',
        color: '#fffff'
    }

    return (
        <TabsWrapperStyled>
            <TabsStyled
                tabBarStyle={tabBarStyle}
                defaultActiveKey={DefaultTab}
                onTabClick={(key) => handleTabClick(key)}
                items={tabs}
            />
        </TabsWrapperStyled>
    )
}

const TabsStyled = styled(Tabs)`
    margin: 20px 20px 0 20px;

    .ant-tabs-tab {
        color: white !important;
    }

    .ant-tabs-nav::before {
        border-bottom: none !important; /* Remove the border */
        background-color: white !important; /* Set the background color to white */
    }
    &&& .ant-badge {
        margin-left: 4px;
    }
`;

const TabsWrapperStyled = styled.div`
    width: 100%;
    border-bottom: 1px solid #FFFFFF1A;
`;

export default NotificationSubHeader;
