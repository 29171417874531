import { getPointDataReverse } from 'shared/api/nominatim/getPointDataReverse';
import { MapControls } from 'shared/components/ui/map/MapControls';
import useDebounce from 'shared/hooks/useDebounce';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAddressAutocompleteFeatures,
  useAddressAutoCompleteState,
} from '../model/useAddressAutocompleteState';
import { Spin } from '@pankod/refine-antd';
import Marker from '../assets/marker.svg';
import 'leaflet/dist/leaflet.css';
import {
  AutoCompleteStyled,
  ButtonStyled,
  InputWrapper,
  MapWrapper,
  NotFoundAddressContainerStyled,
  NotFoundAddressTextStyled,
  SpinWrapperStyled,
} from './SelectPointOnMap.styles';
import './SelectPointOnMap.css';
import L from 'leaflet';
import {
  MapContainer,
  ScaleControl,
  TileLayer,
  useMapEvent,
} from 'react-leaflet';


interface ISelectPointOnMapProps {
  currentPoint?: [number, number];
  initialPosition: [number, number];
  onSubmit: (arr: [number, number]) => void;
}

export const SelectPointOnMap = (props: ISelectPointOnMapProps) => {
  const { currentPoint, initialPosition, onSubmit } = props;

  const { t } = useTranslation(['dailyReports', 'shared']);

  const [map, setMap] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [point, setPoint] = useState<[number, number] | undefined>();
  const [curMarker, setCurMarker] = useState<any>(null);
  const [addressQuery, setAddressQuery] = useState('');

  const {
    features,
    addressAutocomplete,
    setCurrentAddress,
    setFeatures,
    setIsDebounceLoading,
    isDebounceLoading,
  } = useAddressAutoCompleteState();

  const mapRef = useRef<L.Map>(null);
  const animateRef = useRef(true);

  const debouncedAddress = useDebounce(addressQuery, 1000);

  const LocationFinderDummy = () => {
    useMapEvent('click', (e) => {
      getPointDataReverse([e.latlng.lat, e.latlng.lng], (data) => {
        setPoint([e.latlng.lat, e.latlng.lng]);
        if (!data.address.road) {
          setInputValue(
            `${Math.floor(e.latlng.lat * 10000) / 10000}, ${
              Math.floor(e.latlng.lng * 10000) / 10000
            }`
          );
        } else {
          setInputValue(
            `${data.address.road} ${
              !!data.address.house_number ? data.address.house_number : ''
            }`
          );
        }
        setAddressQuery(data.display_name);
      });
    });
    return null;
  };

  function SetViewOnClick({
    animateRef,
  }: {
    animateRef: React.MutableRefObject<boolean>;
  }) {
    const map = useMapEvent('click', (e) => {
      map.setView(e.latlng, map.getZoom(), {
        animate: animateRef.current || false,
      });
      setCurrentAddress({} as IAddressAutocompleteFeatures);
    });

    return null;
  }

  const handlePreventDefault = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'Enter' || e.key === 'ArrowUp') {
      e.preventDefault();
    }
  };

  const handleSetCurrentAddress = (value: string) => {
    const fondedPoint = features.find(
      (item) => item.properties.formatted === value
    );

    if (fondedPoint) {
      setCurrentAddress(fondedPoint);
      setAddressQuery(fondedPoint.properties.formatted);
    }
  };

  const handleSetAddressQuery = (value: string) => {
    setAddressQuery(value);
    setIsDebounceLoading(true);
    if (value === '') {
      setCurrentAddress({} as IAddressAutocompleteFeatures);
      setFeatures([]);
      setIsDebounceLoading(false);
    }
  };

  const onSaveButtonClick = () => {
    localStorage.setItem('isMapOpen', String(false));
    if (!!point) {
      onSubmit(point);
    }
  };

  useEffect(() => {
    addressAutocomplete(debouncedAddress);
  }, [debouncedAddress]);

  useEffect(() => {
    if (!point) {
      curMarker?.remove();
      mapRef?.current?.setView(initialPosition);
    } else {
      mapRef?.current?.setView(point);
    }
    const pointIcon = L.icon({
      iconUrl: Marker,
      iconAnchor: [17, 42],
      shadowRetinaUrl: '',
    });
    const timeout = setTimeout(() => {
      const mapInstance: L.Map | null = mapRef.current;
      if (mapInstance && point) {
        const marker = L.marker(point, { icon: pointIcon });
        curMarker && curMarker.remove();
        mapInstance.addLayer(marker);
        setCurMarker(marker);
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [point]);

  useLayoutEffect(() => {
    setPoint(currentPoint);
  }, [currentPoint]);

  const getMap = useCallback(() => {
    return (
      <>
        <MapContainer
          center={currentPoint ?? initialPosition}
          zoom={13}
          ref={mapRef}
          minZoom={3}
          maxBounds={[
            [90, 180], // South
            [-90, -180], // North
            [0, 0], // West
            [0, 0], // East
          ]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <LocationFinderDummy />
          <SetViewOnClick animateRef={animateRef} />
          <ScaleControl position="bottomright" />
        </MapContainer>
        <MapControls mapRef={mapRef} />
      </>
    );
  }, [currentPoint, initialPosition]);

  useEffect(() => {
    setMap(getMap());
  }, [getMap]);

  return (
    <MapWrapper>
      <InputWrapper>
        <AutoCompleteStyled
          value={addressQuery}
          onKeyDown={handlePreventDefault}
          onSelect={handleSetCurrentAddress}
          disabled
          notFoundContent={
            isDebounceLoading ? (
              <SpinWrapperStyled>
                <Spin />
              </SpinWrapperStyled>
            ) : (
              <NotFoundAddressContainerStyled>
                <NotFoundAddressTextStyled>
                  {t('shared:тексты.Не нашли адрес')}
                </NotFoundAddressTextStyled>
                <NotFoundAddressTextStyled>
                  {t(
                    'shared:тексты.Попробуйте отметить геолокацию вашего пункта обмена на карте'
                  )}
                </NotFoundAddressTextStyled>
              </NotFoundAddressContainerStyled>
            )
          }
          onSearch={handleSetAddressQuery}
          showSearch
          options={features?.map((item) => ({
            value: item.properties.formatted,
            label: item.properties.formatted,
          }))}
          placeholder={t('shared:модалки.Выберите адрес на карте')}
        />
        <ButtonStyled onClick={onSaveButtonClick} type="primary">
          {t('shared:кнопки.Сохранить')}
        </ButtonStyled>
      </InputWrapper>

      {map}
    </MapWrapper>
  );
};
