import create from 'zustand';
import {devtools} from "zustand/middleware";
import {produce} from "immer";
import {getFiltersForAnalyticsRequests} from "../../../shared/helpers/script";
import {axios} from "../../../shared/exios";
import {API_URL} from "../../../packages/keycloak-client/constants";
import {useAnalyticsInputState} from "./useAnalyticsInputState";

type TAnalyticsDebtsState = {
  searchAnalyticCompanyDebts: (currency?: string) => void;
  searchAnalyticEmployeesDebts: (currency?: string) => void;
  companyCurrency: string;
  isAnalyticCompanyDebtsLoading: boolean;
  isAnalyticEmployeesDebtsLoading: boolean;
  analyticCompanyDebtsBalance: TAnalyticCompanyDebts;
  analyticEmployeesDebtsBalance: TAnalyticEmployeesDebts;
};

type TAnalyticCompanyDebts = {
  result: string;
  status: string;
  company_currency: string;
  company_currency_zeros_number: number;
};

type TAnalyticEmployeesDebts = {
  result: string;
  status: string;
  company_currency: string;
  company_currency_zeros_number: number;
};

export const useAnalyticsDebtsState =
  create<TAnalyticsDebtsState>()(
    devtools(
      (set, get) => ({
        companyCurrency: '',
        isAnalyticCompanyDebtsLoading: true,
        isAnalyticEmployeesDebtsLoading: true,
        analyticCompanyDebtsBalance: {
          result: '',
          status: '',
          company_currency: '',
          company_currency_zeros_number: 8,
        },
        analyticEmployeesDebtsBalance: {
          result: '',
          status: '',
          company_currency: '',
          company_currency_zeros_number: 8,
        },
        searchAnalyticCompanyDebts: async (currency?: string) => {
          const filters = getFiltersForAnalyticsRequests({
            requestName: 'searchAnalyticDebts',
          });
          const response = await axios.post(
            `${API_URL}/analytic-company-debts/search`,
            {
              filters: !!filters?.length ? [...filters] : [],
              currency:
                currency ?? useAnalyticsInputState.getState().companyCurrency,
            },
            {
              headers: {
                Authorization: axios.defaults.headers.common['Authorization'],
              },
            }
          );
          set(
            produce((draft) => {
              if (response?.data?.data) {
                draft.analyticCompanyDebtsBalance = response.data.data;
                draft.isAnalyticCompanyDebtsLoading = false;
              }
              if (currency) {
                draft.companyCurrency = currency;
              }
            }),
            true,
            {
              type: 'useAnalyticsDebtsState => searchAnalyticCompanyDebts',
            }
          );
        },
        searchAnalyticEmployeesDebts: async (currency?: string) => {
          const filters = getFiltersForAnalyticsRequests({
            requestName: 'searchAnalyticDebts',
          });
          const response = await axios.post(
            `${API_URL}/analytic-user-account-debts/search`,
            {
              filters: !!filters?.length ? [...filters] : [],
              currency:
                currency ?? useAnalyticsInputState.getState().companyCurrency,
            },
            {
              headers: {
                Authorization: axios.defaults.headers.common['Authorization'],
              },
            }
          );
          set(
            produce((draft) => {
              if (response?.data?.data) {
                draft.analyticEmployeesDebtsBalance = response.data.data;
                draft.isAnalyticEmployeesDebtsLoading = false;
              }
              if (currency) {
                draft.companyCurrency = currency;
              }
            }),
            true,
            {
              type: 'useAnalyticsDebtsState => searchAnalyticEmployeesDebts',
            }
          );
        }
      }),
      {
        anonymousActionType: 'useAnalyticsDebtsState action',
        name: 'useAnalyticsDebtsState',
      }
    )
  );
