import styled from 'styled-components';

export const LanguagesListStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;
`;

export const LanguageStyled = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  border-radius: 6px;
  outline: 1px solid #0000000f;
  width: 45%;
  &:hover {
    outline: ${({ $isActive }) => !$isActive && '1px solid #00000026'};
  }

  outline: ${({ $isActive }) => $isActive && '1px solid #69b1ff'};
`;
