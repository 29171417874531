import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {axios} from '../../../shared/exios';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {getFiltersForAnalyticsRequests} from '../../../shared/helpers/script';

type TAnalyticsInputState = {
  analyticArrivals: TAnalyticArrivals;
  searchAnalyticArrivals: (currency?: string) => void;
  companyCurrency: string;
  isSearchAnalyticArrivalsLoading: boolean;
};

type TAnalyticArrivals = {
  result: string;
  status: string;
  company_currency: string;
};

export const useAnalyticsInputState = create<TAnalyticsInputState>()(
  devtools(
    (set, get) => ({
      isSearchAnalyticArrivalsLoading: true,
      companyCurrency: '',
      analyticArrivals: {
        result: '',
        status: '',
        company_currency: '',
      },
      searchAnalyticArrivals: async (currency?: string) => {
        const filters = getFiltersForAnalyticsRequests({
          requestName: 'searchAnalyticArrivals',
        });
        const response = await axios.post(
          `${API_URL}/analytic-arrival/search`,
          {
            filters: !!filters?.length ? [...filters] : [],
            currency:
              currency ?? useAnalyticsInputState.getState().companyCurrency,
          },
          {
            headers: {
              Authorization: axios.defaults.headers.common['Authorization'],
            },
          }
        );

        set(
          produce((draft) => {
            if (response?.data?.data) {
              draft.analyticArrivals = response?.data?.data;
              draft.isSearchAnalyticArrivalsLoading = false;
            }
            if (currency) {
              draft.companyCurrency = currency;
            }
          }),
          true,
          {
            type: 'useAnalyticsInputState => searchAnalyticArrivals',
          }
        );
      },
    }),
    {
      anonymousActionType: 'useAnalyticsInputState action',
      name: 'useAnalyticsInputState',
    }
  )
);
