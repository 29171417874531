import styled from "styled-components";
import React, {useMemo} from "react";
import {Button} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import FileOutlined from "./assets/FileOutlined.svg";
import Styles from "../../../index.module.css";

interface IPdfFileBlock {
    name: string;
    size: number;
    handleDeleteFile: () => void;
}

const PdfFileBlock = ({name, size, handleDeleteFile}: IPdfFileBlock) => {
    const conversionSize = +size * Math.pow(10, -6);

    const renderName = useMemo(() => {
        return name.length > 13 ? name.substring(0, 13) + '...' : name
    }, [name])

    const renderSize = useMemo(() => {
        return conversionSize > 1
            ? conversionSize
            : conversionSize * 1000
    }, [conversionSize])

    const renderNameSize = useMemo(() => {
        return +conversionSize > 1 ? 'MB' : 'KB'
    }, [conversionSize])

    return (
        <PdfFileWrapperStyled>
            <ButtonStyled
                size="small"
                icon={
                    <CloseOutlined className={Styles.file_preview__icon}/>
                }
                disabled={false}
                onClick={handleDeleteFile}
            />
            <PdfIconStyled src={FileOutlined} alt={'File'}/>
            <PdfFileInfoStyled>
                <PdfFileInfoNameStyled>
                    {renderName}
                </PdfFileInfoNameStyled>
                <PdfFileInfoSizeStyled>
                    PDF, {renderSize}{' '}
                    {renderNameSize}
                </PdfFileInfoSizeStyled>
            </PdfFileInfoStyled>
        </PdfFileWrapperStyled>
    );
};

export default PdfFileBlock;

const PdfFileWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    background: #767687;
    border-radius: 8px;
    padding: 6px 8px;
    position: relative;
`;

const PdfFileInfoStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const PdfFileInfoNameStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    color: #FFFFFF;
`;

const PdfFileInfoSizeStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    color: #FFFFFF;
`;

const PdfIconStyled = styled.img`
    width: 24px;
    height: 24px;
`;

const ButtonStyled = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #00000026;
    box-shadow: 0 2px 0 0 #FFFFFF0A;
`;

export {PdfFileBlock};
