import styled from 'styled-components';
import NotificationViewAllButton from '../NotificationViewAllButton';
import React, {Suspense} from 'react';
import {ErrorBoundary} from '../../../../ErrorBoundary';

const NotificationSelectFooterReadAllButton = React.lazy(
    () => import('./NotificationSelectFooterReadAll')
);

const NotificationSelectFooter = () => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<>Loading...</>}>
                <NotificationSelectFooterStyled>
                    <NotificationViewAllButton/>
                    <NotificationSelectFooterReadAllButton/>
                </NotificationSelectFooterStyled>
            </Suspense>
        </ErrorBoundary>
    );
};

const NotificationSelectFooterStyled = styled.div`
    max-height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 16px;
    margin-right: 16px;
    border-top: 1px solid #FFFFFF1A;
    padding-top: 20px;
    margin-top: auto;
    margin-bottom: 10px;
`;

export default NotificationSelectFooter;
