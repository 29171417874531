import { Flex } from '../../../../../../shared/components/styled';
import { Popover, Typography } from '@pankod/refine-antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { ReactNode } from 'react';

interface IProps {
  label: string;
  value: string;
  popoverText?: ReactNode;
}

const DateInfoItem = ({ label, value, popoverText }: IProps) => {
  return (
    <Flex gap={8}>
      <Typography.Text type={'secondary'}>{label}</Typography.Text>
      <Flex gap={4}>
        <Typography.Text>{value}</Typography.Text>
        {popoverText && (
          <Popover
            placement={'top'}
            color={'rgba(229, 249, 255, 1)'}
            content={popoverText}
            overlayInnerStyle={{ width: 300 }}
          >
            <InfoCircleTwoTone />
          </Popover>
        )}
      </Flex>
    </Flex>
  );
};

export default DateInfoItem;
