import React from 'react';
import { TagProps, TagType } from 'antd/es/tag';
import { Tag } from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
interface Props extends TagProps {
  children: React.ReactNode;
}
const BlueTag: React.FC<Props> = ({ children, ...props }) => {
  return (
    <TagWrapper>
      <Tag
        onClick={props.onClose}
        {...props}
        color="#E6F4FF"
        closeIcon={<CloseOutlinedStyled />}
      >
        <TagText>{children}</TagText>
      </Tag>
    </TagWrapper>
  );
};

export default BlueTag;

const TagText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
`;

const TagWrapper = styled.span`
  cursor: pointer;
  .ant-tag.ant-tag-has-color {
    border-color: #bae0ff !important;
  }
`;

const CloseOutlinedStyled = styled(CloseOutlined)`
  font-size: 12px;
  svg {
    color: #00000073;
  }
`;
