import { ReactComponent as LocateIcon} from '../assets/near_me.svg';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { RefObject } from 'react';
import { Button } from 'antd';


interface MapControlsProps {
  mapRef: RefObject<L.Map> | null;
}

export const MapControls = ({ mapRef }: MapControlsProps) => {
  const plusButtonClickHandler = () => {
    const element = document.querySelector(
      '.leaflet-control-zoom-in'
    ) as HTMLElement;
    element?.click();
  };

  const minusButtonClickHandler = () => {
    const element = document.querySelector(
      '.leaflet-control-zoom-out'
    ) as HTMLElement;
    element?.click();
  };

  const locateButtonClickHandler = () => {
    mapRef?.current?.locate({ setView: true });
  };
  return (
    <ControlsStyled>
      <ButtonStyled onClick={() => plusButtonClickHandler()}>
        <PlusOutlinedStyled />
      </ButtonStyled>
      <ButtonStyled onClick={() => minusButtonClickHandler()}>
        <MinusOutlinedStyled />
      </ButtonStyled>
      <ButtonStyled onClick={() => locateButtonClickHandler()}>
        <LocateIcon />
      </ButtonStyled>
    </ControlsStyled>
  );
};

const ButtonStyled = styled(Button)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 0;
`;
const PlusOutlinedStyled = styled(PlusOutlined)`
  font-size: 16px;
`;

const MinusOutlinedStyled = styled(MinusOutlined)`
  font-size: 16px;
`;

const ControlsStyled = styled.div`
  position: absolute;
  top: 42%;
  right: 24px;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 16px;
  }
`;
