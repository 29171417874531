'use client';
import { useResize } from 'shared/hooks/useResize';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useModalState } from '../model/useModalState';
import { ModalKeys } from '../model/enums';
import { ModalCancelOffer } from './ModalCancelOffer/ModalCancelOffer';
import { ModalOpenDispute } from './ModalOpenDispute/ModalOpenDispute';
import { ModalConfirmReceiptOfCash } from './ModalConfirmReceiptOfCash/ModalConfirmReceiptOfCash';
import { ModalChangeLanguage } from './ModalChangeLanguage/ModalChangeLanguage';
import { ModalSelectAddressOnMap } from './ModalSelectAddressOnMap/ModalSelectAddressOnMap';
import { ModalCancelTransaction } from './ModalCancelTransaction/ModalCancelTransaction';
import { ModalCancelTransactionFromConfirm } from './ModalCancelTransactionFromConfirm/ModalCancelTransactionFromConfirm';

export const RenderModal = ({
  currentModalKey,
  inStatisticPage = false,
}: {
  currentModalKey: ModalKeys;
  inStatisticPage?: boolean;
}) => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(currentModalKey)
  );
  const { isScreen991, isScreen480 } = useResize();

  const ref = useRef<HTMLBodyElement | null>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector('body');
    setMounted(true);
  }, []);

  // Выключает скролл при открытии модального окна
  useEffect(() => {
    if (isModalOpen && document) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    }
    if (!isModalOpen && document) {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [isModalOpen]);

  const modals = {
    [ModalKeys.ModalCancelOffer]: isModalOpen && <ModalCancelOffer />,
    [ModalKeys.ModalConfirmReceiptOfCash]: isModalOpen && (
      <ModalConfirmReceiptOfCash />
    ),
    [ModalKeys.ModalChangeLanguage]: isModalOpen && <ModalChangeLanguage />,
    [ModalKeys.ModalSelectAddressOnMap]: isModalOpen && (
      <ModalSelectAddressOnMap />
    ),
    [ModalKeys.ModalCancelTransaction]: isModalOpen && (
      <ModalCancelTransaction />
    ),
    [ModalKeys.ModalOpenDispute]: isModalOpen && <ModalOpenDispute />,
    [ModalKeys.ModalCancelTransactionFromConfirm]: isModalOpen && (
      <ModalCancelTransactionFromConfirm />
    ),
  };

  return mounted && ref.current
    ? createPortal(
        <ModalWrapperStyled
          id="modal-container"
          $isScreen980={isScreen991}
          $isScreen425={isScreen480}
        >
          {modals[currentModalKey]}
        </ModalWrapperStyled>,
        ref.current
      )
    : null;
};

interface IModalWrapperProps {
  $isScreen980?: boolean;
  $isScreen425?: boolean;
}

const ModalWrapperStyled = styled.div<IModalWrapperProps>`
  overflow-y: hidden;
  width: calc(100% - 15px);
  .ant-modal {
    min-width: ${({ $isScreen980, $isScreen425 }) => {
      if (!$isScreen980 && $isScreen425) {
        return 'calc(100% - 32px);';
      }
      if (!$isScreen425) {
        return '100%';
      }
    }};
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-modal-content {
    min-height: ${({ $isScreen980, $isScreen425 }) => {
      if (!$isScreen980 && $isScreen425) {
        return 'calc(100vh - 32px)';
      }
      if (!$isScreen425) {
        return '100vh';
      }
    }};
  }
  .ant-modal-content {
    border-radius: ${({ $isScreen425 }) => {
      if (!$isScreen425) {
        return '0';
      }
    }};
  }
`;
