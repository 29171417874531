import styled from 'styled-components';

// import '/node_modules/flag-icons/css/flag-icons.min.css';

interface ICountryFlagProps {
    countryCode: string;
    width?: number;
    height?: number;
    rounded?: boolean;
    border?: boolean;
}

export const CountryFlag = ({countryCode, width, height, rounded, border}: ICountryFlagProps) => {
    return (
        <FlagStyled
            $width={width}
            $height={height}
            $isRounded={rounded}
            $border={border}
            className={`fi fi-${countryCode?.toLowerCase()}
        ${width === height && 'fis'}`}></FlagStyled>
    );
};

interface FlagStyleProps {
    $width?: number;
    $height?: number;
    $isRounded?: boolean;
    $border?: boolean;
}

export const FlagStyled = styled.span<FlagStyleProps>`
    && {
        min-width: ${({$width}) => !!$width && $width + 'px'};
        min-height: ${({$height}) => !!$height && $height + 'px'};
    }

    ${({$isRounded}) => !!$isRounded && `border-radius: 50%;`};

    ${({$border}) => !!$border && `border: 0.5px solid #F5F5F5;`};
`;
