import { BellOutlined } from '@ant-design/icons';
import { Badge } from '@pankod/refine-antd';
import styled from 'styled-components';
import { ErrorBoundary } from '../../../ErrorBoundary';
import { Suspense } from 'react';

const NotificationBell = ({ total }: { total: number }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<>Loading...</>}>
        <BadgeStyled
          offset={[4, 0]}
          count={total}
          size={'default'}
          color={'#08979C'}
          showZero={true}
        >
          <BellOutlinedStyled />
        </BadgeStyled>
      </Suspense>
    </ErrorBoundary>
  );
};

const BellOutlinedStyled = styled(BellOutlined)`
  cursor: pointer;
  padding-top: 4px;
  padding-right: 3px;
  padding-bottom: 6px;
  direction: ltr;
  color: #ffffffcc;
  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 4px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const BadgeStyled = styled(Badge)`
  //direction: ltr;
`;

export default NotificationBell;
