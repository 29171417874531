import styled from "styled-components";
import {Statistic} from "antd";

const {Countdown} = Statistic;

export const ChatHeaderInfoTempTimerStyled = styled(Countdown)`
    .ant-statistic-content-value {
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        color: #767687;
        transition: ease-in-out 0.5s;
        cursor: pointer;

        &:hover {
            color: #4096FF;
        }
    }
`;
