import React, { useEffect, useState } from 'react';
import { useResize } from '../../../shared/hooks/useResize';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { TableStyled } from '../../../styles';
import { IconWrapper, TableWrapper } from '../ui/styles';
import { ExchangePointsTableType } from '../types/types';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone,
} from '../../../shared/components/icons';
import { Button, Popover } from '@pankod/refine-antd';
import { cashFormatter } from '../../../shared/helpers/script';
import { truncateNumber } from '../../../shared/helpers/truncateNumber';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { IExchangePoint } from '../../../interfaces';
import styled from 'styled-components';

type propsType = {
  exchangePointsData: IExchangePoint[];
};

const ExchangePointsTab = ({ exchangePointsData }: propsType) => {
  const { t } = useTranslation(['shared']);
  const { isScreen350, isScreen1024 } = useResize();

  const [tableData, setTableData] = useState<ExchangePointsTableType[]>([]);

  const navigate = useNavigate();
  const onPaymentsClick = (e: React.SyntheticEvent, id?: number) => {
    e.stopPropagation();
    navigate(`/accounts?id=${id}`);
  };
  const operationsColumns: ColumnsType<ExchangePointsTableType> = [
    {
      title: ' ',
      dataIndex: 'pointStatus',
      key: 'pointStatus',
      width: 10,
      render: (_, record) => {
        switch (record.pointStatus) {
          case 'works':
            return <IconWrapper>{CheckCircleTwoTone()}</IconWrapper>;
          case 'not_working':
            return (
              <Popover
                placement="topLeft"
                color="#E5F9FF"
                content={t('shared:статусы.Пункт обмена закрыт')}
              >
                <IconWrapper>{CloseCircleTwoTone()}</IconWrapper>
              </Popover>
            );
          default:
            return <IconWrapper>{MinusCircleTwoTone()}</IconWrapper>;
        }
      },
    },
    {
      title: t('shared:тексты.Пункт обмена'),
      dataIndex: 'exchangePoint',
      key: 'exchangePoint',
      width: 350,
      render: (_, record) => {
        return <div className="lineClamp">{record.pointName}</div>;
      },
    },
    {
      title: t('shared:таблицы.Остаток средств'),
      dataIndex: 'balanceOfFunds',
      key: 'balanceOfFunds',
      width: 350,
      render: (_, record) => {
        return (
          <div>
            {cashFormatter(
              truncateNumber(record?.balanceOfFunds || 0, record.zerosNumber)
            )}{' '}
            {record.currencyShortname}
          </div>
        );
      },
    },
    {
      title: t('shared:тексты.Адрес'),
      dataIndex: 'address',
      key: 'address',
      width: 350,
      render: (_, record) => (
        <AddressWrapper>
          <div className="lineClamp">{record.pointAddress || '-'}</div>
          <ButtonsWrapper>
            <ButtonStyled
              type="link"
              onClick={(e) => onPaymentsClick(e, record.key)}
              className="areaButton"
            >
              {t('shared:тексты.Счета')}
            </ButtonStyled>
          </ButtonsWrapper>
        </AddressWrapper>
      ),
    },
    {
      title: t(' '),
      dataIndex: 'dop',
      key: 'dop',
      width: 10,
      render: (_, record) => <div></div>,
    },
  ];

  const onRowRedirectHandler = (rowData: ExchangePointsTableType) => {
    return {
      onClick: (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/points/${rowData.key}`);
      },
    };
  };

  useEffect(() => {
    const table: ExchangePointsTableType[] = [];
    exchangePointsData?.map((el: any) => {
      table.push({
        key: el.id,
        pointName: el.name,
        balanceOfFunds: el.accounts_balance?.toString() ?? '',
        pointStatus: el.status,
        pointAddress: el.address || undefined,
        zerosNumber: el.currency?.zeros_number ?? 2,
        currencyShortname: el.currency?.shortname ?? '',
      });
    });
    setTableData(table);
  }, [exchangePointsData]);

  return (
    <TableWrapper>
      <TableStyled
        $isScreen350={isScreen350}
        $isScreen1024={isScreen1024}
        style={{ borderRadius: 0 }}
        dataSource={tableData}
        pagination={false}
        columns={operationsColumns as any[]}
        onRow={onRowRedirectHandler}
      />
    </TableWrapper>
  );
};

export default ExchangePointsTab;
const ButtonStyled = styled(Button)`
  padding: 0;
`;
const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  a:last-child {
    margin-left: 32px;
  }
`;
