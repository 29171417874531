import styled from 'styled-components';

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitleStyled = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 32px;
`;

export const CommentBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  .ant-input {
    min-height: 104px;
    max-height: 280px;
  }
  .ant-input-data-count {
    margin-top: 5px;
  }
`;

export const CommentTitleStyled = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 8px;
`;
export const DisabledReasonStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 8px 0 32px 0;
`;
export const ReasonTitleStyled = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`;
export const ReasonContentStyled = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
`;

export const HelperTextStyled = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 32px;
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;
