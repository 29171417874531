import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const NotificationSelectHeaderButtonSettings = React.lazy(
    () => import('./NotificationHeaderButtonSettings')
);

const NotificationSelectHeader = () => {
    const {t} = useTranslation(['notification', 'shared']);
    return (
        <NotificationSelectHeaderStyled>
            <NotificationSelectHeaderTextStyled>
                {t('shared:тексты.Уведомления')}
            </NotificationSelectHeaderTextStyled>
            <NotificationSelectHeaderButtonSettings/>
        </NotificationSelectHeaderStyled>
    );
};

const NotificationSelectHeaderStyled = styled.div`
    padding: 32px 24px 32px 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #FFFFFF1A;
`;

const NotificationSelectHeaderTextStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
    color: #FBFBFF;
`;

export default NotificationSelectHeader;
