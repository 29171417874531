import styled from 'styled-components';

export const ModalDescriptionStyled = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 8px;
`;
export const ModalTextStyled = styled.div``;
export const ModalTitleStyled = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;
export const ModalRowStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;
