import React from 'react';
import styled from "styled-components";

interface Props {
  htmlFor: string;
  title: string;
  text: string;
}
const FakeAntLabel: React.FC<Props> = ({htmlFor,title,text}) => {
  return (
    <LabelWrapper>
      <label htmlFor={htmlFor} title={title}>{text}</label>
    </LabelWrapper>
  );
};

export default FakeAntLabel;

const LabelWrapper = styled.div`
  margin: 0;
  padding: 0 0 4px;
  white-space: initial;
  text-align: start;
  display: flex;
  flex-grow: 0;
  overflow: hidden;
  vertical-align: middle;
  label{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
  }
  `
