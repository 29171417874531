import React, { ReactNode } from 'react';
import { Breadcrumb } from 'antd';
import { Button } from '@pankod/refine-antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import {
  ContainerStyled,
  DescriptionTitle,
  HeaderStyled,
  TitleStyled,
} from './Title.styles';
import { ButtonType } from 'antd/es/button/buttonHelpers';
import { DefaultTFuncReturn } from 'i18next';

interface TitleProps {
  children?: ReactNode;
  title?: string;
  description?: DefaultTFuncReturn;
  breadcrumbs?: ItemType[];
  button?: {
    title?: DefaultTFuncReturn;
    callback: () => void;
    type?: ButtonType;
    icon?: ReactNode;
  };
}

export const Title = (props: TitleProps) => {
  const { children, title, description, button, breadcrumbs } = props;
  return (
    <ContainerStyled>
      {breadcrumbs?.length && <Breadcrumb items={breadcrumbs} />}
      <HeaderStyled>
        <div>
          <TitleStyled>
            {children} {title}
          </TitleStyled>
          <DescriptionTitle>{description}</DescriptionTitle>
        </div>
        {button && (
          <Button
            onClick={button.callback}
            icon={button.icon}
            type={button.type || 'default'}
          >
            {button.title}
          </Button>
        )}
      </HeaderStyled>
    </ContainerStyled>
  );
};
