import React, { useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Popover } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

const CopyToClipboard: React.FC<{ text: string | number }> = ({ text }) => {
  const { t, i18n } = useTranslation(['shared']);
  const [isOpen, setIsOpen] = useState(false);
  const copyToClipboard = () => {
    setIsOpen((prev) => !prev);
    navigator.clipboard.writeText(text.toString());
    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  return (
    <Popover
      color="#E5F9FF"
      content={t('shared:тексты.Скопировано')}
      open={isOpen}
    >
      <CopyOutlined
        style={{
          fontSize: 16,
          cursor: 'pointer',
          color: '#00000073',
          marginLeft: 6,
        }}
        onClick={copyToClipboard}
      />
    </Popover>
  );
};

export default CopyToClipboard;
