import { Modal } from '@pankod/refine-antd';
import {
  CreateOfferExitModalDescStyled,
  CreateOfferExitModalTitleBlockStyled,
  CreateOfferExitModalTitleStyled,
} from './CreateOfferExitModal.styles';
import Warn from '../../assets/warn-yellow.svg';
import { useTranslation } from 'react-i18next';

interface ICreateOfferExitModal {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const CreateOfferExitModal = ({
  isOpen,
  onOk,
  onCancel,
}: ICreateOfferExitModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      closable={false}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      width={400}
      okText={t('shared:кнопки.Выйти')}
      cancelText={t('shared:кнопки.Отмена')}
      centered
    >
      <CreateOfferExitModalTitleBlockStyled>
        <img src={Warn} alt={'warn'} width={'24px'} height={'24px'} />
        <CreateOfferExitModalTitleStyled>
          {t('shared:тексты.Вы хотите выйти')}
        </CreateOfferExitModalTitleStyled>
      </CreateOfferExitModalTitleBlockStyled>
      <CreateOfferExitModalDescStyled>
        {t(
          'shared:тексты.Вы уверены запятая что хотите отменить создание оффера'
        )}
      </CreateOfferExitModalDescStyled>
    </Modal>
  );
};

export default CreateOfferExitModal;
