import {IChatMessage, IChatRepository, IChatSocket} from "../../interfaces"
import {IUser} from "interfaces"
import {createContext, PropsWithChildren, useCallback, useContext, useMemo} from "react"

/**
 * Default Valuts
 */

const DEFAULT_USER: IUser = {
    email: 'Loremgipsumgdolorgsi@mail.ru',
    emailVerified: true,
    firstName: 'Loremgipsumgdolorgsi',
    id: '123123123',
    lastName: '',
    username: 'Loremgipsumgdolorgsi'
}

/**
 * Context
 */

interface IChatContext {
    user: IUser

    emitMessage(message: string | File): Promise<void>

    onMessage(): Promise<IChatMessage>
}

const ChatContext = createContext<IChatContext>({
    user: DEFAULT_USER,
    emitMessage: async () => {
        //
    },
    onMessage: async () => {
        return {} as IChatMessage
    }
})
const useChatContext = (): IChatContext => useContext(ChatContext)

/**
 * Provider
 */

interface IChatProviderProps extends PropsWithChildren {
    socket: IChatSocket
    repository: IChatRepository
}

const ChatProvider = (props: IChatProviderProps): JSX.Element => {

    const {socket: {emit, on}} = props

    const handleEmitMessage = useCallback(async (message: string | File) => {
        await emit({message})
    }, [emit])

    const context = useMemo<IChatContext>(() => ({
        user: DEFAULT_USER,

        emitMessage: handleEmitMessage,
        onMessage: () => on()
    }), [handleEmitMessage, on])

    return (
        <ChatContext.Provider value={context}>
            {props.children}
        </ChatContext.Provider>
    )
}

export type {IChatContext}
export {ChatProvider, useChatContext}
