import { Tag } from '@pankod/refine-antd';
import { Link } from '@pankod/refine-react-router-v6';
import { useOperationsFiltersState } from '../../../shared/state/filtersState/useOperationsFiltersState';
import styled from 'styled-components';
import { ACCOUNT_URL } from 'packages/keycloak-client/constants';

function JsxCreator(
  items: { type: string; text: string }[],
  link: string,
  topic_key: string,
  notificationType: string
) {
  const setOperationsFilters = useOperationsFiltersState(
    (state) => state.setOperationsFilters
  );

  return (
    items?.length &&
    items?.map((item) => {
      const getFullLink = (notificationType: string) => {
        return notificationType === 'my_deals' ? ACCOUNT_URL + link : link;
      };

      if (item.type === 'link') {
        if (items[items.length - 1].type === 'exPointId') {
          return (
            <Link
              onClick={() => {
                setOperationsFilters(
                  { first_exchange_point_id: [+items[items.length - 1].text] },
                  true
                );
              }}
              to={getFullLink(notificationType)}
              target={'_blank'}
            >
              {item.text}
            </Link>
          );
        }

        //@ts-ignore
        if (topic_key === 'exchange') {
          return <a href={getFullLink(notificationType)}>{item.text}</a>;
        }

        return (
          <a
            target={'_blank'}
            rel="noreferrer"
            href={getFullLink(notificationType)}
          >
            {item.text}
          </a>
        );
      }

      if (item.type === 'corporate' || item.type === 'accountTypes') {
        return <DefaultTag>{item.text}</DefaultTag>;
      }
      if (
        item.type === 'opChainStatusWaiting' ||
        item.type === 'operationStatusWaiting'
      ) {
        return <OrangeTag>{item.text}</OrangeTag>;
      }
      if (
        item.type === 'opChainStatusClosed' ||
        item.type === 'operationStatusClosed'
      ) {
        return <CyanTag>{item.text}</CyanTag>;
      }
      if (
        item.type === 'opChainStatusCanceled' ||
        item.type === 'operationStatusCanceled'
      ) {
        return <RedTag>{item.text}</RedTag>;
      }
      return <></>;
    })
  );
}

export const useParser = (
  original: string,
  values: { type: string; text: string }[] | string,
  link: string,
  topic_key: string,
  notificationType: string
) => {
  let valuesData = values;

  // Проверяем, является ли values строкой
  if (typeof values === 'string') {
    // Проверяем, содержит ли строка валидный JSON
    try {
      // Если строка начинается с [ и заканчивается на ], пытаемся разобрать
      if (values.trim().startsWith('[') && values.trim().endsWith(']')) {
        valuesData = JSON.parse(values);
      } else {
        valuesData = []; // Можно вернуть пустой массив или что-то более подходящее
      }
    } catch (error) {
      valuesData = []; // Обработка ошибки парсинга
    }
  }

  // Проверяем, является ли valuesData массивом
  if (!Array.isArray(valuesData)) {
    return { texts: [''], elems: [] };
  }

  const textArr = original.split('%s');
  const arr = JsxCreator(valuesData, link, topic_key, notificationType);

  return { texts: textArr, elems: arr };
};

const RedTag = styled(Tag)`
  border-color: #58181c;
  background-color: #2a1215;
  color: #d32029;
`;
const OrangeTag = styled(Tag)`
  border-color: #593815;
  background-color: #2b1d11;
  color: #d87a16;
`;
const CyanTag = styled(Tag)`
  border-color: #144848;
  background-color: #112123;
  color: #13a8a8;
`;
const DefaultTag = styled(Tag)`
  border-color: #474755;
  background-color: #2b2b36;
  color: #ffffffd9;
`;
