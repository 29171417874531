import {axios} from '../../../../shared/exios';
import {API_URL} from '../../../../packages/keycloak-client/constants';

export type TData = {
  date: string;
  price: string;
};

export const timeType = {
  '2W': 1209600,
  W: 604800,
  '1D': 86400,
  '30': 1800,
  '10': 600,
};

export async function makeApiRequest({
                                       mainCurrency,
                                       lastCurrency,
                                       type,
                                       limit = 2000,
                                     }: {
  mainCurrency: string;
  lastCurrency: string;
  type?: number;
  limit?: number;
}) {
  if (!mainCurrency || !lastCurrency) return [];
  try {
    const response = await axios.post<TData>(`${API_URL}/get-chart`, {
      last: lastCurrency,
      main: mainCurrency,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(`CryptoCompare request error: ${error.status}`);
  }
}
