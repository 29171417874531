const Plus = ({
                  size = 24,
                  color = '#FBFBFF',
              }: {
    size?: number;
    color?: string;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
    >
        <g id="plus-lg">
            <path
                id="plus (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C12.8284 0 13.5 0.671573 13.5 1.5V10.5H22.5C23.3284 10.5 24 11.1716 24 12C24 12.8284 23.3284 13.5 22.5 13.5H13.5V22.5C13.5 23.3284 12.8284 24 12 24C11.1716 24 10.5 23.3284 10.5 22.5V13.5H1.5C0.671573 13.5 0 12.8284 0 12C0 11.1716 0.671573 10.5 1.5 10.5H10.5V1.5C10.5 0.671573 11.1716 0 12 0Z"
                fill={color}
            />
        </g>
    </svg>
);

export {Plus};
