import styled from 'styled-components';
import { Button } from '@pankod/refine-antd';

// TODO: Доработать под адаптив см layout/index

export const CreateOfferPageStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CreateOfferFooterStyled = styled.div`
  margin-left: -32px;
  display: flex;
  width: 100%;
  height: 88px;
  padding: 28px 64px;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid #29292b;
  background: #1f1f1f;
  position: fixed;
  bottom: 0;
  z-index: 1;
`;

export const CreateOfferFooterLeftStyled = styled.div``;

export const CreateOfferFooterLeftTextStyled = styled.div`
  color: #fff;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const CreateOfferFooterRightStyled = styled.div`
  display: flex;
  gap: 16px;
  margin-right: 15%;
`;

export const CreateOfferFooterCancelBtnStyled = styled(Button)`
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: #fff;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  transition: all 0.25s;
  &:hover {
    border: 1px solid #fff;
  }
`;

export const CreateOfferFooterCourseTextStyled = styled.div`
  font-family: 'SF Pro Text', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #36cfc9;
`;

export const CreateOfferFooterCommissionTextStyled = styled.div`
  color: #fff;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
