import { create } from 'zustand';
import { GEOAPIFY_API_KEY, GEOAPIFY_API_URL } from 'packages/keycloak-client/constants';


interface IProperties {
  country_code: string;
  housenumber: string;
  street: string;
  country: string;
  county: string;
  postcode: string;
  state: string;
  city: string;
  lon: number;
  lat: number;
  result_type: string;
  formatted: string;
  address_line1: string;
  address_line2: string;
  timezone: ITimezone;
  plus_code: string;
  place_id: string;
  region?: string;
  district?: string;
  suburb?: string;
  category?: string;
}

interface ITimezone {
  name: string;
  offset_STD: string;
  offset_STD_seconds: number;
  offset_DST: string;
  offset_DST_seconds: number;
}

export interface IAddressAutocompleteFeatures {
  properties: IProperties;
}

interface IUseAddressAutocompleteState {
  features: IAddressAutocompleteFeatures[];
  addressAutocomplete: (searchValue: string, countrycode?: string) => void;
  currentAddress: IAddressAutocompleteFeatures;
  setCurrentAddress: (value?: IAddressAutocompleteFeatures) => void;
  setFeatures: (value: IAddressAutocompleteFeatures[]) => void;
  isDebounceLoading: boolean;
  setIsDebounceLoading: (value: boolean) => void;
  getAddressDataByName: (addressData: string) => void;
}

export const useAddressAutoCompleteState = create<IUseAddressAutocompleteState>(
  (set) => ({
    features: [],
    currentAddress: {} as IAddressAutocompleteFeatures,
    isDebounceLoading: false,
    setIsDebounceLoading: (value) => set({ isDebounceLoading: value }),
    setCurrentAddress: (value) => {
      if (value) {
        set({ currentAddress: value });
      }
    },
    addressAutocomplete: async (searchValue: string, countrycode?: string) => {
      try {
        const requestOptions = {
          method: 'GET',
        };
        const params = new URLSearchParams({
          apiKey: GEOAPIFY_API_KEY,
          text: searchValue,
          lang: 'en',
          filter: `countrycode:${countrycode?.toLowerCase()}`,
        }).toString();

        const response = await fetch(
          `${GEOAPIFY_API_URL}/autocomplete?${params}`,
          requestOptions
        );
        const json = await response.json();
        set({ features: json.features });
      } catch (e) {
        console.log(e);
      } finally {
        set({ isDebounceLoading: false });
      }
    },
    getAddressDataByName: async (addressData) => {
      try {
        const requestOptions = {
          method: 'GET',
        };
        const params = new URLSearchParams({
          apiKey: GEOAPIFY_API_KEY,
          text: addressData,
          lang: 'en',
        }).toString();

        const response = await fetch(
          `${GEOAPIFY_API_URL}/search?${params}`,
          requestOptions
        );
        const json = await response.json();
        set({ currentAddress: json.features[0] });
      } catch (e) {
        console.log(e);
      } finally {
        set({ isDebounceLoading: false });
      }
    },
    setFeatures: (value: IAddressAutocompleteFeatures[]) =>
      set({ features: value }),
  })
);
