import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../../../ErrorBoundary';
import { Suspense } from 'react';
import { ACCOUNT_URL } from '../../../../../packages/keycloak-client/constants';

const NotificationViewAllButton = () => {
  const { t } = useTranslation(['shared']);

  const navigateToNotifications = () => {
    window.open(`${ACCOUNT_URL}/notifications`);
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<>Loading...</>}>
        <NotificationSelectFooterButton onClick={navigateToNotifications}>
          {t('shared:тексты.Смотреть все уведомления')}
        </NotificationSelectFooterButton>
      </Suspense>
    </ErrorBoundary>
  );
};

const NotificationSelectFooterButton = styled.button`
  font-family: SF Pro Text, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #ffffffcc;

  transition: 0.2s ease-in-out;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: rgb(54, 136, 255);
  }
`;

export default NotificationViewAllButton;
