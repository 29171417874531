import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import { Button, Popover, Tooltip } from '@pankod/refine-antd';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

type TColors = {
  red?: string
  blue?: string
  green?: string
}

interface OfferStatusProps {
  status: string
  colors?: TColors
}

interface OfferButtonsProps {
  status: string
  callbacks: {
    onCancel: () => void;
    onOpen: () => void;
  }
}

export const OfferStatus = (props: OfferStatusProps) => {

  const {t} = useTranslation(['shared'])

  const {status, colors = {}} = props
  if (status === colors.red) {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:тексты.Отменен')}
    >
      <CloseCircleFilled style={{color: '#FF7875', fontSize: '16px'}}/>
    </Popover>
  }
  if (status === colors.blue) {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:статусы.Активен')}
    >
      <ClockCircleFilled style={{color: '#69B1FF', fontSize: '16px'}}/>
    </Popover>
  }
  if (status === colors.green) {
    return <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={t('shared:тексты.Закрыт')}
    >
      <CheckCircleFilled style={{color: '#13C2C2', fontSize: '16px'}}/>
    </Popover>
  }
  return (
    <ClockCircleFilled style={{color: '#a3a3a3', fontSize: '16px'}}/>
  )
}
export const OfferButtons = (props: OfferButtonsProps
) => {
  const {t} = useTranslation(['shared'])
  const {status, callbacks} = props
  const [isOpen, setIsOpen] = useState(false)
  if (status === 'active' || status === 'done') {
    return (
      <>
        <Popover
          zIndex={1000000}
          color="#E5F9FF"
          placement="top"
          content={t('shared:тексты.Отменить оффер')}
        >
          <Button
            icon={<CloseOutlined style={{color: 'rgba(255, 77, 79, 1)'}} />}
            onClick={callbacks.onCancel}
            style={{minWidth: '40px', minHeight: '40px', border: 'none'}}
            type="text"
          />
        </Popover>
        {/*<Button*/}
        {/*  icon={<ToggleStyled*/}
        {/*    onClick={(e) => setIsOpen(prev => !prev)}*/}
        {/*    $isShow={isOpen}*/}
        {/*  >*/}
        {/*    <Popover*/}
        {/*      placement="top"*/}
        {/*      color={'#E5F9FF'}*/}
        {/*      style={{display: 'flex'}}*/}
        {/*      content={*/}
        {/*        isOpen*/}
        {/*          ? t('shared:тексты.Скрыть вложенные ДДС')*/}
        {/*          : t('shared:тексты.Показать вложенные ДДС')*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <DoubleRightOutlined/>*/}
        {/*    </Popover>*/}
        {/*  </ToggleStyled>*/}
        {/*  }*/}
        {/*  onClick={*/}
        {/*    callbacks.onOpen*/}
        {/*  }*/}
        {/*  style={{minWidth: '40px', minHeight: '40px', border: 'none'}}*/}
        {/*  type="text"*/}
        {/*/>*/}
      </>
    );
  }

  if (status === 'closed') {
    return (
      <>
        <Button
          icon={<CloseOutlined style={{color: 'rgba(255, 77, 79, 1)'}}/>}
          onClick={callbacks.onCancel}
          style={{minWidth: '40px', minHeight: '40px', border: 'none'}}
          type="text"
        />
        {/*<Button*/}
        {/*  icon={<ToggleStyled*/}
        {/*    onClick={(e) => setIsOpen(prev => !prev)}*/}
        {/*    $isShow={isOpen}*/}
        {/*  >*/}
        {/*    <Popover*/}
        {/*      placement="top"*/}
        {/*      color={'#E5F9FF'}*/}
        {/*      style={{display: 'flex'}}*/}
        {/*      content={*/}
        {/*        isOpen*/}
        {/*          ? t('shared:тексты.Скрыть вложенные ДДС')*/}
        {/*          : t('shared:тексты.Показать вложенные ДДС')*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <DoubleRightOutlined/>*/}
        {/*    </Popover>*/}
        {/*  </ToggleStyled>*/}
        {/*  }*/}
        {/*  onClick={callbacks.onOpen}*/}
        {/*  style={{minWidth: '40px', minHeight: '40px', border: 'none'}}*/}
        {/*  type="text"*/}
        {/*/>*/}
      </>
    );
  }

  if (status === 'declined') {
    return (
      // <Button
      //   icon={<CheckOutlined style={{color: 'rgba(19, 194, 194, 1)'}}/>}
      //   onClick={callbacks.onOpen}
      //   style={{minWidth: '40px', minHeight: '40px', border: 'none'}}
      //   type="text"
      // />
      <></>
    );
  }
  return <></>
};
export const ToggleStyled = styled.div<{
  $isShow: boolean;
}>`
  cursor: pointer;
  rotate: ${({ $isShow }) => ($isShow ? '270deg' : '90deg')};
  color: rgba(22, 119, 255, 1);
`;
