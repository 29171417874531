import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { configDev, configLocal, configProd, defaultkeysCookies } from '../constants';

export const useCookiesCustom = (keysCookies?: string[]) => {
  const [cookie, setCookie, removeCookie] = useCookies(keysCookies ?? defaultkeysCookies);

  const setCookieCustom = (key: string, value: any, config?: CookieSetOptions) => {
    setCookie(key, value, config ?? configDev);
    setCookie(key, value, config ?? configProd);
    setCookie(key, value, config ?? configLocal);
  };

  const removeCookieCustom = (key: string, config?: CookieSetOptions) => {
    removeCookie(key, config ?? configDev);
    removeCookie(key, config ?? configProd);
    removeCookie(key, config ?? configLocal);
  };

  return {
    cookie,
    setCookie: setCookieCustom,
    removeCookie: removeCookieCustom
  };
};
