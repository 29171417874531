interface DefaultIconProps {
  color?: string;
}
export const DefaultIcon = (props: DefaultIconProps) => {
  const { color = '#ffffff' } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0625 2.8125H3.125C2.95312 2.8125 2.8125 2.95312 2.8125 3.125V9.0625C2.8125 9.23438 2.95312 9.375 3.125 9.375H9.0625C9.23438 9.375 9.375 9.23438 9.375 9.0625V3.125C9.375 2.95312 9.23438 2.8125 9.0625 2.8125ZM8.04688 8.04688H4.14062V4.14062H8.04688V8.04688ZM16.875 2.8125H10.9375C10.7656 2.8125 10.625 2.95312 10.625 3.125V9.0625C10.625 9.23438 10.7656 9.375 10.9375 9.375H16.875C17.0469 9.375 17.1875 9.23438 17.1875 9.0625V3.125C17.1875 2.95312 17.0469 2.8125 16.875 2.8125ZM15.8594 8.04688H11.9531V4.14062H15.8594V8.04688ZM9.0625 10.625H3.125C2.95312 10.625 2.8125 10.7656 2.8125 10.9375V16.875C2.8125 17.0469 2.95312 17.1875 3.125 17.1875H9.0625C9.23438 17.1875 9.375 17.0469 9.375 16.875V10.9375C9.375 10.7656 9.23438 10.625 9.0625 10.625ZM8.04688 15.8594H4.14062V11.9531H8.04688V15.8594ZM16.875 10.625H10.9375C10.7656 10.625 10.625 10.7656 10.625 10.9375V16.875C10.625 17.0469 10.7656 17.1875 10.9375 17.1875H16.875C17.0469 17.1875 17.1875 17.0469 17.1875 16.875V10.9375C17.1875 10.7656 17.0469 10.625 16.875 10.625ZM15.8594 15.8594H11.9531V11.9531H15.8594V15.8594Z"
        fill={color}
        fillOpacity="0.65"
      />
    </svg>
  );
};
