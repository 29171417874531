export const EditStaffIcon = ({
  color = 'white',
  width = 16,
  height = 16,
  fillOpacity = 0.45,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.02656 11.75C4.05781 11.75 4.08906 11.7469 4.12031 11.7422L6.74844 11.2812C6.77969 11.275 6.80938 11.2609 6.83125 11.2375L13.4547 4.61406C13.4692 4.59961 13.4807 4.58244 13.4885 4.56354C13.4963 4.54463 13.5004 4.52437 13.5004 4.50391C13.5004 4.48344 13.4963 4.46318 13.4885 4.44428C13.4807 4.42538 13.4692 4.40821 13.4547 4.39375L10.8578 1.79531C10.8281 1.76563 10.7891 1.75 10.7469 1.75C10.7047 1.75 10.6656 1.76563 10.6359 1.79531L4.0125 8.41875C3.98906 8.44219 3.975 8.47031 3.96875 8.50156L3.50781 11.1297C3.49261 11.2134 3.49804 11.2995 3.52364 11.3807C3.54923 11.4618 3.59421 11.5355 3.65469 11.5953C3.75781 11.6953 3.8875 11.75 4.02656 11.75ZM5.07969 9.025L10.7469 3.35938L11.8922 4.50469L6.225 10.1703L4.83594 10.4156L5.07969 9.025ZM13.75 13.0625H2.25C1.97344 13.0625 1.75 13.2859 1.75 13.5625V14.125C1.75 14.1938 1.80625 14.25 1.875 14.25H14.125C14.1938 14.25 14.25 14.1938 14.25 14.125V13.5625C14.25 13.2859 14.0266 13.0625 13.75 13.0625Z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  </svg>
);
