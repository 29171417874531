import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { getCountChats } from '../api/chats/getCountChats';

export type TChatType = 'personal' | 'corporate';

interface IChatAsideState {
  chatType: TChatType;
  isLoadingChats: boolean;
  chatCounts: {
    personal: number;
    corporate: number;
  };
}
interface IChatAsideStore extends IChatAsideState {
  changeChatType: (newType: TChatType) => void;
  getChatCounts: () => void;
}

export const useChatAsideState = create<IChatAsideStore>()(
  devtools(
    (set, get) => ({
      chatType: 'personal',
      isLoadingChats: false,
      chatCounts: {
        personal: 0,
        corporate: 0
      },
      changeChatType: (newType) => {
        set({
          chatType: newType
        });
      },
      getChatCounts: async () => {
        const response = await getCountChats();

        set(
          produce((draft) => {
            draft.chatCounts.corporate =
              response?.exchange_point?.exchange_points.reduce(
                (prev, next) => prev + next.unread_message_count,
                0
              ) || 0;
            draft.chatCounts.personal = response?.user.unread_message_count
              ? response?.user.unread_message_count
              : 0;
          })
        );
      }
    }),
    {
      anonymousActionType: 'useChatAsideState action',
      name: 'useChatAsideState'
    }
  )
);
