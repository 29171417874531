import { useRequestConfirmState } from 'pages/offer-request-confirm/model/useRequestConfirmState';
import { useModalState } from 'shared/components/ui/RenderModal/model/useModalState';
import { getPointDataReverse } from 'shared/api/nominatim/getPointDataReverse';
import { useAddressAutocomplete } from 'shared/hooks/useAddressAutocomplete';
import { useCitiesAutocomplete } from 'shared/hooks/useCitiesAutocomplete';
import { CountrySelect } from 'shared/components/ui/select/CountrySelect';
import { ModalKeys } from 'shared/components/ui/RenderModal/model/enums';
import { RenderModal } from 'shared/components/ui/RenderModal';
import NoDataDisplay from 'shared/components/NoDataDisplay';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Select, Spin } from '@pankod/refine-antd';
import useDebounce from 'shared/hooks/useDebounce';
import { Flex } from 'shared/components/styled';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  SelectStyled,
  FlexWrapperStyled,
  MapButton,
  NotFoundAddressContainerStyled,
  NotFoundAddressTextStyled,
  SpinWrapperStyled,
} from './AddressBlock.styles';

export const AddressBlock = () => {
  const { openModal } = useModalState();
  const { t } = useTranslation(['offers', 'shared']);

  const requestData = useRequestConfirmState((state) => state.requestData);
  const formErros = useRequestConfirmState((state) => state.formErrors);
  const changeRequestDataField = useRequestConfirmState(
    (state) => state.changeRequestDataField
  );

  const [placeId, setPlaceId] = useState<string | null>(null);

  const [citySearch, setCitySearch] = useState('');
  const debounceCitySearch = useDebounce(citySearch, 100);

  const { cities, isLoading: isLoadingCities } = useCitiesAutocomplete(
    debounceCitySearch,
    requestData?.country || undefined
  );

  const [addressSearch, setAddressSearch] = useState('');
  const debounceAddressSearch = useDebounce(addressSearch, 100);

  const { locations, isLoading: isLoadingLocations } = useAddressAutocomplete(
    debounceAddressSearch,
    requestData?.country || undefined,
    requestData?.city || undefined,
    requestData?.state || undefined,
    placeId || undefined
  );

  useEffect(() => {
    if (requestData?.city) {
      setCitySearch(requestData?.city);
    }
  }, [requestData?.city]);

  useEffect(() => {
    if (requestData?.exchange_address) {
      setAddressSearch(requestData?.exchange_address);
    }
  }, [requestData?.exchange_address]);

  useEffect(() => {
    if ((requestData?.city, cities.length)) {
      const option = cities.find(
        (item) => item.properties.city === requestData?.city
      );
      setPlaceId(option?.properties.place_id || null);
    }
  }, [requestData?.city]);

  const handleMapSubmit = (arr: [number, number]) => {
    getPointDataReverse(arr, (data) => {
      changeRequestDataField('exchange_address', data.display_name);
      changeRequestDataField('latitude', arr[0].toString());
      changeRequestDataField('longitude', arr[1].toString());
      changeRequestDataField('country', data.address.country || 'No country');
      changeRequestDataField('state', data.address.state);
      changeRequestDataField('city', data.address.city || 'No city');
      setPlaceId(data.place_id);
    });
  };

  const handleOpenMapModal = () => {
    const coordinates =
      requestData?.latitude && requestData?.longitude
        ? [requestData?.latitude, requestData?.longitude]
        : undefined;
    openModal(ModalKeys.ModalSelectAddressOnMap, {
      onSubmit: handleMapSubmit,
      currentPoint: coordinates,
    });
  };

  const handelCountryChange = (
    value: string,
    _: { label: string; value: string }
  ) => {
    changeRequestDataField('country', value);
    changeRequestDataField('city', null);
    changeRequestDataField('state', null);
    changeRequestDataField('exchange_address', null);
    setPlaceId(null);
  };

  const handleChangeCitySearch = (value: string) => {
    setCitySearch(value);
  };

  const handleSelectCity = (value: string) => {
    const option = cities.find((item) => item.properties.city === value);
    changeRequestDataField('city', value);
    changeRequestDataField('state', option?.properties.state || null);
    changeRequestDataField('exchange_address', null);
  };

  // Тут можно остальные данные тоже в стейт запихать
  const handleSelectAddress = (value: unknown) => {
    if (typeof value !== 'string') return;
    changeRequestDataField('exchange_address', value);
    const location = locations?.find(
      (item) => item.properties?.formatted === value
    );
    changeRequestDataField(
      'longitude',
      location?.geometry?.coordinates[0] || null
    );
    changeRequestDataField(
      'latitude',
      location?.geometry?.coordinates[1] || null
    );
  };

  const handleChangeAddressSearch = (value: string) => {
    setAddressSearch(value);
  };

  return (
    <>
      <Flex gap={16}>
        <SelectStyled
          notFoundContent={
            isLoadingLocations ? (
              <SpinWrapperStyled>
                <Spin />
              </SpinWrapperStyled>
            ) : (
              <NotFoundAddressContainerStyled>
                <NotFoundAddressTextStyled>
                  {t('shared:тексты.Не нашли адрес')}
                </NotFoundAddressTextStyled>
                <NotFoundAddressTextStyled>
                  {t(
                    'shared:тексты.Попробуйте отметить геолокацию вашего пункта обмена на карте'
                  )}
                </NotFoundAddressTextStyled>
              </NotFoundAddressContainerStyled>
            )
          }
          status={
            formErros.address && !requestData?.exchange_address
              ? 'error'
              : undefined
          }
          showSearch
          onSearch={handleChangeAddressSearch}
          onSelect={handleSelectAddress}
          options={locations?.map((item) => ({
            value: item.properties?.formatted,
            label: item.properties?.formatted,
          }))}
          placeholder={t('Введите адрес встречи')}
          value={requestData?.exchange_address}
          disabled={!requestData?.city || !requestData?.country}
        />
        <MapButton
          type="primary"
          ghost
          icon={<EnvironmentOutlined />}
          onClick={handleOpenMapModal}
        />
      </Flex>

      <FlexWrapperStyled gap={16}>
        <CountrySelect
          className="locationSelect"
          id="country_code"
          value={requestData?.country ?? undefined}
          onSelect={handelCountryChange}
        />
        <Select
          className="locationSelect"
          id="city"
          filterOption={() => true}
          notFoundContent={
            isLoadingCities ? (
              <SpinWrapperStyled>
                <Spin />
              </SpinWrapperStyled>
            ) : (
              <NoDataDisplay />
            )
          }
          value={requestData?.city}
          onSearch={handleChangeCitySearch}
          onSelect={handleSelectCity}
          showSearch
          options={cities?.map(({ properties: { city, state, county } }) => ({
            value: city ?? county,
            label: `${city ?? county}, ${state}`,
          }))}
          placeholder={t('shared:тексты.Выберите город')}
        />
      </FlexWrapperStyled>

      <RenderModal currentModalKey={ModalKeys.ModalSelectAddressOnMap} />
    </>
  );
};
