import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {
  BackendFilters,
  IExchangePoint,
  IInclude,
  IMeta,
  IQuerySearchData,
  IUserAccount,
} from '../../../../../interfaces';
import {axios} from '../../../../../shared/exios';
import {API_URL} from '../../../../../packages/keycloak-client/constants';

const DEFAULT_LIMIT = 10;

export interface IUserAccountWithPoints extends IUserAccount {
  exchange_points: IExchangePoint[];
}

type TUserAccountState = {
  userAccountsData: IUserAccountWithPoints[];
  userAccountsMeta: IMeta | undefined;
  page: number;
  connectedToAccounts: string | undefined;
  filters: BackendFilters[];
  sort: any[];
  limit: number;
  isLoading: boolean;
  includes: IInclude[];
  setPage: (page: number) => void;
  setFilters: (filters: BackendFilters[]) => void;
  setSort: (sort: any[]) => void;
  userAccountsSearch: (connectedToAccounts?: string) => void;
  resetState: () => void;
  setIncludes: (includes: IInclude[]) => void;
};

const INITIAL_STATE = {
  userAccountsData: [],
  userAccountsMeta: undefined,
  page: 1,
  filters: [],
  sort: [],
  limit: DEFAULT_LIMIT,
  isLoading: false,
  includes: [{relation: 'role'}],
  connectedToAccounts: undefined,
};

export const useUserAccountState = create<TUserAccountState>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,
      setPage: (page) => {
        const searchUserAccounts = get().userAccountsSearch;
        set({page});
        searchUserAccounts();
      },
      setFilters: (filters) => {
        const userAccountsSearch = get().userAccountsSearch;
        set(
          produce((draft) => {
            draft.filters = filters;
          })
        );
        userAccountsSearch();
      },
      setSort: (sort) => {
        const userAccountsSearch = get().userAccountsSearch;
        set(
          produce((draft) => {
            draft.sort = sort;
          })
        );
        userAccountsSearch();
      },
      setIncludes: (includes) => {
        set({includes});
      },
      userAccountsSearch: async (connectedToAccounts) => {
        const {
          filters,
          sort,
          limit,
          page,
          includes,
          connectedToAccounts: connectedToAccountsFromState,
        } = get();
        set({isLoading: true});

        try {
          const {
            data: {data: userAccountsData, meta: userAccountsMeta},
          } = await axios.post<IQuerySearchData<IUserAccountWithPoints[]>>(
            `${API_URL}/user-accounts/search`,
            {
              connectedToAccount:
                connectedToAccounts || connectedToAccountsFromState,
              filters,
              sort,
              page,
              limit,
              includes,
            }
          );

          set({userAccountsData, userAccountsMeta});
        } catch (e: any) {
          console.log(e.message);
        } finally {
          set({isLoading: false, connectedToAccounts});
        }
      },
      resetState: () => {
        set({...INITIAL_STATE});
      },
    }),
    {
      anonymousActionType: 'useUserAccountState action',
      name: 'useUserAccountState',
    }
  )
);
