import { Select, SelectProps } from '@pankod/refine-antd';
import { useMemo } from 'react';
import countryList from 'react-select-country-list';

interface ICountrySelect {
  onSelect?: SelectProps<any, { label: string; value: string }>['onSelect'];
  onClear?: () => void;
  placeholder?: string;
  className?: string;
  id?: string;
  value?: string;
}

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const CountrySelect = ({
  onSelect,
  onClear,
  placeholder,
  className,
  id,
  value
}: ICountrySelect) => {
  const options = useMemo(() => countryList().getData(), []);
  return (
    <Select
      placeholder={placeholder}
      className={className}
      id={id}
      value={value}
      optionFilterProp="children"
      filterOption={filterOption}
      options={options}
      onSelect={onSelect}
      onClear={onClear}
      allowClear={!!onClear}
      showSearch
    />
  );
};
