import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { API_URL } from '../../packages/keycloak-client/constants';
import { createOptions } from 'shared/helpers/script';
import {
  ICurrency,
  TExchangePointPublication,
  TExchangePointStatus,
} from 'interfaces';
import { axios } from 'shared/exios';

type TExchangePointState = {
  exchangePointsData: TExchangePoint[];
  exchangePointsMeta: TExchangePointMeta;
  currentExchangePoint: TCurrentExchangePoint;
  searchExchangePoints: ({ setFilters }: any) => void;
  setExchangePoints: ({ exchangePointsData }: any) => void;
  setCurrentExchangePoint: (exchangePoint: TCurrentExchangePoint) => void;
  searchExchangePointsForAccounts: () => any;
  searchSingleExchangePoint: (id: number) => void;
  publishExchangePoint: (id: number) => Promise<void>;
  unpublishExchangePoint: (id: number) => Promise<void>;
  createExchangePointImage: (id: number, url?: string) => Promise<number>;
  deleteExchangePointImage: (ids: number[]) => Promise<void>;
  getPointImages: (id: number) => Promise<void>;
  _updateExchangePointPublishStatus: (
    id: number,
    status: TExchangePointPublication
  ) => Promise<void>;
};

export type TExchangePoint = {
  id: number;
  name: string;
  company_id: number;
  currency_id: number;
  address: string | null;
  timezone: string;
  daily_report_time: string;
  status: TExchangePointStatus;
  published_status: TExchangePointPublication;
  accounts_balance: string;
};

type TExchangePointCourse = {
  id: number;
  primary_currency_id: number;
  secondary_currency_id: number;
  sell_course: number | null;
  purchase_course: number | null;
  updated_at: string;
  // primary_currency_shortname?: string | null;
  // secondary_currency_shortname?: string | null;
  primary_currency: ICurrency;
  secondary_currency: ICurrency;
};

type TCurrentExchangePoint = {
  id: number;
  name: string;
  company_id: number;
  currency_id: number;
  address: string | null;
  timezone: string;
  daily_report_time: string;
  status: TExchangePointStatus;
  published_status: TExchangePointPublication;
  monitoring_status: 'disabled' | 'unavailable' | 'not_working' | 'working';
  is_publish_request_sent: boolean;
  accounts_balance: string;
  currency: {
    id: 0;
    company_id: 0;
    name: '';
    shortname: '';
    zeros_number: 0;
    status: '';
    type: '';
  };
  images: TImage[];
  contacts: TContact[] | null;
  description: string | null;
  courses: TExchangePointCourse[];
  // published_status: TExchangePointPublication;
  country: string | null;
  region: string | null;
  city: string | null;
  state: string | null;
  street: string | null;
  house_number: string | null;
  office: string | null;
  latitude: string | number | null;
  longitude: string | number | null;
  vip: boolean;
  is_trusted: boolean;
};

type TExchangePointMeta = {};

type TSetExchangePoints = {
  exchangePointsData: TExchangePoint[];
  exchangePointsMeta: TExchangePointMeta;
};

type TContact = {
  value: string;
};

export type TImage = {
  company_id: number;
  exchange_point_id: number;
  id: number;
  url: string;
};

export const useExchangePointState = create<TExchangePointState>()(
  devtools(
    (set, get) => ({
      exchangePointsData: [],
      exchangePointsMeta: {},
      currentExchangePoint: {
        id: 0,
        name: '',
        company_id: 0,
        currency_id: 0,
        address: null,
        timezone: '',
        daily_report_time: '',
        status: 'works',
        published_status: 'not_published',
        monitoring_status: 'not_working',
        is_publish_request_sent: false,
        accounts_balance: '',
        currency: {
          id: 0,
          company_id: 0,
          name: '',
          shortname: '',
          zeros_number: 0,
          status: '',
          type: '',
        },
        contacts: null,
        images: [],
        description: null,
        courses: [],
        country: null,
        region: null,
        city: null,
        state: null,
        street: null,
        house_number: null,
        office: null,
        latitude: null,
        longitude: null,
        vip: false,
        is_trusted: false,
      },
      searchExchangePoints: async ({ setFilters }: any) => {
        const response = await axios.post(`${API_URL}/exchange-points/search`, {
          limit: 10000,
          page: 1,
        });
        const options = createOptions(
          response.data.data,
          'useExchangePointState'
        );
        localStorage.setItem('exchangePoints', JSON.stringify(options));
        !!setFilters &&
          setFilters({
            options,
            selectId: 'exchangePoints',
            isFromRequest: true,
          });

        set(
          produce((draft) => {
            draft.setExchangePoints({
              exchangePointsData: response?.data.data!,
              // @ts-ignore
              exchangePointsMeta: response?.data.meta!,
            });
          })
        );
      },
      searchExchangePointsForAccounts: () => {
        return axios
          .post(`${API_URL}/exchange-points/search`, {
            limit: 10000,
            page: 1,
          })
          .then((response) => {
            const options: { key: number; label: string; value: number }[] =
              createOptions(
                response.data.data,
                'useExchangePointStateForAccounts'
              );
            return options;
          })
          .catch((error) => {
            console.error('Error fetching exchange points:', error);
          });
      },

      searchSingleExchangePoint: async (id: number) => {
        try {
          const response = await axios.post(
            `${API_URL}/exchange-points/search`,
            {
              includes: [
                { relation: 'currency' },
                { relation: 'currency' },
                { relation: 'contacts' },
                { relation: 'images' },
                { relation: 'courses' },
                { relation: 'exchangePointReports' },
                { relation: 'accounts.currency' },
                { relation: 'accounts.operations' },
                { relation: 'operationsChains' },
                { relation: 'courses.primaryCurrency' },
                { relation: 'courses.secondaryCurrency' },
              ],
              filters: id ? [{ field: 'id', operator: '=', value: id }] : [],
            }
          );

          const pointData: TCurrentExchangePoint = response?.data?.data![0];

          // try {
          //   await Promise.all(
          //     pointData.courses.map(async (course: TExchangePointCourse) => {
          //       const primaryCurrency = await axios.get(
          //         `${API_URL}/currency/${course?.primary_currency_id}`
          //       );
          //       const secondaryCurrency = await axios.get(
          //         `${API_URL}/currency/${course?.secondary_currency_id}`
          //       );
          //       course.primary_currency_shortname =
          //         primaryCurrency?.data?.data?.shortname;
          //       course.secondary_currency_shortname =
          //         secondaryCurrency?.data?.data?.shortname;
          //     })
          //   );
          // } catch (error) {
          //   console.error('searchSingleExchangePoint', error);
          // }

          set(
            produce((draft) => {
              draft.currentExchangePoint = pointData;
            })
          );

          // if (pointData.images.some((image) => image.url.endsWith('temp'))) {
          //   setTimeout(() => console.log('refetching')
          //   , 5000);
          // };
        } catch (error) {
          console.error('searchSingleExchangePoint', error);
        }
      },

      getPointImages: async (id: number) => {
        try {
          const response = await axios.post(
            `${API_URL}/exchange-point-images/search`,
            {
              filters: [
                { field: 'exchange_point_id', operator: '=', value: id },
              ],
            }
          );
          set(
            produce((draft) => {
              draft.currentExchangePoint.images = response.data
                .data as TImage[];
            })
          );
        } catch (error) {
          console.error('getPointImages', error);
        }
      },

      setCurrentExchangePoint: (exchangePoint: TCurrentExchangePoint) => {
        set(
          produce((draft) => {
            draft.currentExchangePoint = {
              ...draft.currentExchangePoint,
              ...exchangePoint,
            };
          })
        );
      },

      setExchangePoints: ({
        exchangePointsData,
        exchangePointsMeta,
      }: TSetExchangePoints) =>
        set(
          produce((draft) => {
            draft.exchangePointsData = exchangePointsData;
            draft.exchangePointsMeta = exchangePointsMeta;
          }),
          false,
          {
            type: 'useExchangePointState => setExchangePoints',
          }
        ),

      publishExchangePoint: async (id: number) => {
        await get()._updateExchangePointPublishStatus(id, 'published');
      },

      unpublishExchangePoint: async (id: number) => {
        await get()._updateExchangePointPublishStatus(id, 'not_published');
      },

      createExchangePointImage: async (id: number, url?: string) => {
        try {
          const response = await axios.post(
            `${API_URL}/exchange-point-images`,
            {
              exchange_point_id: id,
              url: url || 'temp',
            }
          );
          return Promise.resolve(response.data.data.id);
        } catch (error) {
          console.error('createExchangePointImage', error);
          return Promise.reject(error);
        }
      },

      deleteExchangePointImage: async (ids: number[]) => {
        try {
          await axios.delete(`${API_URL}/exchange-point-images/batch`, {
            data: {
              resources: ids,
            },
          });
        } catch (error) {
          console.error('deleteExchangePointImage', error);
        }
      },

      _updateExchangePointPublishStatus: async (
        id: number,
        status: TExchangePointPublication
      ): Promise<void> => {
        try {
          await axios.put(`${API_URL}/exchange-points/${id}`, {
            published_status: status,
          });
          set(
            produce((draft) => {
              draft.currentExchangePoint = {
                ...draft.currentExchangePoint,
                published_status: status,
              };
            })
          );
        } catch (error) {
          console.error('_updateExchangePointPublishStatus', error);
        }
      },
    }),
    {
      anonymousActionType: 'useExchangePointState action',
      name: 'exchangePointState',
    }
  )
);

export type { TCurrentExchangePoint };
