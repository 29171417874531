import { ThemeConfig } from 'antd';

export const mainTheme: ThemeConfig = {
  token: {
    colorPrimary: 'linear-gradient(270deg, #6E27F8 -85.22%, #20D9C0 164.99%)',
    fontFamily: 'Inter, sans-serif',
    colorTextHeading: '#000000E0',
    colorText: '#000000E0',
  },
  components: {
    Timeline: {
      colorBgContainer: '',
    },
    Button: {
      borderRadius: 8,
      colorBgContainerDisabled: '#0000000A',
      colorBorder: '#00000026',
      colorTextDisabled: '#00000040',
      colorLink: '#1677FF',
    },
    Checkbox: {
      colorPrimary: '#1677FF',
      colorBorderSecondary: '#202124',
      colorPrimaryBg: 'white',
      colorBorder: '#202124',
      colorPrimaryTextActive: 'white',
      borderRadiusSM: 4,
      borderRadiusLG: 4,
      borderRadius: 4,
      colorPrimaryHover: '#5a9fff',
    },
    Input: {
      borderRadius: 6,
      borderRadiusLG: 6,
      colorError: '#FF4D4F',
      colorErrorBorderHover: '#FF7875',
      colorErrorHover: '#FF7875',
      colorBorder: '#00000026',
      colorText: '#000000E0',
      colorBgContainer: '#FFFFFF',
      colorFillAlter: 'transparent',
      colorTextPlaceholder: '#00000040',
      controlHeight: 40,
      colorTextLabel: '#000000A6',
    },
    InputNumber: {
      borderRadius: 6,
      borderRadiusLG: 6,
      colorError: '#FF4D4F',
      colorErrorBorderHover: '#FF7875',
      colorErrorHover: '#FF7875',
      colorBorder: '#00000026',
      colorText: '#000000E0',
      colorBgContainer: '#FFFFFF',
      colorFillAlter: 'transparent',
      colorTextPlaceholder: '#00000040',
      controlHeight: 40,
    },
    Form: {
      colorError: '#FF4D4F',
    },
    Typography: {
      colorLink: '#FBFBFF',
      colorLinkHover: '#FBFBFF',
      colorLinkActive: '#FBFBFF',
      fontSizeHeading1: 40,
      colorPrimary: '#000000E0',
      colorText: '#000000E0',
      colorTextHeading: '#000000E0',
      fontSizeHeading2: 32,
    },

    Select: {
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorTextLabel: '#000000E0',
      colorTextDisabled: '#474755',
      borderRadius: 6,
      borderRadiusSM: 6,
      borderRadiusLG: 6,
      borderRadiusXS: 6,
      controlHeight: 40,
      colorBgContainer: '#FFFFFF',
      colorBorder: '#00000026',
      colorText: '#000000E0',
      colorTextPlaceholder: '#00000040',
      controlItemBgActive: '#E6F4FF',
      colorBgElevated: '#FFFFFF',
      colorTextSecondary: '#000000A6',
    },
    Steps: {
      controlHeightLG: 0,
    },
    Modal: {
      borderRadiusLG: 24,
      paddingMD: 40,
      paddingContentHorizontalLG: 40,
    },
    DatePicker: {
      controlHeight: 40,
      colorText: '#000000E0',
      colorTextDisabled: '#767687',
      colorPrimaryBorder: '',
      colorPrimary: '#1677FF',
      controlHeightSM: 28,
      colorBgContainer: '#FFFFFF',
      colorSplit: '#0000000F',
      colorTextPlaceholder: '#00000040',
      controlItemBgActive: '#E6F4FF',
    },
    Table: {
      colorText: '#000000E0',
      colorTextHeading: '#000000E0',
      colorBgContainer: '#FFFFFF',
      borderRadius: 24,
      borderRadiusLG: 24,
      colorBorderSecondary: '#0000000F',
      colorFillAlter: '#00000005',
      colorTextDisabled: 'rgba(43, 43, 54, 0.35)',
      colorSplit: '#0000000F',
      lineType: 'solid',
      lineWidth: 1,
      colorBorder: '#00000026',
      controlItemBgActive: 'transparent',
      controlItemBgActiveHover: '#00000005',
      controlItemBgHover: 'transparent',
    },
    Tabs: {
      colorPrimary: '#1677FF',
      padding: 12,
    },
    Badge: {
      colorBorderBg: 'inherit',
    },
    Collapse: {
      colorBorder: '#474755',
      colorBgContainer: '#1C1C27',
    },
    QRCode: {
      borderRadius: 0,
      borderRadiusLG: 0,
    },
    // Dropdown: {
    //   colorBgElevated: '#FFFFFF',
    //   colorText: '#000000E0',
    //   controlItemBgHover: '#0000000A',
    // },

    Tooltip: {
      paddingXS: 12,
      paddingSM: 8,
      colorBgDefault: '#474755',
    },
    Menu: {
      radiusItem: 0,
      colorItemBg: '',
      colorItemBgHover: '#413D5080',
      colorItemBgActive: '#413D5080',
      colorItemBgSelected: '#413D5080',
      controlItemBgActiveHover: '#413D5080',
      colorItemText: '#767687',
      colorItemTextHover: '#FBFBFF',
      colorItemTextSelected: '#FBFBFF',
      marginXXS: 0,
      marginXS: 0,
    },
    Pagination: {
      colorPrimary: '#1677ff',
    },
    Spin: {
      colorPrimary: '#1677ff',
    },
    Radio: {
      colorText: '#000000E0',
      colorBgContainer: '#FFFFFF',
      colorPrimary: '#1677FF',
    },
    Segmented: {
      motionDurationMid: '0.1s',
      motionDurationSlow: '0.1s',
    },
    Switch: {
      colorPrimary: '#1677FF',
      colorPrimaryHover: '#4794ff',
    },
  },
};

export const createInviteTheme: ThemeConfig = {
  components: {
    Input: {
      controlHeight: 32,
    },
    InputNumber: {
      controlHeight: 32,
    },
  },
};
