import {PropsWithChildren} from 'react'
import {ChatContainerStyled} from './assets/styles';

interface IChatContentProps extends PropsWithChildren {
}

const ChatContent = (props: IChatContentProps): JSX.Element => {
    return (
        <ChatContainerStyled>
            {props.children}
        </ChatContainerStyled>
    )
}

export {ChatContent}
