import styled from 'styled-components';
import { Button, Spin } from '@pankod/refine-antd';
import { Card, Input, Segmented, Select } from '@pankod/refine-antd';
import { Flex } from '../../../shared/components/styled';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;
export const ContainerElemsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 32px 32px 0 32px;
`;
export const CardsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 16px;
  height: 96px;
  width: 100%;
  padding: 24px 32px 32px 32px;
  border-left: 1px solid #29292b;
  background: #1f1f1f;
`;
export const ButtonBackStyled = styled(Button)`
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff40;
`;

export const InputStyled = styled(Input)`
  width: 180px;
  &.ant-input-affix-wrapper {
    box-shadow: none;
  }
`;

export const CardStyled = styled(Card)`
  padding: 24px 8px;

  & .ant-card-body {
    padding: 0;
    display: flex;
  }

  & .ant-divider {
    height: auto;
    margin: 0;
  }
`;

export const InfoBlockWrapper = styled(Flex)`
  padding: 0 24px;
`;

export const ButtonLinkStyled = styled(Button)`
  padding: 0;
`;

export const SegmentedStyled = styled(Segmented)`
  & .ant-segmented-item-label {
    padding: 2px 25px;
  }
`;

export const LoaderStyled = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
