import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from '@pankod/refine-react-router-v6';
import { useAccount } from './api/useAccount';
import { truncateText } from '../../shared/helpers/script';
import { Breadcrumb } from 'antd';
import {
  AboutAccountCard,
  AboutAccountWrapper,
  AlertContainer,
  TabsStyled,
  AlertStyled,
} from './ui/styles';
import { Col, Row } from '@pankod/refine-antd';
import Header from './ui/Header';
import Description from './ui/Description';
import { AdaptiveFullScreenLoader } from '../../shared/components/full-screen-loader';
import MMFTab from './tabs/MMFTab';
import ExchangePointsTab from './tabs/ExchangePointsTab';
import useOperations from '../chains/useOperations';
import { IOperation } from '../../interfaces';
import { useExchangePoints } from '../Staff/ui/Modals/InviteModal/api/useExchangePoints';
import StaffTab from './tabs/StaffTab';
import { useUserAccountState } from './tabs/StaffTab/model/useUserAccountsState';

const Index = () => {
  const { t } = useTranslation(['shared']);

  let { id } = useParams();
  const userAccountsSearch = useUserAccountState(
    (state) => state.userAccountsSearch
  );
  const userAccountsMeta = useUserAccountState(
    (state) => state.userAccountsMeta
  );
  const resetUserAccountsState = useUserAccountState(
    (state) => state.resetState
  );

  const { accountData, isFetching, userCompanyData } = useAccount({ id });
  const { exchangePoints, totalCount: totalCountExchangePoints } =
    useExchangePoints([
      {
        field: 'accounts.id',
        operator: '=',
        value: id,
      },
    ]);
  const {
    operationsData,
    totalCount: totalCountOperations,
    isLoading,
    refetch,
  } = useOperations({
    page: 1,
    filter: [
      {
        field: 'account_id',
        operator: '=',
        value: id,
      },
    ],
  });

  const [activeTab, setActiveTab] = useState<'MMF' | 'exchange-points'>(
    'exchange-points'
  );

  const breadcrumbItems = [
    {
      title: <Link to="/accounts">{t('shared:тексты.Счета')}</Link>,
      key: 'account',
    },
    {
      title:
        (accountData?.shortname && truncateText(accountData?.shortname)) ||
        t('shared:статусы.Нет данных'),
      key: 'accounts',
    },
  ];

  const tabs = useMemo(() => {
    return [
      {
        label: `${t(
          'shared:тексты.Пункты обмена'
        )} (${totalCountExchangePoints})`,
        key: 'exchange-points',
        children: '',
      },
      {
        label: `${t('shared:тексты.ДДС')} (${totalCountOperations})`,
        key: 'MMF',
        children: '',
      },
      {
        label: `${t('shared:тексты.Сотрудники')} (${
          userAccountsMeta?.total ?? 0
        })`,
        key: 'userAccounts',
        children: <StaffTab />,
      },
    ];
  }, [t, exchangePoints, operationsData, userAccountsMeta]);

  const refetchAll = async () => {
    await Promise.all([refetch()]);
  };

  useEffect(() => {
    userAccountsSearch(id);

    return () => {
      resetUserAccountsState();
    };
  }, []);

  return (
    <>
      {isFetching ? (
        <Row style={{ marginTop: '10%' }}>
          <AdaptiveFullScreenLoader />
        </Row>
      ) : (
        <AboutAccountWrapper>
          <Breadcrumb
            items={breadcrumbItems}
            style={{
              fontSize: '14px',
              fontFamily: 'Inter',
              fontWeight: 400,
              lineHeight: '22px',
              marginBottom: '16px',
            }}
          />
          <AboutAccountCard>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Header accountData={accountData} />
              </Col>
              <Col span={24}>
                <Description
                  accountData={accountData}
                  userCompanyData={userCompanyData}
                />
              </Col>
            </Row>
            {accountData?.is_external && (
              <AlertStyled
                message={t('shared:тексты.Имеет связь с живым кошельком')}
                description={t(
                  'shared:тексты.Этот счёт связан с балансом Edenex.'
                )}
                closable
                type="info"
                showIcon
              />
            )}
            <TabsStyled
              items={tabs}
              activeKey={activeTab}
              tabBarGutter={48}
              onChange={(key: string) =>
                setActiveTab(key as 'MMF' | 'exchange-points')
              }
              style={{ marginBottom: 24 }}
            />
            {activeTab === 'exchange-points' && (
              <ExchangePointsTab exchangePointsData={exchangePoints} />
            )}
            {activeTab === 'MMF' && (
              <MMFTab
                // parentOperationStatus={operationsData.}
                refetchAll={refetchAll}
                accountOperationsData={operationsData as IOperation[]}
              />
            )}
          </AboutAccountCard>
        </AboutAccountWrapper>
      )}
    </>
  );
};

export default Index;
