import { useEffect, useState } from 'react';
import { axios } from 'shared/exios';
import { MAP_API_URL } from '../../../../packages/keycloak-client/constants';
import { IResponseShell } from '../../../../shared/interfaces';

interface ISystemCurrency {
  code: string;
  currency_key: string;
  currency_type: string;
  payment_system_name: string;
  type: 'cash';
  use_count: number;
}

export const useCurrency = (searchCurrency: string) => {
  const [currencies, setCurrencies] = useState<ISystemCurrency[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);

  const getNextCurrencies = async (currentPage: number = page + 1) => {
    setIsLoading(true);

    axios
      .post<IResponseShell<ISystemCurrency[]>>(
        `${MAP_API_URL}/payment-system-currency/search`,
        {
          limit: 15,
          page: currentPage,
          filters: [{ field: 'type', operator: '=', value: 'cash' }],
          search: { value: searchCurrency, case_sensitive: false },
        }
      )
      .then(({ data: res }) => {
        setMaxPage(res.meta.last_page);
        setCurrencies((prev) =>
          currentPage === 1 ? res.data : [...prev, ...res.data]
        );
        setPage((prev) => prev + 1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNextCurrencies(1);
  }, [searchCurrency]);

  return {
    currencies,
    isLoading,
    getNextCurrencies,
    page,
    maxPage,
  };
};
