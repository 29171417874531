import {axios} from 'packages/keycloak-client/exios';

export function getPointDataReverse(
    point: [number, number],
    callback: (data: any) => void,
) {
    if (point) {
        axios
            .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${point[0]}&lon=${point[1]}`,
                {headers: {'Accept-Language': 'en'}}
            )
            .then((res) => {
                callback(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    } else {
        console.error('getPointDataReverse - no point', point);
        callback({});
    }
}
