import NotificationViewAllButton from './NotificationViewAllButton';
import styled from 'styled-components';
import fb from '../../../../../packages/fb/fb';
import {cloneDeep} from 'lodash';
import {Suspense, useLayoutEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorBoundary} from '../../../ErrorBoundary';
import emptyNotifList from './emptyNotifList.svg';

const NotificationSelectEmpty = () => {
    const {t} = useTranslation(['shared']);
    const [allNotificationsCount, setAllNotificationsCount] = useState(0);
    const [usersDataNotificationsCount, setUsersDataNotificationsCount] =
        useState(0);
    const [operationsNotificationsCount, setOperationsNotificationsCount] =
        useState(0);
    const [problemsNotificationsCount, setProblemsNotificationsCount] =
        useState(0);
    const [exchangeNotificationsCount, setExchangeNotificationsCount] =
        useState(0);
    const [subordinatesNotificationsCount, setSubordinatesNotificationsCount] =
        useState(0);
    const [accountsNotificationsCount, setAccountsNotificationsCount] =
        useState(0);
    const [myCompanyNotificationsCount, setMyCompanyNotificationsCount] =
        useState(0);

    const checkNotificationsCounts = () => {
        return !!(
            allNotificationsCount ||
            usersDataNotificationsCount ||
            operationsNotificationsCount ||
            problemsNotificationsCount ||
            exchangeNotificationsCount ||
            subordinatesNotificationsCount ||
            accountsNotificationsCount ||
            myCompanyNotificationsCount
        );
    };

    useLayoutEffect(() => {
        const notificationsCountListener = fb.addListener(
            'notificationsCount',
            (data: any) => {
                console.log('data?.allNotificationsCount', data?.allNotificationsCount);
                setAllNotificationsCount(cloneDeep(data?.allNotificationsCount));
                setUsersDataNotificationsCount(
                    cloneDeep(data?.usersDataNotificationsCount)
                );
                setOperationsNotificationsCount(
                    cloneDeep(data?.operationsNotificationsCount)
                );
                setProblemsNotificationsCount(
                    cloneDeep(data?.problemsNotificationsCount)
                );
                setExchangeNotificationsCount(
                    cloneDeep(data?.exchangeNotificationsCount)
                );
                setSubordinatesNotificationsCount(
                    cloneDeep(data?.subordinatesNotificationsCount)
                );
                setAccountsNotificationsCount(
                    cloneDeep(data?.accountsNotificationsCount)
                );
                setMyCompanyNotificationsCount(
                    cloneDeep(data?.myCompanyNotificationsCount)
                );
            }
        );

        return () => {
            notificationsCountListener.remove();
        };
    }, []);

    return (
        <ErrorBoundary>
            <Suspense fallback={<>Loading...</>}>
                <NotificationSelectEmptyStyled>
                    <NotificationSelectEmptyBodyStyled>
                        <EmptyNotificationImageStyled
                            src={emptyNotifList}
                            alt="emptyNotifList"
                        />
                        <NotificationSelectEmptyBodyTextStyled>
                            {t('shared:тексты.Нет новых уведомлений')}
                        </NotificationSelectEmptyBodyTextStyled>
                        <NotificationViewAllButton/>
                    </NotificationSelectEmptyBodyStyled>
                </NotificationSelectEmptyStyled>
            </Suspense>
        </ErrorBoundary>
    );
};

const EmptyNotificationImageStyled = styled.img`
    width: 103px;
    height: 81px;
`;

const NotificationSelectEmptyStyled = styled.div`
    min-height: 250px;
    height: 100%;
    max-height: 67vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NotificationSelectEmptyBodyStyled = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    align-items: center;
`;

const NotificationSelectEmptyBodyTextStyled = styled.div`
    font-family: SF Pro Text, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF80;
`;

export default NotificationSelectEmpty;
