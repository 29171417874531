import {
  DescriptionItemRow,
  DescriptionLabel,
  DescriptionValue,
} from './styles';
import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Popover } from '@pankod/refine-antd';

interface Props {
  direction?: 'horizontal' | 'vertical' | undefined;
  label: string;
  value: string | null | undefined;
  afterContent?: React.ReactNode;
  boldText?: boolean;
  showAccountTypeTag?: boolean;
  accountTypeTag?: string;
  tagValue?: string[];
}

const DescriptionItem = ({
  afterContent,
  direction = 'horizontal',
  label,
  value,
  boldText,
  showAccountTypeTag,
  accountTypeTag,
  tagValue,
}: Props) => {
  const { t } = useTranslation(['shared']);

  const accountTypeMap: any = {
    bank: t('shared:типы.Счёт в банке'),
    physical_safe: t('shared:типы.Физическое хранилище'),
    electronic_wallet: t('shared:типы.Электронный кошелёк'),
  };

  return (
    <DescriptionItemRow $direction={direction}>
      <DescriptionLabel>{label}</DescriptionLabel>
      {showAccountTypeTag && accountTypeTag && (
        <DescriptionValue>
          <Tag style={{ display: 'inline-flex' }} key={accountTypeTag}>
            {accountTypeMap[accountTypeTag]}
          </Tag>
        </DescriptionValue>
      )}
      {value && (
        <DescriptionValue
          style={{
            fontWeight: boldText ? 'bold' : 'normal',
            fontSize: boldText ? '16px' : '14px',
            display: 'flex',
            alignItems: 'center',
          }}
          $direction={direction}
        >
          {value}
          <div style={{ marginLeft: '8px' }}>
            {tagValue?.map((el, i) => {
              return (
                <Popover
                  key={i}
                  color={'#FFF1F0'}
                  trigger="hover"
                  content={
                    el === 'min'
                      ? t('shared:тексты.Остаток ниже рекомендуемого')
                      : t('shared:тексты.Остаток выше рекомендуемого')
                  }
                >
                  <Tag
                    color="red"
                    style={{
                      height: '22px',
                      color: 'rgba(245,34,45,0.66)',
                    }}
                  >
                    {el}
                  </Tag>
                </Popover>
              );
            })}
          </div>
        </DescriptionValue>
      )}
      {value && afterContent}
      {!value && !showAccountTypeTag && '-'}
    </DescriptionItemRow>
  );
};

export default DescriptionItem;
