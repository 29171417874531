import { useEffect, useState } from 'react';
import {
  GEOAPIFY_API_KEY,
  GEOAPIFY_API_URL,
} from '../../packages/keycloak-client/constants';

interface ICity {
  properties: {
    state: string;
    city: string;
    county: string;
    place_id: string;
  };
  geometry: {
    coordinates: [number, number];
  };
}
interface IGeoapifyResponse<T> {
  type: string;
  features: T[];
}

export const useCitiesAutocomplete = (citySearch?: string, countryCode?: string) => {
  const [cities, setCities] = useState<ICity[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCities = async () => {
    if (!citySearch || !countryCode) return;

    setCities([]);
    setIsLoading(true);
    const params = new URLSearchParams({
      apiKey: GEOAPIFY_API_KEY,
      text: citySearch,
      type: 'city',
      lang: 'en',
      filter: `countrycode:${countryCode.toLowerCase()}`,
    }).toString();

    fetch(`${GEOAPIFY_API_URL}/autocomplete?${params}`)
      .then((res) => res.json())
      .then(({ features }: IGeoapifyResponse<ICity>) => {
        // фильтрация для отсечения дублей
        const uniqCities = features.reduce((acc: ICity[], currentCity) => {
          if (
            !acc.some(
              ({ properties: { city, state } }: ICity) =>
                city + state ===
                (currentCity.properties.city ?? currentCity.properties.county) +
                  currentCity.properties.state
            )
          ) {
            acc.push(currentCity);
          }
          return acc;
        }, []);
        setCities(uniqCities);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    (async function () {
      await getCities();
    })();
  }, [citySearch, countryCode]);

  return {
    cities,
    isLoading,
  };
};
