import { SelectPointOnMap } from 'shared/components/ui/map/SelectPointOnMap';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { Modal } from '@pankod/refine-antd';
import styled from 'styled-components';

export const ModalSelectAddressOnMap = () => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalSelectAddressOnMap)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const closeAddressModal = () => {
    closeModal(ModalKeys.ModalSelectAddressOnMap);
  };

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalSelectAddressOnMap).options
  );

  const handleOnPointSubmit = (arr: [number, number]) => {
    if(modalOptions?.onSubmit) {
      modalOptions?.onSubmit(arr);
    }
    closeAddressModal();
  }

  const modalContainer = document.getElementById('modal-container') || document.body;

  return (
    <ModalStyled
      title={null}
      open={isModalOpen}
      onCancel={closeAddressModal}
      footer={null}
      maskClosable={false}
      getContainer={modalContainer}
      className='modal-select-address-on-map'
    >
      <SelectPointOnMap
        initialPosition={[48.142, 17.13]}
        currentPoint={modalOptions?.currentPoint}
        onSubmit={handleOnPointSubmit}
      />
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  height: 600px;
  // !important убрать не получится тк модалка
  width: fit-content !important;
  .ant-modal-content {
    padding: 0;

    .ant-modal-close {
      background: #ffffff;
      color: #000000;
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid #d9d9d9;
      margin-right: 7px;
      margin-top: 7px;
    }

    .ant-modal-close: hover {
      border: 1px solid black;
    }
  }
`;