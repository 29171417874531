import { ButtonLinkStyled } from './OfferRequestConfirm.styles';
import React from 'react';
import { useNavigation } from '@pankod/refine-core';

interface IButtonLink {
  offerRequestName: string;
  offerRequestId: string;
}

const ButtonLink = ({ offerRequestName, offerRequestId }: IButtonLink) => {
  const { push } = useNavigation();
  return (
    <ButtonLinkStyled
      type={'link'}
      onClick={() => push(`/offers/request/${offerRequestId}`)}
    >
      {offerRequestName}
    </ButtonLinkStyled>
  );
};

export default ButtonLink;
