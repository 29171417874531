import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {TNavigation} from "./types";

interface INavigationState {
  activeTab: TNavigation,
  setActiveTab: (value: TNavigation) => void,
}

export const useNavigationState = create<INavigationState>()(
  devtools(
    (set, get) => ({
      activeTab: 'offers',
      setActiveTab: (value) => set({activeTab: value})
    })
  )
)
