import { useCustom } from '@pankod/refine-core';
import { useState } from 'react';
import { IOperation } from '../../interfaces';
import { API_URL } from '../../packages/keycloak-client/constants';
import { MetaType } from '../accountsList/AccountType';

const useOperations: ({ page, filter }: { page: number; filter?: any[] }) => {
  isLoading: boolean;
  operationsData: IOperation[];
  totalCount: number;
  isFetching: boolean;
  refetch: any;
} = ({ page, filter = [] }) => {
  const [operationsData, setOperationsData] = useState<IOperation[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { isLoading, isFetching, refetch } = useCustom<{
    data: {
      data: IOperation[];
    };
    meta: MetaType;
  }>({
    url: `${API_URL}/operations/search`,
    method: 'post',
    config: {
      payload: {
        page: page,
        limit: 10,
        includes: [
          { relation: 'initiator.userAccount' },
          { relation: 'operationHistories' },
          { relation: 'account.currency' },
          { relation: 'exchangePoint' },
          { relation: 'operationsChain' },
        ],
        sort: [{ field: 'created_at', direction: 'desc' }],
        filters: filter?.length ? [...filter] : [],
      },
    },
    queryOptions: {
      onSuccess: ({ data: responseData }) => {
        const response = responseData.data as unknown as IOperation[];

        setOperationsData(response);
        setTotalCount(responseData.meta.total);
      },
    },
  });

  return {
    isLoading,
    operationsData,
    totalCount,
    isFetching,
    refetch,
  };
};

export default useOperations;
