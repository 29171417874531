import { Flex } from '../../../../shared/components/styled';
import { Button, ButtonProps, Tooltip } from '@pankod/refine-antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined, WalletOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { reorderArray } from '../../../../shared/helpers/reorderArray';
import { ACCOUNT_URL } from '../../../../packages/keycloak-client/constants';
import styled from 'styled-components';

interface IProps {
  transitionStatuses: string[];
  callBack: (trigger: string) => void;
  dealCurrentStatus: string;
  isBuyCrypto?: boolean;
}

const idActionButtons = 'deals-cancel-tooltip';

const ActionsBtnBlock = ({
  transitionStatuses,
  callBack,
  dealCurrentStatus,
  isBuyCrypto
}: IProps) => {
  const { t } = useTranslation(['offers', 'shared']);

  const getBtnIcon = (status: string): ButtonProps['icon'] => {
    switch (status) {
      case 'warranty_collateral': {
        return <WalletOutlined style={{ color: '#00000073' }} />;
      }
      case 'completed': {
        return <CheckOutlined style={{ color: 'rgba(82, 196, 26, 1)' }} />;
      }
      case 'on_argument': {
        return (
          <ExclamationCircleOutlined
            style={{ color: 'rgba(250, 173, 20, 1)' }}
          />
        );
      }
      case 'expired':
      case 'cancelled': {
        return <CloseOutlined style={{ color: 'rgba(255, 77, 79, 1)' }} />;
      }
      case 'accepted': {
        return (
          <CheckOutlined style={{ color: 'rgba(82, 196, 26, 1)' }}/>
        );
      }
      default: {
        return undefined;
      }
    }
  };

  const onClickHandler = (trigger: string) => {
    callBack(trigger);
  };

  const onClickTopUpWallet = () => {
    window.open(ACCOUNT_URL + '/coin-accounts')
  };


  const ButtonCancelDisabled = () => {

    if (!(dealCurrentStatus === 'ready' && !transitionStatuses?.includes('cancelled') && !transitionStatuses?.includes('expired')  && isBuyCrypto)) return null;

    return(
      <Tooltip
        placement="topRight"
        title={t('Вы сможете отменить сделку через 30 минут')}
        getPopupContainer={() => document.querySelector(`#${idActionButtons}`)!}
      >
        <ButtonDisabledStyled
          icon={<CloseOutlined style={{ color: '#00000040' }} />}
          type={'default'}
          className={'custom-button-grey-disable'}
        >
          {t('shared:кнопки.Отменить')}
        </ButtonDisabledStyled>
      </Tooltip>
    )
  }


  return (
    <Flex gap={16} id={idActionButtons}>
      {dealCurrentStatus === 'warranty_collateral' && isBuyCrypto &&
        <Button
          onClick={onClickTopUpWallet}
          icon={getBtnIcon(dealCurrentStatus)}
          type={'default'}
        >
          {t('shared:кнопки.Пополнить баланс')}
        </Button>
      }
      {reorderArray(transitionStatuses)?.map((status) => {
        return (
          <Button
            onClick={() => onClickHandler(status)}
            key={status}
            icon={getBtnIcon(status)}
            type={'default'}
          >
            {status === 'on_argument' && <span>{t('Открыть спор')}</span>}
            {status === 'completed' && <span>{t('Получил наличные')}</span>}
            {status === 'expired' || status === 'cancelled' ? (
              <span>
                {t(
                  `${
                    dealCurrentStatus === 'pending'
                      ? t('Отклонить')
                      : t('shared:кнопки.Отменить')
                  }`
                )}
              </span>
            ) : null}
            {status === 'accepted' && <span>{t('shared:кнопки.Принять')}</span>}
          </Button>
        );
      })}

      <ButtonCancelDisabled/>

    </Flex>
  );
};

export default ActionsBtnBlock;


const ButtonDisabledStyled = styled(Button)`
  &&&&.custom-button-grey-disable {
    background: #0000000A;
    color: #00000040;
    border: 1px solid #00000026;

    &:hover {
      background: #0000000A;
      cursor: not-allowed;
    }
  }
`
