import BN from 'bignumber.js';

export const removeTrailingZeros = (
  num: number | string | BN | undefined
): string => {
  if (typeof num !== 'string') {
    num = String(num);
  }

  const numStr = num;
  const decimalIndex = numStr.indexOf('.');
  const commaIndex = numStr.indexOf(',');

  if (decimalIndex === -1 && commaIndex === -1) {
    return numStr;
  }

  let decimalPos = decimalIndex === -1 ? commaIndex : decimalIndex;

  if (decimalPos !== -1) {
    let lastNonZeroIndex = -1;
    for (let i = decimalPos + 1; i < numStr.length; i++) {
      if (numStr[i] !== '0') {
        lastNonZeroIndex = i;
      }
    }

    if (lastNonZeroIndex === -1) {
      return numStr.substring(0, decimalPos);
    }

    return numStr.substring(0, lastNonZeroIndex + 1);
  }

  return numStr;
};
