import { useEffect, useState } from 'react';
import { useUserState } from 'shared/state/useUserState';

export const useUserRole = () => {
  const { userApi } = useUserState();

  const [userRole, setUserRole] = useState<'admin' | 'manager' | 'cashier'>(
    'cashier'
  );

  useEffect(() => {
    userApi?.id &&
      setUserRole(userApi?.role?.name as 'admin' | 'manager' | 'cashier');
  }, [userApi?.id]);

  return userRole;
};
