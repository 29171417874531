import styled, {css, keyframes} from 'styled-components';
import React, {Fragment, Suspense} from 'react';
import {TNotification} from './NotificationSelect';
import dayjs from 'dayjs';
import {axios} from '../../../../exios';
import {API_URL} from '../../../../../packages/keycloak-client/constants';
import {notification} from 'antd';
import {useParser} from 'pages/notifications/model/useParser';
import fb from '../../../../../packages/fb/fb';
import {t} from 'i18next';
import {ErrorBoundary} from '../../../ErrorBoundary';
import travelCard from './travelCard.svg';
import fileIcon from './fileIcon.svg';

const NotificationSelectMenuItemTooltip = React.lazy(
    () => import('./NotificationSelectMenuItemTooltip')
);

type TNotificationSelectMenuItemProps = {
    item: TNotification;
    isNotificationPage?: boolean;
};

const NotificationSelectMenuItem = ({
                                        item,
                                        isNotificationPage = false,
                                    }: TNotificationSelectMenuItemProps) => {

    const {texts, elems} = useParser(
        item?.text,
        item?.data,
        item?.link ?? 'none',
        item.topic_key,
        item.type
    );

    const setNotificationIsRead = async ({id}: { id: number }) => {
        try {
            await axios.put(
                `${API_URL}/notifications/${id}`,
                {
                    is_read: true,
                },
                {
                    headers: {
                        Authorization: axios.defaults.headers.common.Authorization,
                    },
                }
            );
            fb.emit('deleteNotificationFromUnread', id);
            notification.open({
                type: 'success',
                message: t('shared:статусы.Сообщение успешно прочитано'),
            });
        } catch (e) {
            notification.open({
                type: 'error',
                message: t('shared:статусы.Ошибка прочтения уведомления'),
            });
        }
    };

    type TRenderIcon = {
        type: string
    }

    const renderIcon = ({type}: TRenderIcon) => {
        const iconsMap = {
            corporate: travelCard,
            my_deals: fileIcon
        }
        // @ts-ignore
        return iconsMap[type];
    }

    type TRenderTitleProps = {
        topic: string,
        type: string
    }


    const renderTitle = ({topic, type}: TRenderTitleProps) => {
        return (
            <TitleStyled>
                <img src={renderIcon({type})} alt={'img'}/>
                <span>{topic}</span>
            </TitleStyled>
        )
    }


    return (
        <ErrorBoundary key={item.id}>
            <Suspense fallback={<>Loading...</>}>
                <NotificationSelectMenuItemStyled
                    $isNotificationPage={isNotificationPage}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                >
                    <NotificationSelectMenuItemCol1Styled>
                        <NotificationSelectMenuItemTitleStyled>
                            {renderTitle({topic: item.topic, type: item.type})}
                        </NotificationSelectMenuItemTitleStyled>
                        <NotificationSelectMenuItemTextStyled>
                            {texts.map((text: string, index) => {
                                return (
                                    <Fragment key={index}>
                                        {text}
                                        {elems ? elems[index] : ''}
                                    </Fragment>
                                );
                            })}
                        </NotificationSelectMenuItemTextStyled>
                        <NotificationSelectMenuItemDateStyled>
                            {dayjs(new Date(item.updated_at)).format('DD.MM.YYYY HH:mm')}
                        </NotificationSelectMenuItemDateStyled>
                    </NotificationSelectMenuItemCol1Styled>
                    <NotificationSelectMenuItemCol2Styled>
                        {item.is_read ? (
                            <></>
                        ) : (
                            <NotificationSelectMenuItemTooltip
                                text={t('shared:тексты.Отметить как прочитанное')}
                                placement="left"
                                onClickAction={() => setNotificationIsRead({id: item.id})}
                            >
                                <DotWrapper>
                                    <DotStyled/>
                                </DotWrapper>
                            </NotificationSelectMenuItemTooltip>
                        )}
                    </NotificationSelectMenuItemCol2Styled>
                </NotificationSelectMenuItemStyled>
            </Suspense>
        </ErrorBoundary>
    );
};

const DotWrapper = styled.div`
    padding: 7px;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid #08979C;
        border-radius: 20px;
    }
`;

const TitleStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`;

const DotStyled = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 86px;
    background-color: #08979C;
    animation: ${pulseAnimation} 1.5s infinite;
`;

const NotificationSelectMenuItemStyled = styled.div<{
    $isNotificationPage?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    //width: 100%;
    border-bottom: 1px solid #FFFFFF1A;
    padding: 16px;

    ${({$isNotificationPage}) =>
            $isNotificationPage &&
            css`
                &:first-child {
                    margin-top: 24px;
                }

                width: calc(100% - 20px);
                padding: 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            `}
`;

const NotificationSelectMenuItemCol1Styled = styled.div``;

const NotificationSelectMenuItemCol2Styled = styled.div``;

const NotificationSelectMenuItemTitleStyled = styled.div`
    font-family: SF Pro Text, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 8px;
`;

const NotificationSelectMenuItemDateStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #FFFFFF80;
`;

const NotificationSelectMenuItemTextStyled = styled.div`
    font-family: SF Pro Text, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFFCC;
    margin-bottom: 16px;
`;

export default NotificationSelectMenuItem;
