import {ChatHeaderContainerStyled} from "./assets/styles";
import ChatHeaderContainerUserInfo from "../header/components/UserInfo/ChatHeaderContainerUserInfo";
import ChatHeaderInfo from "./components/ChatInfo/ChatHeaderInfo";

interface IHeaderChat {
    isReferee: boolean,
    isClosableChat: boolean,
    setIsOpenChat: (value: boolean) => void,
    opponent: {
        type: "user" | "exchange_point" | "company" | undefined,
        created_at: string,
        caption: string
    } | undefined,
    currentChat: any,
    isShowTimer: boolean,
}

const ChatHeader = ({
                        isReferee,
                        isClosableChat,
                        setIsOpenChat,
                        opponent,
                        currentChat,
                        isShowTimer
                    }: IHeaderChat): JSX.Element => {
    return (
        <ChatHeaderContainerStyled>
            <ChatHeaderContainerUserInfo
                isReferee={isReferee}
                isClosableChat={isClosableChat}
                setIsOpenChat={setIsOpenChat}
                opponent={opponent}
            />
            {isShowTimer && (
                <ChatHeaderInfo created_at={currentChat?.entity_type === 'offer' ? currentChat?.created_at : null}/>
            )}
        </ChatHeaderContainerStyled>
    );
};

export {ChatHeader};
