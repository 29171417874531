import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {axios} from '../exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {produce} from 'immer';
import {cloneDeep} from "lodash";

type TSystemNotificationsBellState = {
    getNotificationsCount: () => Promise<void>;
    currentTab: number;
    setCurrentTab: (value: number) => void;
    setTotalCount: (value: number) => void;
    setCurrentPage: (value: number) => void;
    getNotificationSearch: () => Promise<void>;
    currentPage: number;
    totalCount: number;
    setNotifications: (value: any[]) => void;
    notifications: any[];
    corporate: {
        count: number;
        topics: {
            accounts: number;
            exchange: number;
            my_company: number;
            offers: number;
            operations: number;
            partners: number;
            problems: number;
            subordinates: number;
            users_data: number;
        };
    },
    my_deals: {
        count: number;
        topics: any[];
    }
};

const checkTab = (currentTab: number): 'my_deals' | 'corporate' | undefined => {
    const tabsMap: { [key: number]: 'my_deals' | 'corporate' | undefined } = {
        1: undefined,
        2: 'my_deals',
        3: 'corporate'
    };

    return tabsMap[currentTab];
};

export const useSystemNotificationsBellState = create<TSystemNotificationsBellState>()(
    devtools(
        (set, get) => ({
            corporate: {
                count: 0,
                topics: {
                    accounts: 0,
                    exchange: 0,
                    my_company: 0,
                    offers: 0,
                    operations: 0,
                    partners: 0,
                    problems: 0,
                    subordinates: 0,
                    users_data: 0
                }
            },
            my_deals: {
                count: 0,
                topics: []
            },
            totalCount: 0,
            currentTab: 1,
            currentPage: 1,
            notifications: [],
            setTotalCount: (value: number) => set(
                produce((draft) => {
                    draft.totalCount = value;
                })
            ),
            setCurrentPage: (value: number) => set(
                produce((draft) => {
                    draft.currentPage = value;
                })
            ),
            setCurrentTab: (value: number) => set(
                produce((draft) => {
                    draft.currentTab = value;
                })
            ),
            setNotifications: (value: any[]) => set(
                produce((draft) => {
                    draft.notifications = value;
                })
            ),
            getNotificationSearch: async () => {
                try {
                    const filters = [
                        {
                            field: 'is_read',
                            operator: '=',
                            value: false,
                        },
                    ];

                    const isTab = !!checkTab(get().currentTab);
                    if (isTab) {
                        filters.push({
                            field: 'type',
                            operator: '=',
                            // @ts-ignore
                            value: checkTab(get().currentTab)
                        });
                    }

                    const res = await axios.post(
                        `${API_URL}/notifications/search`,
                        {
                            page: get().currentPage,
                            limit: 10000,
                            filters: filters,
                            sort: [{field: 'created_at', direction: 'desc'}],
                            includes: [
                                {
                                    relation: 'userAccount'
                                }
                            ]
                        }
                    )
                    if (res?.data) {
                        set(
                            produce((draft) => {
                                draft.getNotificationsCount();
                                draft.notifications = cloneDeep(res.data.data.length ? res.data.data : []);
                                draft.totalCount = res.data.meta?.total;
                                draft.currentPage = res.data.meta?.current_page;
                            })
                        );
                    }
                } catch (e) {
                    console.log('getNotificationSearch', e);
                }
            },
            getNotificationsCount: async () => {
                try {
                    const res = await axios.get(`${API_URL}/notifications/get-count`);
                    set(
                        produce((draft) => {
                            draft.corporate.count = res?.data?.data?.corporate?.count;
                            draft.corporate.topics = res?.data?.data?.corporate?.topics;
                            draft.my_deals.count = res?.data?.data?.my_deals?.count;
                        })
                    );
                    set(
                        produce((draft) => {
                            draft.totalCount = res?.data?.data?.corporate?.count + res?.data?.data?.my_deals?.count;
                        })
                    );
                } catch (e) {
                    console.log('getNotificationsCount e', e);
                }
            },
        }),
        {
            anonymousActionType: 'useSystemNotificationsBellState action',
            name: 'SystemNotificationsBellState',
        }
    )
);
