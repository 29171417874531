import styled from "styled-components";

export const FilterContainerStyled = styled.div`

 `
export const LabelStyled = styled.p`
  margin-bottom: 4px;
  color: #000000A6;
  font-size:14px;
`
