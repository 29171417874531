import { useTranslation } from 'react-i18next';
import { Link } from '@pankod/refine-react-router-v6';
import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import React from 'react';
import { useChartState } from '../model/chartState';

type TbreadcrumbItems = {
  operationName?: null | string;
};
const CourseHistoryBreadcrumb = ({ operationName }: TbreadcrumbItems) => {
  const { t } = useTranslation(['currencyPairs']);
  const {
    mainCurrencySelect: { currencyKey: mainCurrencyKey },
    lastCurrencySelect: { currencyKey: lastCurrencyKey },
  } = useChartState((state) => state);
  const breadcrumbItems = [
    {
      title: (
        <Link to="/currency-pairs">{t('shared:тексты.Валютные пары')}</Link>
      ),
      key: 'currencyPairs',
    },
    {
      title: `${t(
        'История изменения курса'
      )} ${mainCurrencyKey} / ${lastCurrencyKey}`,
      key: 'courseHistory',
    },
  ];
  return <BreadCrumbStyled items={breadcrumbItems} />;
};

export default CourseHistoryBreadcrumb;

const BreadCrumbStyled = styled(Breadcrumb)`
  font-size: 14px;
  font-family: Inter, serif;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
`;
