import {getCookieByName} from "../../../helpers/controlCookies";

type TCalcAuthToken = {
    chatType: 'list' | 'deal' | 'admin' | 'phex' | undefined
    typeTabs?: string
}

export const calcAuthToken = ({chatType, typeTabs}: TCalcAuthToken) => {
    if (chatType === 'list') {
        return typeTabs === 'personal' ? `Bearer ${getCookieByName('partnerChatToken')?.replace('Bearer', '')}` : `Bearer ${getCookieByName('phexChatToken')?.replace('Bearer', '')}`
    } else if (chatType === 'deal') {
        return `Bearer ${getCookieByName('partnerChatToken')?.replace('Bearer', '')}`
    } else if (chatType === 'phex') {
        return `Bearer ${getCookieByName('phexChatToken')?.replace(
            'Bearer',
            ''
        )}`
    } else if (chatType === 'admin') {
        return `Bearer ${getCookieByName('hubChatToken')?.replace(
            'Bearer',
            ''
        )}`
    } else {
        return undefined
    }
}
