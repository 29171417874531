import {UploadFile} from "antd";
import PdfFileBlock from "./components/pdfFileBlock";
import React, {useEffect} from "react";
import {getBase64} from "./helpers/getBase64";
import {RcFile} from "antd/es/upload";
import styled from "styled-components";
import ImageFileBlock from "./components/imageFIleBlock/ImageFileBlock";

type TFilePreview = {
    files: UploadFile[] | null;
    handleDeleteFile: () => void;
    setPreviewImage: (value: string) => void;
    previewImage: string;
}

const FilePreview = ({
                         files,
                         handleDeleteFile,
                         setPreviewImage,
                         previewImage,
                     }: TFilePreview) => {
    const fileIsPdf = !!files && /\.pdf$/.test(files[0].name as string);

    useEffect(() => {
        async function getPreview() {
            if (files && files[0]) {
                if (!files[0].url && !files[0].preview) {
                    files[0].preview = await getBase64(files[0].originFileObj as RcFile);
                }
                setPreviewImage(files[0].url || (files[0].preview as string));
            }
        }

        getPreview().then();
    }, [files]);

    return (
        <>
            <FilePreviewStyled>
                {files?.length && (
                    <FilePreviewIconStyled>
                        <FilePreviewIconStyled>
                            {fileIsPdf ? (
                                <PdfFileBlock
                                    name={files[0]?.name}
                                    size={files[0]?.size || 0}
                                    handleDeleteFile={handleDeleteFile}
                                />
                            ) : (
                                <ImageFileBlock
                                    previewImage={previewImage}
                                    handleDeleteFile={handleDeleteFile}
                                />
                            )}
                        </FilePreviewIconStyled>
                    </FilePreviewIconStyled>
                )}
            </FilePreviewStyled>
        </>
    )
}

export default FilePreview;

const FilePreviewStyled = styled.div`
    padding-top: 10px;
    display: flex;
    gap: 10px;
`;

const FilePreviewIconStyled = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    gap: 10px;
    align-items: center;
`;
