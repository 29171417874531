import styled from 'styled-components';

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitleStyled = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ModalDescriptionStyled = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 24px 35px;
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;
