import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmptyOfferTitleStyled,
  EmptyOfferTransactionsStyled,
  LabelTabStyled,
  PaginationWrapper,
  StyledTabs,
  WrapperLoaderStyled,
  WrapperTableStyled,
  WrapperTabsStyled,
} from './Offer.styles';
import { BoxIcon } from '../../../shared/components/icons';
import { getDeals } from '../../offers/api/getDeals';
import { useParams } from '@pankod/refine-react-router-v6';
import { TDealsData } from '../../offers/model/types';
import { getExchangePoints } from '../../offers/api/getExchangePoints';
import { IExchangePoint } from '../../../interfaces';
import { Pagination } from '@pankod/refine-antd';
import { AdaptiveFullScreenLoader } from '../../../shared/components/full-screen-loader';
import DealsTable from '../../../features/DealsTable/ui/DealsTable';

const OfferTabs = () => {
  const { t } = useTranslation(['shared']);
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState<string>('offerTransactions');
  const [deals, setDeals] = useState<TDealsData['data']>([]);
  const [metaDeals, setMetaDeals] = useState({
    current_page: 1,
    total: 0,
    per_page: 0,
  });
  const [pointsDeals, setPointsDeals] = useState<IExchangePoint[]>([]);
  const [isloading, setIsLoading] = useState<boolean>(true);

  const fetchDeals = async () => {
    setIsLoading(true);
    const filter = [
      {
        field: 'offer.id',
        operator: '=',
        value: id?.toString(),
      },
    ];

    try {
      const dataDeals = await getDeals(filter as [], metaDeals.current_page);
      setDeals(dataDeals.data);
      setMetaDeals({
        current_page: dataDeals.meta.current_page,
        total: dataDeals.meta.total,
        per_page: dataDeals.meta.per_page,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      label: (
        <LabelTabStyled>
          {t('shared:тексты.Сделки')} ({metaDeals.total || 0})
        </LabelTabStyled>
      ),
      key: 'offerTransactions',
    },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const points = await getExchangePoints();
      setIsLoading((prev) => false);
      setPointsDeals(points.arr);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchDeals();
    })();
  }, [metaDeals.current_page]);

  const changeActiveTabHandler = (value: string) => {
    setActiveTab(value);
  };

  const handleChangeMetadata = (value: number) => {
    setMetaDeals((prev) => ({ ...prev, current_page: value }));
  };

  return (
    <>
      <WrapperTabsStyled>
        <StyledTabs
          items={tabs}
          activeKey={activeTab}
          tabBarGutter={48}
          onChange={changeActiveTabHandler}
        />
        {isloading ? (
          <WrapperLoaderStyled>
            <AdaptiveFullScreenLoader />
          </WrapperLoaderStyled>
        ) : !!deals.length ? (
          <WrapperTableStyled>
            <DealsTable
              refetchDeals={fetchDeals}
              setPagination={handleChangeMetadata}
              deals={deals}
              points={pointsDeals}
              isDealsEmpty={false}
              isFullTable={false}
            />
          </WrapperTableStyled>
        ) : (
          <EmptyOfferTransactionsStyled>
            <BoxIcon />
            <EmptyOfferTitleStyled>
              {t('shared:тексты.Нет заявок')}
            </EmptyOfferTitleStyled>
          </EmptyOfferTransactionsStyled>
        )}
      </WrapperTabsStyled>
      <PaginationWrapper>
        <Pagination
          total={metaDeals.total}
          current={metaDeals.current_page}
          onChange={handleChangeMetadata}
          hideOnSinglePage={true}
          showSizeChanger={false}
        />
      </PaginationWrapper>
    </>
  );
};

export default OfferTabs;
