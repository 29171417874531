import { axios } from '../../../shared/exios';
import { IResponseShell, IStaff } from '../../../shared/interfaces';
import { IExchangePoint, IUser } from '../../../interfaces';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { IAccount } from '../../ExchangePoints/interface';

type TExchangePoint = Omit<IExchangePoint, 'accounts' | 'user_accounts'> & {
  accounts: IAccount[];
  user_accounts: IStaff[];
};

export const getExchangePoint = async (exchangePointId: number) => {
  try {
    const { data } = await axios.post<IResponseShell<TExchangePoint[]>>(
      `${API_URL}/exchange-points/search`,
      {
        includes: [
          { relation: 'userAccounts' },
          { relation: 'accounts.currency' },
        ],
        filters: [{ field: 'id', operator: '=', value: exchangePointId }],
      }
    );

    return data.data[0];
  } catch (err) {
    console.error(err);
  }
};
