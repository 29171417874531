import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {axios} from '../../../shared/exios';
import {getFiltersForAnalyticsRequests} from '../../../shared/helpers/script';

type TAnalyticsAccountBalanceState = {
  analyticAccountsBalance: TAnalyticAccountsBalance;
  searchAnalyticAccountsBalance: (currency?: string) => void;
  companyCurrency: string;
  isAnalyticAccountsBalanceLoading: boolean;
};

type TAnalyticAccountsBalance = {
  result: string;
  status: string;
  company_currency: string;
  company_currency_zeros_number: number;
};

export const useAnalyticsAccountBalanceState =
  create<TAnalyticsAccountBalanceState>()(
    devtools(
      (set, get) => ({
        companyCurrency: '',
        isAnalyticAccountsBalanceLoading: true,
        analyticAccountsBalance: {
          result: '',
          status: '',
          company_currency: '',
          company_currency_zeros_number: 8,
        },
        searchAnalyticAccountsBalance: async (currency?: string) => {
          const filters = getFiltersForAnalyticsRequests({
            requestName: 'searchAnalyticAccountsBalance',
          });
          const response = await axios.post(
            `${API_URL}/analytic-accounts-balance/search`,
            {
              filters: !!filters?.length ? [...filters] : [],
              currency:
                currency ??
                useAnalyticsAccountBalanceState.getState().companyCurrency,
            },
            {
              headers: {
                Authorization: axios.defaults.headers.common['Authorization'],
              },
            }
          );
          set(
            produce((draft) => {
              if (response?.data?.data) {
                draft.analyticAccountsBalance = response.data.data;
                draft.isAnalyticAccountsBalanceLoading = false;
              }
              if (currency) {
                draft.companyCurrency = currency;
              }
            }),
            true,
            {
              type: 'useAnalyticsAccountBalanceState => searchAnalyticAccountsBalance',
            }
          );
        },
      }),
      {
        anonymousActionType: 'useAnalyticsAccountBalanceState action',
        name: 'useAnalyticsAccountBalanceState',
      }
    )
  );
