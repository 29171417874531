import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {axios} from '../exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {createOptions} from 'shared/helpers/script';

type TMmfState = {
  mmfsData: [];
  mmfsMeta: {};
  searchMmfsCodes: ({setFilters}: any) => void;
  setMmfs: ({mmfsData, mmfsMeta}: TSetMmfsProps) => void;
};

type TSetMmfsProps = {
  mmfsData: any;
  mmfsMeta: any;
};

export const useMmfsState = create<TMmfState>()(
  devtools(
    (set, get) => ({
      mmfsData: [],
      mmfsMeta: {},
      searchMmfsCodes: async ({setFilters}: any) => {
        const response = await axios.post(`${API_URL}/mmf-codes/search`, {
          limit: 10000,
          page: 1,
        });
        const options = createOptions(response.data.data, 'useMmfsState');
        localStorage.setItem('corpMmfs', JSON.stringify(options));
        setFilters({options, selectId: 'corpMmfs', isFromRequest: true});
        set(
          produce((draft) => {
            draft.setMmfs({
              mmfsData: response.data.data,
              // @ts-ignore
              mmfsMeta: response.data.meta,
            });
          }),
          false,
          {
            type: 'useMmfsState => searchMmfsCodes',
          }
        );
      },
      setMmfs: ({mmfsData, mmfsMeta}: TSetMmfsProps) =>
        set(
          produce((draft) => {
            draft.mmfsData = mmfsData;
            draft.mmfsMeta = mmfsMeta;
          }),
          false,
          {
            type: 'useMmfsState => setMmfs',
          }
        ),
    }),
    {
      anonymousActionType: 'useMmfsState action',
      name: 'mmfsState',
    }
  )
);
