import styled from 'styled-components';
import { CheckCircleFilled } from '@ant-design/icons';

const CloseCircleFilledStyle = () => {
  return <CheckCircleFilledStyled />;
};

export default CloseCircleFilledStyle;

const CheckCircleFilledStyled = styled(CheckCircleFilled)`
  color: #13c2c2;
`;
