import styled from 'styled-components';
import { Table } from '@pankod/refine-antd';

export const TableStyled = styled(Table)`
  & table {
    border: none;
  }

  .ant-table-content .ant-table-thead tr th.ant-table-cell:first-child,
  .ant-table-content .ant-table-thead tr th.ant-table-cell:last-child {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }
`;
