import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { TOption } from '../model/types';
import { cloneDeep } from 'lodash';
import { useUserAccountState } from '../../../shared/state/useUserAccountsState';
import { useAnalyticsOutputState } from './useAnalyticsOutputState';
import { useAnalyticsInputState } from './useAnalyticsInputState';
import { useAnalyticsActualBenefitState } from './useAnalyticsActualBenefitState';
import { useAnalyticsAccountBalanceState } from './useAnalyticsAccountBalanceState';
import fb from '../../../packages/fb/fb';
import { t } from 'i18next';
import { useUserState } from 'shared/state/useUserState';
import dayjs from 'dayjs';
import {useAnalyticsDebtsState} from "./useAnalyticsDebtsState";

type TAnalyticsFiltersState = {
  isDisabledAccountBalance: boolean;
  isDisabledResetFilters: boolean;
  resetFilters: () => void;
  setIsDisabledResetFilters: () => void;
  refetchAnalyticsPage: () => void;
  filters: {
    dateArr: any[];
    currencies: any[];
    exchangePoints: any[];
    accounts: any[];
    operationTypes: any[];
    corpMmfs: any[];
    clients: any[];
    employees: any[];
    accountTypes: any[];
  };
  translateCorpMmfs: () => void;
  setFilters: ({ options, selectId, isFromRequest }: TSetFilters) => void;
  setFilterDate: (dates: string[]) => void;
  getAllFiltersBySelectId: (filter: TFilter) => any;
  getAllSelectedFiltersBySelectId: (filter: TFilter) => any;
  deselectById: ({ id, type }: { id: number | string; type: TFilter }) => void;
};

type TFilter = string;

type TSetFilters = {
  options: string | TOption[];
  selectId: string;
  isFromRequest?: boolean;
};

export const useAnalyticsRequestsFiltersState =
  create<TAnalyticsFiltersState>()(
    devtools(
      (set, get) => ({
        filters: {
          dateArr: [
            dayjs().format('YYYY-MM-DD') + ' 00:00:00',
            dayjs().format('YYYY-MM-DD') + ' 23:59:59',
          ],
          currencies: [],
          exchangePoints: [],
          accounts: [],
          operationTypes: [],
          corpMmfs: [],
          clients: [],
          employees: [],
          accountTypes: [
            {
              id: 'bank',
              value: 'shared:типы.Счёт в банке',
              isSelected: false,
              key: 'bank',
            },
            {
              id: 'electronic_wallet',
              value: 'shared:типы.Электронный кошелёк',
              isSelected: false,
              key: 'electronic_wallet',
            },
            {
              id: 'physical_safe',
              value: 'shared:типы.Физическое хранилище',
              isSelected: false,
              key: 'physical_safe',
            },
          ],
        },
        isDisabledResetFilters: true,
        isDisabledAccountBalance: false,
        isDisabledActualBenefits: false,
        translateCorpMmfs: () =>
          set(
            produce((draft) => {
              const corpMmfsArr = draft.filters.corpMmfs;

              const translations = {
                loan: t('shared:статусы.Займ'),
                rent: t('shared:типы.Аренда'),
                staff_wage: t('shared:статусы.Заработная плата персонала'),
                balance_adjustment: t('shared:статусы.Корректировка остатков'),
                other: t('shared:статусы.Прочее'),
              };

              for (const e of corpMmfsArr) {
                // @ts-ignore
                if (translations[e.value]) {
                  // @ts-ignore
                  e.value = translations[e.value];
                }
              }
              draft.corpMmfs = corpMmfsArr;
            })
          ),
        refetchAnalyticsPage: () => {
          const searchUserAccounts =
            useUserAccountState.getState().searchUserAccounts;
          const searchUserAccountsWithUid =
            useUserAccountState.getState().searchUserAccountsWithUid;

          const searchAnalyticExpenditures =
            useAnalyticsOutputState.getState().searchAnalyticExpenditures;
          const searchAnalyticArrivals =
            useAnalyticsInputState.getState().searchAnalyticArrivals;
          const searchAnalyticRateBenefit =
            useAnalyticsActualBenefitState.getState().searchAnalyticRateBenefit;

          const searchAnalyticCompanyDebts =
            useAnalyticsDebtsState.getState().searchAnalyticCompanyDebts;

          const searchAnalyticEmployeesDebts =
            useAnalyticsDebtsState.getState().searchAnalyticEmployeesDebts;

          const searchAnalyticAccountsBalance =
            useAnalyticsAccountBalanceState.getState()
              .searchAnalyticAccountsBalance;
          const user = useUserState.getState().userApi;

          const time = 100;

          setTimeout(() => {
            searchAnalyticExpenditures();
          }, time * 7);

          setTimeout(() => {
            searchAnalyticArrivals();
          }, time * 8);

          setTimeout(() => {
            searchAnalyticRateBenefit();
          }, time * 9);

          setTimeout(() => {
            searchAnalyticAccountsBalance();
          }, time * 10);

          setTimeout(() => {
            searchAnalyticCompanyDebts();
          }, time * 11);

          setTimeout(() => {
            searchAnalyticEmployeesDebts();
          }, time * 12);

          set(
            produce((draft) => {
              user.role.name === 'admin'
                ? searchUserAccounts({
                    setFilters: draft.setFilters,
                  })
                : searchUserAccountsWithUid({
                    setFilters: draft.setFilters, //uid for cashier and manager view
                    uid: user.uid,
                  });
            }),
            false,
            {
              type: 'useAnalyticsRequestsFiltersState => refetchAnalyticsPage',
            }
          );
        },
        setIsDisabledResetFilters: () =>
          set(
            produce((draft) => {
              if (
                !!draft.getAllSelectedFiltersBySelectId('currencies')?.length ||
                !!draft.getAllSelectedFiltersBySelectId('exchangePoints')
                  ?.length ||
                !!draft.getAllSelectedFiltersBySelectId('accounts')?.length ||
                !!draft.getAllSelectedFiltersBySelectId('operationTypes')
                  ?.length ||
                !!draft.getAllSelectedFiltersBySelectId('corpMmfs')?.length ||
                !!draft.getAllSelectedFiltersBySelectId('clients')?.length ||
                !!draft.getAllSelectedFiltersBySelectId('employees')?.length ||
                !!draft.getAllSelectedFiltersBySelectId('accountTypes')
                  ?.length ||
                !!draft.getAllSelectedFiltersBySelectId('dateArr')[0] ||
                !!draft.getAllSelectedFiltersBySelectId('dateArr')[1]
              ) {
                draft.isDisabledResetFilters = false;
              } else {
                draft.isDisabledResetFilters = true;
              }
            }),
            false,
            {
              type: 'useAnalyticsRequestsFiltersState => setIsDisabledResetFilters',
            }
          ),
        getAllFiltersBySelectId: (filter: TFilter) => {
          if (!!filter) {
            const filters = {
              dateArr: get().filters.dateArr,
              currencies: get().filters.currencies,
              exchangePoints: get().filters.exchangePoints,
              accounts: get().filters.accounts,
              operationTypes: get().filters.operationTypes,
              corpMmfs: get().filters.corpMmfs,
              clients: get().filters.clients,
              employees: get().filters.employees,
              accountTypes: get().filters.accountTypes,
            };
            // @ts-ignore
            return filters[filter];
          } else return [];
        },
        getAllSelectedFiltersBySelectId: (filter: TFilter) => {
          const stateFilters = get().filters;
          const selectedCurrencies = stateFilters.currencies.filter(
            (e) => e.isSelected
          );
          const selectedExchangePoints = stateFilters.exchangePoints.filter(
            (e) => e.isSelected
          );
          const selectedAccounts = stateFilters.accounts.filter(
            (e) => e.isSelected
          );
          const selectedOperationTypes = stateFilters.operationTypes.filter(
            (e) => e.isSelected
          );
          const selectedCorpMmfs = stateFilters.corpMmfs.filter(
            (e) => e.isSelected
          );
          const selectedClients = stateFilters.clients.filter(
            (e) => e.isSelected
          );
          const selectedEmployees = stateFilters.employees.filter(
            (e) => e.isSelected
          );
          const selectedAccountTypes = stateFilters.accountTypes.filter(
            (e) => e.isSelected
          );

          switch (filter) {
            case 'dateArr':
              return stateFilters.dateArr;
            case 'currencies':
              return selectedCurrencies;
            case 'exchangePoints':
              return selectedExchangePoints;
            case 'accounts':
              return selectedAccounts;
            case 'operationTypes':
              return selectedOperationTypes;
            case 'corpMmfs':
              return selectedCorpMmfs;
            case 'clients':
              return selectedClients;
            case 'employees':
              return selectedEmployees;
            case 'accountTypes':
              return selectedAccountTypes;
            default:
              return [];
          }
        },
        deselectById: ({ id, type }: { id: number | string; type: TFilter }) =>
          set(
            produce((draft) => {
              const filters = draft.filters;
              draft.filters[type] = filters[type]?.map((e: any) => {
                if (e.id === id) {
                  return {
                    ...e,
                    isSelected: false,
                  };
                }
                return e;
              });
              localStorage.setItem(type, JSON.stringify(draft.filters[type]));
            })
          ),
        resetFilters: () =>
          set(
            produce((draft) => {
              const accountsLocalstorage = localStorage.getItem('accounts')
                ? JSON.parse(localStorage?.getItem('accounts')!)
                : '';
              const clientsLocalstorage = localStorage.getItem('clients')
                ? JSON.parse(localStorage.getItem('clients')!)
                : '';
              const currenciesLocalstorage = localStorage.getItem('currencies')
                ? JSON.parse(localStorage.getItem('currencies')!)
                : '';
              const exchangePointsLocalstorage = localStorage.getItem(
                'exchangePoints'
              )
                ? JSON.parse(localStorage.getItem('exchangePoints')!)
                : '';
              const corpMmfsLocalstorage = localStorage.getItem('corpMmfs')
                ? JSON.parse(localStorage.getItem('corpMmfs')!)
                : '';
              const operationTypesLocalstorage = localStorage.getItem(
                'operationTypes'
              )
                ? JSON.parse(localStorage.getItem('operationTypes')!)
                : '';
              const employeesLocalstorage = localStorage.getItem('employees')
                ? JSON.parse(localStorage.getItem('employees')!)
                : '';
              const accountTypesLocalstorage = localStorage.getItem(
                'accountTypes'
              )
                ? JSON.parse(localStorage.getItem('accountTypes')!)
                : '';

              const accountsLocalstorageEdited: any[] = [];
              const clientsLocalstorageEdited: any[] = [];
              const currenciesLocalstorageEdited: any[] = [];
              const exchangePointsLocalstorageEdited: any[] = [];
              const corpMmfsLocalstorageEdited: any[] = [];
              const operationTypesLocalstorageEdited: any[] = [];
              const employeesLocalstorageEdited: any[] = [];
              const accountTypesLocalstorageEdited: any[] = [];

              accountsLocalstorage?.length &&
                accountsLocalstorage.forEach((e: any) => {
                  accountsLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              clientsLocalstorage?.length &&
                clientsLocalstorage.forEach((e: any) => {
                  clientsLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              currenciesLocalstorage?.length &&
                currenciesLocalstorage.forEach((e: any) => {
                  currenciesLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              exchangePointsLocalstorage?.length &&
                exchangePointsLocalstorage.forEach((e: any) => {
                  exchangePointsLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              corpMmfsLocalstorage?.length &&
                corpMmfsLocalstorage.forEach((e: any) => {
                  corpMmfsLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              operationTypesLocalstorage?.length &&
                operationTypesLocalstorage.forEach((e: any) => {
                  operationTypesLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              employeesLocalstorage?.length &&
                employeesLocalstorage.forEach((e: any) => {
                  employeesLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              accountTypesLocalstorage?.length &&
                accountTypesLocalstorage.forEach((e: any) => {
                  accountTypesLocalstorageEdited.push({
                    ...e,
                    isSelected: false,
                  });
                });

              localStorage.setItem(
                'accounts',
                JSON.stringify(accountsLocalstorageEdited)
              );
              localStorage.setItem(
                'clients',
                JSON.stringify(clientsLocalstorageEdited)
              );
              localStorage.setItem(
                'currencies',
                JSON.stringify(currenciesLocalstorageEdited)
              );
              localStorage.setItem(
                'exchangePoints',
                JSON.stringify(exchangePointsLocalstorageEdited)
              );
              localStorage.setItem(
                'corpMmfs',
                JSON.stringify(corpMmfsLocalstorageEdited)
              );
              localStorage.setItem(
                'operationTypes',
                JSON.stringify(operationTypesLocalstorageEdited)
              );
              localStorage.setItem(
                'employees',
                JSON.stringify(employeesLocalstorageEdited)
              );
              localStorage.setItem(
                'accountTypes',
                JSON.stringify(accountTypesLocalstorageEdited)
              );

              draft.filters = cloneDeep({
                ...draft.filters,
                dateArr: [],
                currencies: draft.filters.currencies.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
                exchangePoints: draft.filters.exchangePoints.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
                accounts: draft.filters.accounts.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
                operationTypes: draft.filters.operationTypes.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
                corpMmfs: draft.filters.corpMmfs.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
                clients: draft.filters.clients.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
                employees: draft.filters.employees.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
                accountTypes: draft.filters.accountTypes.map((e: any) => ({
                  ...e,
                  isSelected: false,
                })),
              });
              console.log('draft.filters', draft.filters);
              draft.setIsDisabledResetFilters();
              draft.isDisabledResetFilters = true;
            }),
            false,
            { type: 'useAnalyticsRequestsFiltersState => resetFilters' }
          ),
        setFilters: ({ options, selectId, isFromRequest }: TSetFilters) =>
          set(
            produce((draft) => {
              if (
                typeof options === 'string' ||
                isFromRequest ||
                selectId === 'dateArr'
              ) {
                localStorage.setItem(selectId, JSON.stringify(options));
                draft.filters[selectId] = options;
              } else {
                const all = draft.getAllFiltersBySelectId(selectId);
                const arr = all.map((unselectedOption: any) => {
                  const el = options.find(
                    (el: any) => el.id === unselectedOption.id
                  );
                  if (el) {
                    return el;
                  } else {
                    return { ...unselectedOption, isSelected: false };
                  }
                });
                localStorage.setItem(selectId, JSON.stringify(arr));
                draft.filters[selectId] = arr;
                fb.emit('isDisabledAccountBalance');
                fb.emit('isDisabledActualBenefit');
              }
            }),
            false,
            { type: 'useAnalyticsRequestsFiltersState => setFilters' }
          ),
        setFilterDate: (dates: string[]) =>
          set(
            produce((draft) => {
              draft.filters.dateArr = dates;
            }),
            false,
            { type: 'useAnalyticsRequestsFiltersState => setFilterDate' }
          ),
      }),
      {
        anonymousActionType: 'useAnalyticsRequestsFiltersState action',
        name: 'useAnalyticsRequestsFiltersState',
      }
    )
  );
