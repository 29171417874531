import { CountdownStyled, TimerWrapper } from './RequestInfo.styles';
import { useTranslation } from 'react-i18next';

interface IProps {
  deadline: string | number;
}

const Timer = ({ deadline }: IProps) => {
  const { t } = useTranslation('shared');
  return (
    <TimerWrapper>
      <CountdownStyled
        value={deadline}
        format={`DD ${t('shared:тексты.д')} : HH ${t('shared:тексты.ч')} : mm`}
        suffix={t('shared:тексты.м')}
      />
    </TimerWrapper>
  );
};

export default Timer;
