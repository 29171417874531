import { IAccount } from '../../ExchangePoints/interface';
import {
  ExternalContainer,
  ExternalText,
  HeaderRow,
  HeaderTitle,
  TagStyled,
} from './styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WalletOutlined } from '@ant-design/icons';

type propsType = {
  accountData: IAccount | null;
};
const Header = ({ accountData }: propsType) => {
  const { t } = useTranslation(['shared']);

  const statusChainMap: Record<'blocked' | 'active', React.ReactNode> = {
    blocked: (
      <TagStyled color="red">{t('shared:статусы.Заблокирован')}</TagStyled>
    ),
    active: <TagStyled color="cyan">{t('shared:статусы.Активен')}</TagStyled>,
  };

  return (
    <HeaderRow>
      <HeaderTitle>
        {(accountData?.shortname && accountData?.shortname) ||
          t('shared:статусы.Нет данных')}
      </HeaderTitle>
      {statusChainMap[accountData?.status as IAccount['status']]}
      {accountData?.is_external && (
        <ExternalContainer>
          <WalletOutlined style={{ fontSize: 12 }} />
          <ExternalText>
            {t('shared:тексты.Имеет связь с живым кошельком')}
          </ExternalText>
        </ExternalContainer>
      )}
    </HeaderRow>
  );
};

export default Header;
