import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { Modal } from 'antd';
import {
  LanguagesListStyled,
  LanguageStyled,
} from './ModalChangeLanguage.styles';
import { useTranslation } from 'react-i18next';
import { axios } from '../../../../../exios';
import {
  ACCOUNT_URL_API,
  API_URL,
} from '../../../../../../packages/keycloak-client/constants';
import { useUserState } from '../../../../../state/useUserState';
import { useCookiesCustom } from 'shared/hooks/useCookiesCustom';

export const ModalChangeLanguage = () => {
  const { t, i18n } = useTranslation(['shared']);
  const { userApi } = useUserState();
  const {setCookie} = useCookiesCustom();
  const checkModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalChangeLanguage)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const closeNoAccountModal = () => {
    closeModal(ModalKeys.ModalChangeLanguage);
  };

  const handleLanguageChange = (locale: string) => {
    {
      const id = userApi.id;
      i18n.changeLanguage(locale).then();
      if (!id) {
        return;
      }
      axios
        .patch(`${ACCOUNT_URL_API}/server/edenex-account/api/update-language`, {
          lang: locale,
        })
        .then(() => {
          i18n.changeLanguage(locale).then();
          setCookie('i18nLang', locale);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    closeModal(ModalKeys.ModalChangeLanguage);
  };

  return (
    <Modal
      centered
      footer={null}
      onCancel={closeNoAccountModal}
      open={checkModalOpen}
      width={400}
    >
      <h2>{t('shared:тексты.Выбор языка')}</h2>
      <LanguagesListStyled>
        {/* раскомментить когда пригодятся остальные языки */}
        {/*<LanguageStyled*/}
        {/*  $isActive={i18n.language === 'ar'}*/}
        {/*  onClick={() => handleLanguageChange('ar')}*/}
        {/*>*/}
        {/*  AR عرب*/}
        {/*</LanguageStyled>*/}
        {/*<LanguageStyled*/}
        {/*  $isActive={i18n.language === 'ch'}*/}
        {/*  onClick={() => handleLanguageChange('ch')}*/}
        {/*>*/}
        {/*  CH 中國人*/}
        {/*</LanguageStyled>*/}
        <LanguageStyled
          $isActive={i18n.language === 'en'}
          onClick={() => handleLanguageChange('en')}
        >
          EN English
        </LanguageStyled>
        {/* раскомментить когда пригодятся остальные языки */}
        {/*<LanguageStyled*/}
        {/*  $isActive={i18n.language === 'es'}*/}
        {/*  onClick={() => handleLanguageChange('es')}*/}
        {/*>*/}
        {/*  ES Español*/}
        {/*</LanguageStyled>*/}
        {/*<LanguageStyled*/}
        {/*  $isActive={i18n.language === 'fr'}*/}
        {/*  onClick={() => handleLanguageChange('fr')}*/}
        {/*>*/}
        {/*  FR Français*/}
        {/*</LanguageStyled>*/}
        {/*<LanguageStyled*/}
        {/*  $isActive={i18n.language === 'hi'}*/}
        {/*  onClick={() => handleLanguageChange('hi')}*/}
        {/*>*/}
        {/*  HI हिंदी*/}
        {/*</LanguageStyled>*/}
        {/*<LanguageStyled*/}
        {/*  $isActive={i18n.language === 'jp'}*/}
        {/*  onClick={() => handleLanguageChange('jp')}*/}
        {/*>*/}
        {/*  JP 日本語*/}
        {/*</LanguageStyled>*/}
        {/*<LanguageStyled*/}
        {/*  $isActive={i18n.language === 'pt'}*/}
        {/*  onClick={() => handleLanguageChange('pt')}*/}
        {/*>*/}
        {/*  PT Português*/}
        {/*</LanguageStyled>*/}
        <LanguageStyled
          $isActive={i18n.language === 'ru'}
          onClick={() => handleLanguageChange('ru')}
        >
          RU Русский
        </LanguageStyled>
      </LanguagesListStyled>
    </Modal>
  );
};
