import { axios } from '../exios';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const CACHE_NAME = 'universal-cache';
const CACHE_EXPIRATION_TIME = 2 * 60 * 1000; // 2 минуты в миллисекундах

/**
 * Универсальная функция для выполнения запросов с кэшированием.
 * @param url - URL запроса
 * @param config - Опциональная конфигурация для axios
 * @param cacheKeyCustom - Опциональная строка для сохранения в кэш
 * @returns Данные ответа от сервера
 */
export const requestWithCache = async <T>(url: string, config: AxiosRequestConfig = {}, cacheKeyCustom?: string): Promise<T> => {
  const cacheKey = `https://cache-key/${encodeURIComponent(
    cacheKeyCustom ?? generateCacheKey(url, config)
  )}`;
  const cache = await caches.open(CACHE_NAME);

  // Проверяем наличие данных в кэше
  const cachedResponse = await cache.match(cacheKey);

  if (cachedResponse) {
    const cachedTime = await getCachedTime(cache, cacheKey);

    // Проверяем, не истекло ли время жизни кэша
    if (cachedTime && Date.now() - cachedTime < CACHE_EXPIRATION_TIME) {
      const cachedData: T = await cachedResponse.json();
      return cachedData;
    } else {
      // Удаляем устаревший кэш
      await cache.delete(cacheKey);
    }
  }

  // Выполняем новый запрос с помощью axios
  const response: AxiosResponse<T> = await axios({ url, ...config });

  if (response.status === 200) {
    const data = response.data;

    // Сохраняем новый ответ в кэш
    const responseToCache = new Response(JSON.stringify(data));
    await cache.put(cacheKey, responseToCache);

    // Сохраняем время кэширования
    await saveCachedTime(cache, cacheKey);

    return data;
  } else {
    throw new Error(`Error request: ${response.status} ${response.statusText}`);
  }
};

/**
 * Функция для генерации уникального ключа кэша на основе URL и конфигурации
 */
const generateCacheKey = (url: string, config: AxiosRequestConfig): string => {
  const configString = JSON.stringify(config);
  return `${url}:${configString}`;
}

/**
 * Сохраняем время кэширования для определённого запроса
 */
const saveCachedTime = async(cache: Cache, cacheKey: string): Promise<void> => {
  const timeCacheKey = `${cacheKey}_timestamp`;
  const timestamp = new Response(Date.now().toString());
  await cache.put(timeCacheKey, timestamp);
}

/**
 * Получаем время кэширования для определённого запроса
 */
const getCachedTime = async(cache: Cache, cacheKey: string): Promise<number | null> => {
  const timeCacheKey = `${cacheKey}_timestamp`;
  const cachedTimeResponse = await cache.match(timeCacheKey);
  if (!cachedTimeResponse) return null;
  const timeString = await cachedTimeResponse.text();
  return parseInt(timeString, 10);
}

/**
 * Удаляем кеш для определённого запроса по ключу
 */
export const deleteCashByKey = async(cacheKey: string)=> {
  const cache = await caches.open(CACHE_NAME);
  await cache.delete(cacheKey)
  await cache.delete(`${cacheKey}_timestamp`)
}

/**
 * Удаляем весь кеш для хранилища запросов
 */
export const deleteAllCash = async()=> {
          caches.delete(CACHE_NAME);
}
