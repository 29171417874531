import { Card, ConfigProvider, Typography } from '@pankod/refine-antd';
import { ReactNode } from 'react';
import { CreateOfferContentWrapper } from './CreateOffer.styles';
import { useTranslation } from 'react-i18next';

interface ICreateOfferLayoutProps {
  children: ReactNode;
}

const CreateOfferLayout = ({ children }: ICreateOfferLayoutProps) => {
  const { t } = useTranslation(['shared']);

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            controlHeight: 32,
          },
          InputNumber: {
            controlHeight: 32,
          },
          Select: {
            controlHeight: 32,
          },
        },
      }}
    >
      <CreateOfferContentWrapper>
        <Typography.Title level={4}>
          {t('shared:тексты.Создание оффера')}
        </Typography.Title>
        <Card>{children}</Card>
      </CreateOfferContentWrapper>
    </ConfigProvider>
  );
};

export default CreateOfferLayout;
