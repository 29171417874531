import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import { ConfigProvider } from '@pankod/refine-antd';
import './app/i18n';
import './shared/fonts/index.css';
import { mainTheme } from './shared/components/ui/themes';
import './shared/components/ui/styles.css';
import { FullScreenLoader } from './shared/components/full-screen-loader';
import NoDataDisplay from './shared/components/NoDataDisplay';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.Suspense fallback={<FullScreenLoader />}>
    <ConfigProvider theme={mainTheme} renderEmpty={NoDataDisplay}>
      <App />
    </ConfigProvider>
  </React.Suspense>
);
