import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';

type TSidebarState = {
  isCollapsedSidebar: boolean;
  setIsCollapsedSidebar:(value: boolean)=>void
};

export const useSidebarState = create<TSidebarState>()(
  devtools(
    (set, get) => ({
      isCollapsedSidebar: false,
      setIsCollapsedSidebar: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isCollapsedSidebar = value;
          })
        );
      },
    }),
    {
      anonymousActionType: 'useSidebarState action',
      name: 'userState',
    }
  )
);
