import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {axios} from '../../../shared/exios';
import {getFiltersForAnalyticsRequests} from '../../../shared/helpers/script';

type TAnalyticRateBenefit = {
  result: string;
  status: string;
  company_currency: string;
};

type TAnalyticsActualBenefitState = {
  analyticRateBenefit: TAnalyticRateBenefit;
  searchAnalyticRateBenefit: (currency?: string) => void;
  companyCurrency: string;
  isSearchAnalyticRateBenefitLoading: boolean;
};
export const useAnalyticsActualBenefitState =
  create<TAnalyticsActualBenefitState>()(
    devtools(
      (set, get) => ({
        isSearchAnalyticRateBenefitLoading: true,
        companyCurrency: '',
        analyticRateBenefit: {
          result: '',
          status: '',
          company_currency: '',
        },
        searchAnalyticRateBenefit: async (currency?: string) => {
          const filters = getFiltersForAnalyticsRequests({
            requestName: 'searchAnalyticRateBenefit',
          });
          const response = await axios.post(
            `${API_URL}/analytic-rate-benefit/search`,
            {
              filters: !!filters?.length ? [...filters] : [],
              currency:
                currency ??
                useAnalyticsActualBenefitState.getState().companyCurrency,
            },
            {
              headers: {
                Authorization: axios.defaults.headers.common['Authorization'],
              },
            }
          );
          set(
            produce((draft) => {
              if (response?.data?.data) {
                draft.analyticRateBenefit = response.data.data;
                draft.isSearchAnalyticRateBenefitLoading = false;
              }
              if (currency) {
                draft.companyCurrency = currency;
              }
            }),
            true,
            {
              type: 'useAnalyticsActualBenefitState => searchAnalyticRateBenefit',
            }
          );
        },
      }),
      {
        anonymousActionType: 'useAnalyticsActualBenefitState action',
        name: 'useAnalyticsActualBenefitState',
      }
    )
  );
