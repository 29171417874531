import styled from 'styled-components';
import { Dropdown } from 'antd';

export const DropdownStyled = styled(Dropdown)``;
export const ContainerStyled = styled.div<{ $hoverColor: string }>`
  cursor: pointer;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  transition: 0.1s;

  &:hover {
    background: ${({ $hoverColor }) => $hoverColor};
  }
`;
