import { Centrifuge } from 'centrifuge';
import { WS_URL } from '../../packages/keycloak-client/constants';

interface IChatWorkerConfig {
  token: string;
  getToken: () => Promise<string>;
  debug?: boolean;
  name?: string;
  timeout?: number;
}

interface TSubscribe {
  channelName: string;
  entityId: string | undefined;
  hasReferee: boolean;
  addMessageToMessages: (message: any) => void;
  addAttachmentToLastMessage: (attachment: any) => void;
  searchMessages?: () => Promise<void>;
}

let centrifuge: Centrifuge;

function initChatWorker(config: IChatWorkerConfig) {
  centrifuge = new Centrifuge(WS_URL, config);

  centrifuge.on('error', (error) => {
    console.error('Error WebSocket:', error);
    centrifuge.disconnect();
  });

  centrifuge.on('subscribed', (e) => {
    console.log('Centrifuge subscribed:', e);
  });

  centrifuge.on('unsubscribed', (e) => {
    console.log('Centrifuge unsubscribed:', e);
  });

  centrifuge.on('publication', (e) => {
    console.log('Centrifuge publication:', e);
  });
}

async function subscribe({
  channelName,
  entityId,
  hasReferee,
  addMessageToMessages,
  addAttachmentToLastMessage,
  searchMessages,
}: TSubscribe) {
  try {
    setTimeout(() => {
      console.log('Subscribing to channel:', channelName);
      const chatUserSub = centrifuge.newSubscription(channelName);
      chatUserSub.on('error', (e) => {
        console.error('Error subscribing to channel:', e);
      });
      chatUserSub.on('unsubscribed', (e) => {
        console.log('Unsubscribed from channel:', e);
        chatUserSub.unsubscribe();
      });
      chatUserSub.on('unsubscribed', (e) => {
        console.log('Unsubscribed from channel:', e);
      });
      chatUserSub.on('publication', (pubData) => {
        const { data } = pubData;

        if (data?.type === 'new_message') {
          console.log(
            'data?.type',
            data?.type,
            'entityId',
            entityId,
            'data?.message.chat_id',
            data?.message.chat_id,
            'hasReferee',
            hasReferee,
            'data?.message.participant.type',
            data?.message.participant.type
          );
          addMessageToMessages(data?.message);
          if (entityId) {
            if (data?.message.entity_id === entityId) {
              if (hasReferee && data?.message.participant.type === 'referee') {
                console.log('hasReferee');
                addMessageToMessages(data?.message);
              } else if (data?.message.participant.type !== 'referee') {
                console.log('hasNotReferee');
                addMessageToMessages(data?.message);
              }
            }
          }
          // else {
          //     addMessageToMessages(data?.message);
          // }
          // if (searchMessages) {
          //     searchMessages().then()
          // }
        }
        if (data?.type === 'attachment_uploaded') {
          console.log(
            'data?.type',
            data?.type,
            entityId,
            data.type?.message.chat_id
          );
          if (entityId) {
            if (data.type?.message.entity_id === entityId) {
              addAttachmentToLastMessage(data?.attachment);
            }
          }
          // else {
          //     addAttachmentToLastMessage(data?.attachment);
          // }
          // if (searchMessages) {
          //     searchMessages().then()
          // }
        }
      });
      chatUserSub.subscribe();
      console.log('Subscribed to channel:', channelName);
      return chatUserSub;
    }, 200);
  } catch (error) {
    console.error('Error subscribing to channel:', error);
  }
}

export { centrifuge, subscribe, initChatWorker };
