import { ReactNode } from 'react';
import { Typography } from '@pankod/refine-antd';
import { InfoBlockWrapper } from '../OfferRequestConfirm.styles';

interface IProps {
  title: string;
  children: ReactNode;
}
const InfoBlock = ({ title, children }: IProps) => {
  return (
    <InfoBlockWrapper align={'flex-start'} gap={16} vertical>
      <Typography.Text strong>{title}</Typography.Text>
      {children}
    </InfoBlockWrapper>
  );
};

export default InfoBlock;
