import {create} from 'zustand';
import {IConnection, IPoint, IStaff} from './types';
import {produce} from 'immer';
import {API_URL} from 'packages/keycloak-client/constants';
import {useUserState} from 'shared/state/useUserState';
import {axios} from '../../../../../shared/exios';
import {devtools} from 'zustand/middleware';

type TStep = 1 | 2;

export interface IAccessState {
  isPending: boolean;
  modalStep: TStep;
  points: IPoint[];
  currentPoint: Partial<IPoint>;
  currentStaff: IStaff[];
  currentStaffInitial: IStaff[];
  clientId: number;
  getPoints: (id?: number) => void;
  getPointsStaff: () => void;
  handleEdit: (id: number) => void;
  handleSelect: (id: number) => void;
  handleCheck: (id: number | 'true' | 'false') => void;
  handleSave: (handleSuccess: () => void) => void;
  handleSaveAndAdd: () => void;
  handleAdd: (currentStaff: IStaff[]) => void;
  handleId: (id: string) => void;
  resetStore: () => void;
  resetStep: (id: number) => void;
  successNotificationSave: boolean;
}

export const useAccessState = create<IAccessState>()(
  devtools(
    (set, get) => ({
      clientId: 0,
      isPending: false,
      modalStep: 1,
      points: [],
      currentPoint: {},
      currentStaff: [],
      currentStaffInitial: [],
      successNotificationSave: false,
      successNotificationDelete: false,

      getPoints: async (id?: number) => {
        set(
          produce((draft) => {
            draft.isPending = true;
          })
        );
        try {
          const response: any = await axios.post(
            `${API_URL}/exchange-points/search`,
            {
              limit: 200000000,
              clients_output: 'numbers',
              client_id: get().clientId,
            }
          );
          if (response.status === 200) {
            set(
              produce((draft) => {
                draft.points = [...response.data.data];
              }),
              false,
              {
                type: 'useAccessState => getPoints',
              }
            );
            if (id) {
              get().modalStep = 2;
              get().handleSelect(id);
            }
          }
        } catch {
        } finally {
          set(
            produce((draft) => {
              draft.isPending = false;
            })
          );
        }
      },
      getPointsStaff: async () => {
        set(
          produce((draft) => {
            draft.isPending = true;
          })
        );
        try {
          const response: any = await axios.post(
            `${API_URL}/user-accounts/search`,
            {
              filters: [
                {
                  field: 'exchangePoints.id',
                  operator: '=',
                  value: get().currentPoint.id,
                },
                {
                  type: 'and',
                  field: 'role.name',
                  operator: '!=',
                  value: 'admin',
                },
                {
                  type: 'and',
                  field: 'uid',
                  operator: '!=',
                  value: useUserState.getState().userApi.uid,
                },
              ],
              includes: [
                {relation: 'role'},
                {
                  relation: 'userExchangePointClients',
                  filters: [
                    {
                      field: 'exchange_point_id',
                      operator: '=',
                      value: get().currentPoint.id,
                    },
                    {
                      type: 'and',
                      field: 'client_id',
                      operator: '=',
                      value: get().clientId,
                    },
                  ],
                },
              ],
            }
          );
          if (response.status === 200) {
            set(
              produce((draft) => {
                draft.currentStaff =
                  response.data?.data?.map(
                    (staff: {
                      user_exchange_point_clients?: IConnection[];
                      role: { name: string };
                    }) => ({
                      ...staff,
                      role: staff.role.name,
                      userExchangePointClients:
                      staff.user_exchange_point_clients,
                      isOld:
                        !!staff.user_exchange_point_clients?.length ?? false,
                      isChecked:
                        !!staff.user_exchange_point_clients?.length ?? false,
                    })
                  ) ?? {};
                draft.currentStaffInitial = draft.currentStaff;
              }),
              false,
              {
                type: 'useAccessState => getPointsStaff',
              }
            );
          }
        } catch (e) {
          console.error(e);
        } finally {
          set(
            produce((draft) => {
              draft.isPending = false;
            })
          );
        }
      },
      handleEdit: (id: number) => {
        set(
          produce((draft) => {
            const newPoint =
              draft.points.find((point: { id: number }) => point.id === id) ??
              {};
            draft.currentPoint = newPoint;
          }),
          false,
          {
            type: 'useAccessState => handleEdit',
          }
        );
        get().getPointsStaff();
      },
      handleNext: () =>
        set(
          produce((draft) => {
            draft.modalStep = 2;
          }),
          false,
          {
            type: 'useAccessState => handleNext',
          }
        ),
      handlePrev: () =>
        set(
          produce((draft) => {
            draft.modalStep = 1;
          }),
          false,
          {
            type: 'useAccessState => handlePrev',
          }
        ),
      handleSelect: (id: number) => {
        set(
          produce((draft) => {
            draft.currentPoint =
              draft.points.find((point: { id: number }) => point.id === id) ??
              {};
          }),
          false,
          {
            type: 'useAccessState => handleSelect',
          }
        );
        get().getPointsStaff();
      },
      handleCheck: (id: number | 'true' | 'false') => {
        set(
          produce((draft) => {
            if (id === 'true' || id === 'false') {
              draft.currentStaff = draft.currentStaff.map((staff: IStaff) => {
                return {
                  ...staff,
                  isChecked: JSON.parse(id),
                };
              });
              return;
            }
            draft.currentStaff = draft.currentStaff.map((staff: IStaff) => {
              if (staff.id === id) {
                return {
                  ...staff,
                  isChecked: !staff.isChecked,
                };
              }
              return staff;
            });
          }),
          false,
          {
            type: 'useAccessState => handleCheck',
          }
        );
      },
      handleSave: async (handleSuccess: () => void) => {
        const statusAdd = await get().handleAdd(get().currentStaff);
        console.log('handleSave: async', statusAdd);
        // @ts-ignore
        if (!statusAdd && !statusDelete) return;
        if (
          // @ts-ignore
          statusAdd > 199 &&
          // @ts-ignore
          statusAdd < 299
        ) {
          set(
            produce((draft) => {
              draft.successNotificationSave = true;
            }),
            false,
            {
              type: 'useAccessState => handleSave',
            }
          );
          handleSuccess();
        }
      },
      handleSaveAndAdd: async () => {
        const statusAdd = await get().handleAdd(get().currentStaff);
        console.log('handleSave: async', statusAdd);
        // @ts-ignore
        if (!statusAdd) return;
        if (
          // @ts-ignore
          statusAdd > 199 &&
          // @ts-ignore
          statusAdd < 299
        ) {
          useAccessState
            .getState()
            .resetStep(useAccessState.getState().currentPoint.id ?? 0);
        }
      },
      handleAdd: async (currentStaff) => {
        const staffToAdd = currentStaff.filter(
          (staff) => staff.isOld === false && staff.isChecked === true
        );
        if (!staffToAdd.length) {
          return false;
        }
        const staffToFetch = staffToAdd.map((staff) => ({
          client_id: get().clientId,
          exchange_point_id: get().currentPoint.id,
          user_account_id: staff.uid,
        }));
        try {
          const response = await axios.post(
            `${API_URL}/user-exchange-point-clients/batch`,
            {
              resources: staffToFetch,
            }
          );
          return response.status;
        } catch (e) {
          console.error(e);
          return false;
        }
      },
      handleId: (id: string) => {
        set(
          produce((draft) => {
            if (id.length) {
              draft.clientId = id;
            }
          }),
          false,
          {
            type: 'useAccessState => handleId',
          }
        );
      },
      resetStore: () => {
        set(
          produce((draft) => {
            draft.clientId = 0;
            draft.isPending = false;
            draft.modalStep = 1;
            draft.points = [];
            draft.currentPoint = {};
            draft.currentStaff = [];
            draft.currentStaffInitial = [];
          }),
          false,
          {
            type: 'useAccessState => resetStore',
          }
        );
      },
      resetStep: (id: number) => {
        const filteredPoints = useAccessState
          .getState()
          .points.filter((point: { id: number }) => point.id !== id);
        set(
          produce((draft) => {
            draft.isPending = false;
            draft.modalStep = 1;
            draft.points = JSON.parse(JSON.stringify(filteredPoints));
            draft.currentPoint = {};
            draft.currentStaff = [];
            draft.currentStaffInitial = [];
          }),
          false,
          {
            type: 'useAccessState => resetStep',
          }
        );
      },
    }),
    {
      anonymousActionType: 'useAccessState action',
      name: 'useAccess',
    }
  )
);
