import styled from "styled-components";
import {DownOutlined} from "@ant-design/icons";

export const DownOutlinedStyled = styled(DownOutlined)<{ rotate?: boolean, color?: string }>`
  font-size: 16px;

  svg {
    color: ${(({color}) => color ?? '#000000e0')};
    transition: 0.4s ease transform;
    transform: ${(props) => (props.rotate ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;
