import styled from 'styled-components';
import { Typography } from 'antd';

export const TitleStyled = styled(Typography.Title)`
  &&& {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    margin: 0;
  }
`;
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
`;
export const MainTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
`;

export const DescriptionTitle = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0;
`;
