import {Navigate} from '@pankod/refine-react-router-v6';

export const SessionMiddleware = ({
                                    isAuth,
                                    children,
                                  }: {
  isAuth: boolean;
  children: JSX.Element;
}): JSX.Element => {
  if (isAuth) {
    return children;
  } else {
    return <Navigate to={'/home'}/>;
  }
};
