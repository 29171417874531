import {Avatar, Tooltip} from "antd";
import styled from "styled-components";

export const UserAvatarStyled = styled.img`
    height: 16px;
    width: 16px;
    position: relative;
`;

export const UserAvatarWrapperStyled = styled.div`
    position: relative;
    display: inline-block;
`;

export const UserAvatarIsRefereeStyled = styled.img`
    height: 16px;
    width: 16px;
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 1;
`;

export const AvatarStyled = styled(Avatar)`
    position: relative;
    z-index: 0;
`;

export const TooltipStyled = styled(Tooltip)`
    cursor: pointer;
`;
