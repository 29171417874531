import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {axios} from '../../../shared/exios';
import {getFiltersForAnalyticsRequests} from '../../../shared/helpers/script';

type TAnalyticsOutputState = {
  analyticExpenditures: TAnalyticExpenditures;
  searchAnalyticExpenditures: (currency?: string) => void;
  companyCurrency: string;
  isSearchAnalyticExpendituresLoading: boolean;
};

type TAnalyticExpenditures = {
  result: string;
  status: string;
  company_currency: string;
};

export const useAnalyticsOutputState = create<TAnalyticsOutputState>()(
  devtools(
    (set, get) => ({
      isSearchAnalyticExpendituresLoading: true,
      companyCurrency: '',
      analyticExpenditures: {
        result: '',
        status: '',
        company_currency: '',
      },
      searchAnalyticExpenditures: async (currency?: string) => {
        const filters = getFiltersForAnalyticsRequests({
          requestName: 'searchAnalyticExpenditures',
        });
        const response = await axios.post(
          `${API_URL}/analytic-expenditure/search`,
          {
            filters: !!filters?.length ? [...filters] : [],
            currency:
              currency ?? useAnalyticsOutputState.getState().companyCurrency,
          },
          {
            headers: {
              Authorization: axios.defaults.headers.common['Authorization'],
            },
          }
        );

        set(
          produce((draft) => {
            if (response.data.data) {
              draft.analyticExpenditures = response.data.data;
              draft.isSearchAnalyticExpendituresLoading = false;
            }
            if (currency) {
              draft.companyCurrency = currency;
            }
          }),
          true,
          {
            type: 'useAnalyticsOutputState => setAnalyticExpenditures',
          }
        );
      },
    }),
    {
      anonymousActionType: 'useAnalyticsOutputState action',
      name: 'useAnalyticsOutputState',
    }
  )
);
