import { Flex } from '../../../../shared/components/styled';
import { IInfoList } from '../../model/types';
import { Typography } from '@pankod/refine-antd';
import { Link } from '@pankod/refine-react-router-v6';
import { RequestInfoItemWrapper } from '../OfferRequestPage.styles';

interface IProps extends IInfoList {}

const RequestInfoItem = ({ header, items }: IProps) => {
  return (
    <RequestInfoItemWrapper gap={16} align={'flex-start'} vertical>
      <Flex gap={8}>
        {header.icon}
        <Typography.Text strong>{header.title}</Typography.Text>
      </Flex>
      <Flex gap={8} align={'flex-start'} vertical>
        {items.map(({ label, value, link }) => (
          <Flex gap={8}>
            <Typography.Text type={'secondary'}>{label}</Typography.Text>
            {link ? (
              <Link to={link}>{value}</Link>
            ) : (
              <Typography.Text>{value}</Typography.Text>
            )}
          </Flex>
        ))}
      </Flex>
    </RequestInfoItemWrapper>
  );
};

export default RequestInfoItem;
