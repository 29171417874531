import * as React from 'react';
import { useLayoutEffect, useRef } from 'react';
import {
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
  widget,
} from '../../../charting_library';
import Datafeed from '../model/chartModel/datafeed';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useChartState } from '../model/chartState';

export const TVChartContainer = () => {
  const { i18n } = useTranslation();
  const { currencyKey: mainCurrencyKey } = useChartState(
    (state) => state.mainCurrencySelect
  );
  const { currencyKey: lastCurrencyKey } = useChartState(
    (state) => state.lastCurrencySelect
  );

  const chartContainerRef =
    useRef<HTMLDivElement | null>() as React.MutableRefObject<HTMLInputElement>;

  useLayoutEffect(() => {
    if (!chartContainerRef.current || !mainCurrencyKey || !lastCurrencyKey)
      return;
    const overrides = {
      // 'mainSeriesProperties.areaStyle.linecolor': '#FF0000',
      'mainSeriesProperties.style': 3,
    };
    const widgetOptions: ChartingLibraryWidgetOptions = {
      library_path: '/charting_library/',
      datafeed: Datafeed,
      container: chartContainerRef.current,
      locale: (i18n.language === 'ch'
        ? 'zh'
        : i18n.language === 'jp'
        ? 'ja'
        : i18n.language === 'hi'
        ? 'en'
        : i18n.language) as LanguageCode,
      timezone: 'Europe/Moscow',

      time_frames: [
        {
          text: '1D',
          resolution: '10' as ResolutionString,
          description: '1 day',
        },
        {
          text: '5D',
          resolution: '30' as ResolutionString,
          description: '5 day',
        },
        {
          text: '1m',
          resolution: '1D' as ResolutionString,
          description: '1 month',
        },
        {
          text: '1y',
          resolution: '1D' as ResolutionString,
          description: '1 year',
        },
        {
          text: '5y',
          resolution: 'W' as ResolutionString,
          description: '5 year',
        },
        {
          text: '1000y',
          resolution: '2W' as ResolutionString,
          description: 'All',
          title: 'All',
        },
      ],

      symbol: mainCurrencyKey as string,
      interval: '10' as ResolutionString,
      timeframe: '1D',
      overrides,
      client_id: 'EDENEX',
      user_id: 'public_user_id',
      autosize: true,
      load_last_chart: false,
      theme: 'Light',
      custom_font_family: "'Inter', 'monospace'",

      disabled_features: [
        'header_widget',
        'left_toolbar',
        'items_favoriting',
        'drawing_templates',
        'context_menus',

        'legend_widget',

        'scales_date_format',
        'studies_extend_time_scale',
        'show_percent_option_for_right_margin',
        'timezone_menu',

        // 'timeframes_toolbar',
        'main_series_scale_menu',
        'pressed_mouse_move_scroll',
        'mouse_wheel_scale',
        'mouse_wheel_scroll',
        'popup_hints',
      ],
    };

    const tvWidget = new widget(widgetOptions);

    return () => {
      tvWidget.remove();
    };
  }, [
    i18n.language,
    chartContainerRef.current,
    mainCurrencyKey,
    lastCurrencyKey,
  ]);

  return <ChartStyled ref={chartContainerRef} />;
};

const ChartStyled = styled.div`
  padding: 10px 20px;
  width: 70%;
  height: 80%;
`;
