import { Modal, Typography } from '@pankod/refine-antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const BackModal = ({ onOk, onCancel, isOpen }: IProps) => {
  const { t } = useTranslation(['offers', 'shared']);
  return (
    <Modal
      open={isOpen}
      width={400}
      title={t('shared:модалки.Вы точно хотите выйти вопрос')}
      okText={t('shared:кнопки.Выйти')}
      cancelText={t('shared:кнопки.Отмена')}
      onOk={onOk}
      onCancel={onCancel}
      centered
    >
      <Typography.Text>
        {t('Вы уверены запятая что хотите отменить принятие сделки')}
      </Typography.Text>
    </Modal>
  );
};

export default BackModal;
