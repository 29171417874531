import {
  LibrarySymbolInfo,
  ResolutionString,
} from '../../../../charting_library';
import { makeApiRequest, timeType } from './helpers';
import { useChartState } from '../chartState';

const configurationData: TradingView.DatafeedConfiguration = {
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  exchanges: [],
  symbols_types: [],
  supported_resolutions: [
    '5',
    '10',
    '30',
    'D',
    '3D',
    'W',
    '2W',
  ] as ResolutionString[], //['D', '2D', '3D', 'W', '3W', 'M', '6M']
};

export default {
  onReady: (callback: TradingView.OnReadyCallback) => {
    console.log('[onReady]: Method call');
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: () => {
    console.log('[searchSymbols]: Method call');
  },
  resolveSymbol: (
    symbolName: string,
    onSymbolResolvedCallback: TradingView.ResolveCallback,
    onResolveErrorCallback: TradingView.ErrorCallback
    // extension
  ) => {
    console.log('[resolveSymbol]: Method call', symbolName);

    const {
      lastCurrencySelect: { currencyKey },
      mainCurrencySelect: { currencyKey: currencyKeyMain },
    } = useChartState.getState();

    const symbolInfo: LibrarySymbolInfo = {
      full_name: '',
      format: 'price',
      listed_exchange: 'EDENEX',
      name: currencyKey,
      ticker: currencyKey,
      description: '',
      type: 'crypto',
      session: '24x7',
      timezone: 'Europe/Moscow',
      exchange: currencyKeyMain,
      minmov: 10,
      pricescale: 1000000,
      has_daily: true,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '10', '15', '30'],
      // has_weekly_and_monthly: true,
      supported_resolutions:
        configurationData.supported_resolutions as ResolutionString[],
      volume_precision: 8,
      data_status: 'streaming',
      has_empty_bars: true,
      has_no_volume: true,
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  },
  getBars: async (
    symbolInfo: TradingView.LibrarySymbolInfo,
    resolution: TradingView.ResolutionString,
    periodParams: TradingView.PeriodParams,
    onHistoryCallback: TradingView.HistoryCallback,
    onError: TradingView.ErrorCallback
  ) => {
    const {
      mainCurrencySelect: { currencyKey: mainCurrency },
      lastCurrencySelect: { currencyKey: lastCurrency },
    } = useChartState.getState();
    const { data }: any = await makeApiRequest({
      mainCurrency,
      lastCurrency,
      type: timeType[resolution as keyof typeof timeType],
      // count: periodParams.countBack,
    });

    if (data === null) {
      onError('no data')
    }

    const bars: TradingView.Bar[] = data.map(
      ({ price, date }: any, index: any) => ({
        open: +price,
        high: +price,
        low: +price,
        close: +price,
        time: date * 1000,
      })
    );
    // || +data[0].date < +periodParams.from
    if (!periodParams.firstDataRequest) {
      onHistoryCallback([], { noData: true });
    } else {
      onHistoryCallback(bars, { noData: false });
    }

    //   Fake data
    //   let time = new Date(periodParams.to * 1000);
    //   time.setUTCHours(0);
    //   time.setUTCMinutes(0);
    //   time.setUTCMilliseconds(0);
    // const bars = new Array(periodParams.countBack + 1);
    //
    // let price = 100;
    //
    // for (let i = periodParams.countBack; i > -1; i--) {
    //   // Working out a random value for changing the fake price
    //   const volatility = 0.1;
    //   const x = Math.random() - 0.5;
    //   const changePercent = 2 * volatility * x;
    //   const changeAmount = price * changePercent;
    //   let new_price = price + changeAmount;
    //
    //   bars[i] = {
    //     open: price,
    //     high: price + Math.random() * 10,
    //     low: price - Math.random() * 10,
    //     close: new_price,
    //     time: time.getTime(),
    //   };
    //
    //   price = new_price;
    //
    //   time = new Date(+time - 300 * 1000);
    // }
    //
    // // Once all the bars (usually countBack is around 300 bars) the array of candles is returned to the library
    // onResult(bars);
  },
  subscribeBars: () => {
  },
  unsubscribeBars: () => {},
};
