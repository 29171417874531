import {
  GEOAPIFY_API_KEY,
  GEOAPIFY_API_URL,
} from '../../packages/keycloak-client/constants';
import { useEffect, useState } from 'react';

interface ILocation {
  properties: {
    formatted: string;
  };
  geometry: {
    coordinates: string[];
  };
}
interface IGeoapifyResponse<T> {
  type: string;
  features: T[];
}

export const useAddressAutocomplete = (
  searchValue?: string,
  countryCode?: string,
  city?: string,
  state?: string,
  placeID?: string
) => {
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  console.log(
    'useAddressAutocomplete',
    searchValue,
    countryCode,
    city,
    state,
    placeID
  );

  const getLocation = async () => {
    if (!searchValue || !countryCode) return;

    setLocations([]);
    setIsLoading(true);

    const params = new URLSearchParams({
      apiKey: GEOAPIFY_API_KEY,
      text: `${searchValue ? `${searchValue}` : ''} ${city ? `${city}` : ''} ${
        state ? `${state}` : ''
      }`,
      lang: 'en',
      filter: `countrycode:${countryCode.toLowerCase()}`,
    }).toString();

    fetch(
      `${GEOAPIFY_API_URL}/autocomplete?${params}&${
        placeID ? `filter=place:${placeID}` : ''
      }`
    )
      .then((res) => res.json())
      .then(({ features }: IGeoapifyResponse<ILocation>) => {
        setLocations(features);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    (async function () {
      await getLocation();
    })();
  }, [searchValue, countryCode]);

  return {
    locations: locations,
    isLoading,
  };
};
