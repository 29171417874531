import { Input } from '@pankod/refine-antd';
import {
  ICreateOfferProperties,
  useCreateOfferState,
} from '../model/CreateOfferState';
import { ChangeEvent, useEffect } from 'react';
import { IFreezingPeriod } from '../model/types';

interface IProps {
  field: keyof ICreateOfferProperties;
  onChange?: (value: string | number) => void;
  suffix?: string;
}

const SelfControlInput = (props: IProps) => {
  const { onChange, suffix } = props;

  const value: string = useCreateOfferState(
    (state) => state[props.field] as string
  );

  const changeField = useCreateOfferState((state) => state.changeField);

  const triggerChange = (value: string) => {
    onChange?.(value);
  };

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = changeField(props.field, e.target.value);
    triggerChange(updatedValue);
  };
  return <Input value={value} onChange={onNumberChange} suffix={suffix} />;
};

export default SelfControlInput;
