import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {axios} from '../exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {createOptions} from 'shared/helpers/script';

type TOperationsChainTypesState = {
  operationsChainTypesData: any;
  operationsChainTypesMeta: any;
  searchOperationsChainTypes: ({setFilters}: any) => void;
  setOperationsChainTypes: ({
                              operationsChainTypesData,
                              operationsChainTypesMeta,
                            }: any) => void;
};

type TSetOperationsChainTypes = {
  operationsChainTypesData: any;
  operationsChainTypesMeta: any;
};

export const useOperationsChainTypesState =
  create<TOperationsChainTypesState>()(
    devtools(
      (set, get) => ({
        operationsChainTypesData: [],
        operationsChainTypesMeta: {},
        searchOperationsChainTypes: async ({setFilters}: any) => {
          const response = await axios.post(
            `${API_URL}/operations-chain-types/search`,
            {
              limit: 10000,
              page: 1,
            }
          );
          const options = createOptions(
            response.data.data,
            'useOperationsChainTypeState'
          );
          localStorage.setItem('operationTypes', JSON.stringify(options));
          setFilters({
            options,
            selectId: 'operationTypes',
            isFromRequest: true,
          });
          set(
            produce((draft) => {
              draft.setOperationsChainTypes({
                operationsChainTypesData: response.data.data,
                // @ts-ignore
                operationsChainTypesMeta: response.data.meta,
              });
            }),
            false,
            {
              type: 'useOperationsChainTypesState => searchOperationsChainTypes',
            }
          );
        },
        setOperationsChainTypes: ({
                                    operationsChainTypesData,
                                    operationsChainTypesMeta,
                                  }: TSetOperationsChainTypes) =>
          set(
            produce((draft) => {
              draft.operationsChainTypesData = operationsChainTypesData;
              draft.operationsChainTypesMeta = operationsChainTypesMeta;
            }),
            false,
            {
              type: 'useOperationsChainTypesState => setOperationsChainTypes',
            }
          ),
      }),
      {
        anonymousActionType: 'useOperationsChainTypesState action',
        name: 'operationsChainTypesState',
      }
    )
  );
