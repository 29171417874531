import React from 'react';

type TPropsErrorBoundary = {
  children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<
  TPropsErrorBoundary,
  { hasError: boolean }
> {
  constructor(props: TPropsErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
