import { AutoComplete, AutoCompleteProps, Button } from '@pankod/refine-antd';
import styled from 'styled-components';

export const MapWrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  position: absolute;
  width: 700px;
  top: 24px;
  left: calc(50% - 300px);
  z-index: 999;
`;

export const AutoCompleteStyled: React.ComponentType<AutoCompleteProps> = styled(
  AutoComplete
)`
  margin-bottom: 20px;
  width: 500px;
  display: inline-block;
  margin-right: 20px;
  height: 40px;
  &&& .ant-select-selector {
    background-color: white;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 40px;
`;
export const NotFoundAddressTextStyled = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #00000040;
`;
export const NotFoundAddressContainerStyled = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const SpinWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;
