import { TableStyled } from '../../../../styles';
import React, { useMemo, useState } from 'react';
import { useResize } from '../../../../shared/hooks/useResize';
import { ColumnsType } from 'antd/es/table';
import { TRole } from '../../../aboutStaff/model';
import { IExchangePoint } from '../../../ExchangePoints/interface';
import { useUserRole } from '../../../../shared/hooks/useUserRole';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  IUserAccountWithPoints,
  useUserAccountState,
} from './model/useUserAccountsState';
import { Link, useNavigate } from '@pankod/refine-react-router-v6';
import CheckCircleFilledStyle from '../../../../shared/components/ui/checkCircleFilledStyle';
import CloseCircleFilledStyle from '../../../../shared/components/ui/CloseCircleFilled';
import { Tag } from 'antd';
import { Button, Col } from '@pankod/refine-antd';
import { useUserState } from '../../../../shared/state/useUserState';
import { CloseOutlined, StopOutlined } from '@ant-design/icons';

interface StaffTablesType {
  key: number;
  name: string;
  phone: string;
  status: 'active' | 'ban';
  role: TRole | '';
  nickname: string;
  email: string;
  debt: string | null;
  debt_status: boolean;
  exchange_points?: IExchangePoint[];
  operations_chains?: any[];
  company_currency_key: string;
  uid: string;
}

const StaffTab = () => {
  const navigate = useNavigate();
  const myId = useUserState((state) => state.userApi.id);
  const { isScreen350, isScreen1024 } = useResize();
  const userRole = useUserRole();
  const { t, i18n } = useTranslation(['employees', 'accounts', 'shared']);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detachExchangePointName, setDetachExchangePointName] = useState<
    undefined | string
  >(undefined);

  const userAccounts = useUserAccountState((state) => state.userAccountsData);
  const userAccountsMeta = useUserAccountState(
    (state) => state.userAccountsMeta
  );
  const isLoadingUserAccounts = useUserAccountState((state) => state.isLoading);
  const userAccountsPage = useUserAccountState((state) => state.page);
  const userAccountsLoading = useUserAccountState((state) => state.isLoading);
  const setUserAccountsPage = useUserAccountState((state) => state.setPage);

  const stopPropagation = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  const closeDetachEmployeeModal = () => {
    setModalIsOpen(false);
  };

  const openDetachEmployeeModal = () => {
    setModalIsOpen(true);
  };

  // const rowClick = (record: any) => {
  //   if (activeTab === 'invites') {
  //     return;
  //   }
  //   return {
  //     onClick: (e: any) => {
  //       e.stopPropagation();
  //       e.preventDefault();
  //       navigate(`${record.key}`);
  //     },
  //   };
  // };

  const dropdownItems = [
    {
      key: 'exchangePointsList',
      label: t('shared:тексты.Список пунктов обмена'),
    },
    {
      key: 'AccountsList',
      label: t('shared:тексты.Список счетов'),
    },
    {
      key: 'clientsList',
      label: t('shared:тексты.Список клиентов'),
    },
    {
      key: 'changeStaffData',
      label: t('shared:тексты.Изменить данные сотрудника'),
    },
    {
      key: 'editAccess',
      label: t('shared:тексты.Редактировать доступы'),
    },
  ];

  const columns: ColumnsType<IUserAccountWithPoints> = useMemo(() => {
    const value: ColumnsType<IUserAccountWithPoints> = [
      {
        title: '',
        key: 'status',
        width: '3%',
        render: (value, { status }) => {
          return (
            <StatusWrapper>
              {status === 'active' ? (
                <CheckCircleFilledStyle />
              ) : (
                <CloseCircleFilledStyle />
              )}
            </StatusWrapper>
          );
        },
      },
      {
        title: t('shared:тексты.Сотрудник'),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        render: (value, { firstname, lastname, username, id }) => (
          <CellBlock>
            <UsernameBlock>
              <Link to={`/staff/${id}`}>{username}</Link>
              <Col span={3}>
                {String(id) === String(myId) && (
                  <Tag>{t('shared:тексты.Это вы')}</Tag>
                )}
              </Col>
            </UsernameBlock>
            <SubBlock>{`${firstname} ${lastname}`}</SubBlock>
          </CellBlock>
        ),
      },
      {
        title: t('shared:тексты.Роль'),
        dataIndex: 'role',
        key: 'role',
        width: '10%',
        render: (_, { role }) =>
          t(
            `${
              role?.name === 'admin'
                ? 'shared:роли.Администратор'
                : role?.name === 'cashier'
                ? 'shared:роли.Кассир'
                : role?.name === 'manager'
                ? 'shared:роли.Менеджер'
                : '-'
            }`
          ),
      },
      {
        title: t('shared:тексты.Способ связи'),
        dataIndex: 'communicationMethod',
        width: '15%',
        key: 'communicationMethod',
        render: (_, { email, phone_number }) => {
          return (
            <CellBlock>
              <EmailStyled>{email}</EmailStyled>
              <SubBlock>{phone_number}</SubBlock>
            </CellBlock>
          );
        },
      },
      {
        title: t('Связанные пункты обмена'),
        key: 'relatedExchangePoints',
        width: '30%',
        render: (_, { exchange_points }) => {
          const handleLoose = () => {
            setModalIsOpen(true);
          };
          return (
            <ExchangePointsBlock>
              <div>
                {exchange_points.map((point) => (
                  <div>{point.name}</div>
                ))}
              </div>
              {/*<ActiveTabBlock>*/}
              {/*  {userRole === 'admin' && (*/}
              {/*    <ButtonStyled*/}
              {/*      onClick={handleLoose}*/}
              {/*      icon={<CloseOutlinedStyled />}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*<Dropdown*/}
              {/*  trigger={['click']}*/}
              {/*  menu={{*/}
              {/*    items: dropdownItems,*/}
              {/*    onClick: ({ key, domEvent }) =>*/}
              {/*      //@ts-ignore*/}
              {/*      dropdownClick(key, domEvent, record),*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <ButtonStyled icon={<MoreOutlined />} />*/}
              {/*</Dropdown>*/}
              {/*TODO встроить в дальнейшем*/}
              {/*</ActiveTabBlock>*/}
            </ExchangePointsBlock>
          );
        },
      },
    ];
    return value;
  }, [userRole, i18n.language]);

  if (userAccounts.length === 0) {
    return (
      <NoDataBlock>
        <NoDataContent>
          <StopOutlinedStyled />
          <span>{t('К счёту не прикреплены сотрудники')}</span>
        </NoDataContent>
      </NoDataBlock>
    );
  }

  return (
    <TableWrapper>
      <TableStyled
        $isScreen350={isScreen350}
        $isScreen1024={isScreen1024}
        columns={columns as StaffTablesType[]}
        dataSource={userAccounts}
        loading={isLoadingUserAccounts}
        pagination={{
          total: userAccountsMeta?.total,
          current: userAccountsPage,
          onChange: (page) => setUserAccountsPage(page),
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        // onRow={rowClick}
      />
      {/*<DetachEmployeeModal*/}
      {/*  closeModal={closeDetachEmployeeModal}*/}
      {/*  isOpen={modalIsOpen}*/}
      {/*  exchangePointName={detachExchangePointName}*/}
      {/*/>*/}
      {/*Встроить потом*/}
    </TableWrapper>
  );
};

export default StaffTab;

const TableWrapper = styled.div`
  padding-top: 24px;
`;

const NoDataBlock = styled.div`
  color: rgba(0, 0, 0, 0.45);
  min-height: 364px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`;

const NoDataContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const CropText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CellBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UsernameBlock = styled.div`
  display: flex;
  gap: 16px;
`;

const SubBlock = styled.div`
  color: rgba(0, 0, 0, 0.45);
  max-width: 300px;
  word-break: break-word;
`;

const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const EmailStyled = styled.div`
  max-width: 200px;
  min-width: 150px;
  word-break: break-all;
`;

const ActiveTabBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ButtonStyled = styled(Button).attrs({
  type: 'text',
})``;

const ExchangePointsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const CloseOutlinedStyled = styled(CloseOutlined)`
  color: rgba(255, 77, 79, 1);
`;

const StopOutlinedStyled = styled(StopOutlined)`
  font-size: 42px;
`;
