import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'; // https://react.i18next.com/latest/using-with-hooks
import Backend from 'i18next-http-backend'; //
// adding lazy loading for translations, more information here: https://github.com/i18next/i18next-http-backend
import detector from 'i18next-browser-languagedetector'; // auto detect the user language, more information here: https://github.com/i18next/i18next-browser-languageDetector

//For fix cache issue as varinat
//
//another disable cache at all
// /*      requestOptions: {
//         cache: 'no-store',
//       },*/

function genRandomNumber(length: number) {
  const chars = '0123456789';
  const charLength = chars.length;
  let result = '';
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

const HASH = genRandomNumber(10);

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr', 'jp', 'pt', 'ch', 'es', 'ru', 'hi'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // locale files path
      /*      requestOptions: {
        cache: 'no-store',
      },*/
      backendOptions: [
        {
          expirationTime: 24 * 60 * 60 * 1000 * 7, // 7 days
          defaultVersion: 'v' + HASH, // generate a new version every build to refresh
        },
      ],
    },
    defaultNS: 'common',
    fallbackLng: ['en', 'ar', 'fr', 'jp', 'pt', 'ch', 'es', 'ru', 'hi'],
    debug: false,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
