import {API_URL} from "../../../packages/keycloak-client/constants";
import { IExchangePoint} from "../../../interfaces";
import {TPoints} from "../model/types";
import { requestWithCache } from '../../../shared/helpers/requestWithCache';
export const getExchangePoints = async (): Promise<TPoints> => {
  try {
    const res = await requestWithCache(`${API_URL}/exchange-points/search`, {
      method: 'POST',
      data: {
        limit: 1000,
        page: 1,
      }
    });
    return {arr: (res as { data: IExchangePoint[]; meta: { total: number } })?.data, type: 'points'}
  } catch {
    return {arr: [], type: 'points'}
  }
}
