import { IExchangePointOffer } from '../types';
import { IResponseShell } from '../../../../shared/interfaces';
import { API_URL } from '../../../../packages/keycloak-client/constants';
import { axios } from '../../../../shared/exios';

export enum ECreateExchangeOfferErrors {
  'Exchange Point must be published' = 'Не опубликован на Exchange Map',
  'Exchange Point must not be in scam list' = 'Находится в списке скам-обменников',
  'Exchange Point must be in status works' = 'Статус работы пункта - не работает',
  'Exchange Point must not be disabled in monitoring' = 'Скрыт администратором Exchange Map',
  "Exchange Point must not be on admin's check" = 'Находится на проверке администратором Exchange Map',
  "You can't operate this exchange point offers" = 'У вас нет доступа ни к одному пункту обмена. Создайте пункт или обратитесь к руководителю',
}

export enum ECreateExchangeOfferOneError {
  'Exchange Point must be published' = 'Для работы с офферами опубликуйте пункт обмена или обратитесь к руководителю',
  'Exchange Point must not be in scam list' = 'Пункт обмена находится в списке скам-обменников. Для создания оффера выберите другой пункт или обратитесь к руководителю',
  'Exchange Point must be in status works' = 'Для работы с офферами верните пункт обмена в работу или обратитесь к руководителю',
  'Exchange Point must not be disabled in monitoring' = 'Пункт обмена скрыт администратором ExchangeMap. Выберите другой пункт обмена или обратитесь к руководителю',
  "Exchange Point must not be on admin's check" = 'Для работы с офферами дождитесь подтверждения проверки пункта администратором ExchangeMap или обратитесь к руководителю',
  "You can't operate this exchange point offers" = 'Для работы с офферами опубликуйте пункт обмена или обратитесь к руководителю',
}

interface ICreateOfferError extends Error {
  errors: string[];
}

export class CreateOfferError extends Error implements ICreateOfferError {
  errors;
  constructor(errors: (keyof typeof ECreateExchangeOfferErrors)[]) {
    super('Create offer error');
    this.errors = errors;
  }
}

export const createExchangePointOffer = (offerData: IExchangePointOffer) => {
  return axios
    .post<IResponseShell<IExchangePointOffer>>(
      `${API_URL}/exchange-point-offers`,
      offerData
    )
    .then(({ data }) => data.data)
    .catch((res: any) => {
      throw new CreateOfferError(res.response.data.errors.exchange_point_id);
    });
};
