import React, { useEffect, useState } from 'react';
import { MMFTableType } from '../types/types';
import { TableStyled } from '../../../styles';
import {
  FlexColumnWrapper,
  IndexStatusWrapper,
  StyledDataText,
  TableWrapper,
} from '../ui/styles';
import { useResize } from '../../../shared/hooks/useResize';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleFilled,
  CheckOutlined,
  ClockCircleFilled,
  CloseOutlined,
  ExclamationCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import {
  cashFormatter,
  ISOToDateAndTime,
} from '../../../shared/helpers/script';
import { Link, useNavigate } from '@pankod/refine-react-router-v6';
import { IOperation } from '../../../interfaces';
import styled from 'styled-components';
import { useUserRole } from '../../../shared/hooks/useUserRole';
import { useCustomMutation } from '@pankod/refine-core';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { Modal, notification } from '@pankod/refine-antd';
import BigNumber from 'bignumber.js';

type propsType = {
  accountOperationsData: IOperation[];
  refetchAll: () => void;
};

const MMFTab = ({ accountOperationsData, refetchAll }: propsType) => {
  const { t } = useTranslation(['operationsAndCashFlow', 'shared']);
  const { isScreen350, isScreen1024 } = useResize();

  const [api, contextHolder] = notification.useNotification();

  const [openConfirmChangeStatusModal, setOpenConfirmChangeStatusModal] =
    useState(false);

  const [modalState, setModalState] = useState({
    okText: '',
    title: '',
    description: '',
    id: 0,
    newStatus: '',
  });

  const { mutateAsync: customMutateAsync } = useCustomMutation();

  const [tableData, setTableData] = useState<MMFTableType[]>([]);

  const navigate = useNavigate();

  const userRole = useUserRole();

  const changeOperationHandler = (
    newStatus: 'canceled' | 'waiting' | 'closed',
    oldStatus: 'canceled' | 'waiting' | 'closed',
    id: any
  ) => {
    if (newStatus === 'closed' && oldStatus === 'waiting') {
      customMutateAsync(
        {
          url: `${API_URL}/operations/${id}`,
          method: 'put',
          values: { status: newStatus },
        },
        {
          onSuccess: () => {
            refetchAll();
            return;
          },
        }
      );
    }
    if (newStatus === 'canceled') {
      setModalState({
        okText: t('shared:тексты.Отменить ДДС'),
        title: t('shared:тексты.Отменить ДДС?'),
        description: t(
          `При возврате ДДС в работу остаток счета будет изменён.`
        ),
        id: id,
        newStatus: newStatus,
      });
      setOpenConfirmChangeStatusModal(true);
    }
    if (newStatus === 'waiting') {
      setModalState({
        okText: t('Вернуть ДДС'),
        title: t(`Вернуть ДДС в работу?`),
        description: t(
          `При возврате ДДС в работу остаток счета будет изменён.`
        ),
        id: id,
        newStatus: newStatus,
      });
      setOpenConfirmChangeStatusModal(true);
    }
  };

  const confirmChangeStatusHandler = () => {
    customMutateAsync(
      {
        url: `${API_URL}/operations/${modalState.id}`,
        method: 'put',
        values: { status: modalState.newStatus },
      },
      {
        onSuccess: () => {
          refetchAll();
          return;
        },
      }
    );
    setOpenConfirmChangeStatusModal(false);
  };

  const operationsColumns: ColumnsType<MMFTableType> = [
    {
      title: ' ',
      dataIndex: 'indexCode',
      key: 'indexCode',
      width: 120,
      render: (_, record) => {
        const status: 'canceled' | 'waiting' | 'closed' =
          record.operationStatus;
        return (
          <IndexStatusWrapper>
            {status === 'canceled' && (
              <ClockCircleFilled
                style={{ color: '#FF7875', fontSize: '16px' }}
              />
            )}
            {status === 'waiting' && (
              <ClockCircleFilled
                style={{ color: '#FFC069', fontSize: '16px' }}
              />
            )}
            {status === 'closed' && (
              <CheckCircleFilled
                style={{ color: '#13C2C2', fontSize: '16px' }}
              />
            )}
            <div>{record.index_code}</div>
          </IndexStatusWrapper>
        );
      },
    },
    {
      title: t('shared:тексты.Объём средств'),
      dataIndex: 'amountOfFunds',
      key: 'amountOfFunds',
      width: 300,
      render: (_, record) => {
        return (
          <FlexColumnWrapper>
            {record.direction === 'input'
              ? `+  ${
                  record.zerosNumber &&
                  cashFormatter(
                    BigNumber(record?.amount || 0).toFixed(record.zerosNumber)
                  )
                } `
              : `-  ${
                  record.zerosNumber &&
                  cashFormatter(
                    BigNumber(record?.amount || 0).toFixed(record.zerosNumber)
                  )
                } `}
            {record.currency}
            <div>{amountOfFundsMap[record.direction]}</div>
          </FlexColumnWrapper>
        );
      },
    },
    {
      title: t('shared:тексты.Счёт'),
      dataIndex: 'account',
      key: 'account',
      width: 250,
      render: (_, record) => {
        return (
          <FlexColumnWrapper>
            <div className="lineClamp">{record.accountName}</div>
            <div>
              <Tag
                key={record.type}
                style={{
                  color: 'rgba(0, 0, 0, 0.88)',
                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                }}
              >
                {accountTypeMap[record.type as keyof typeof accountTypeMap]}
              </Tag>
            </div>
          </FlexColumnWrapper>
        );
      },
    },
    {
      title: t('shared:таблицы.Дата и время'),
      dataIndex: 'dateAndTime',
      key: 'dateAndTime',
      width: 220,
      render: (_, record) => {
        return (
          <div>
            <div>{ISOToDateAndTime(record.date || '-').slice(0, 10)}</div>
            <StyledDataText>
              {ISOToDateAndTime(record.date || '-').slice(10)}
            </StyledDataText>
          </div>
        );
      },
    },
    {
      title: t('shared:тексты.Пункт обмена'),
      dataIndex: 'exchangePoint',
      key: 'exchangePoint',
      width: 170,
      onCell: () => ({
        onClick: (event: any) => {
          event.preventDefault();
          event.stopPropagation();
        },
      }),
      render: (_, record) => {
        return (
          <Link className="lineClamp" to={`/points/${record.exchangePointId}`}>
            <TextWrapper>{record.exchangePointName}</TextWrapper>
          </Link>
        );
      },
    },
    {
      title: ' ',
      dataIndex: 'changeStatusBtn',
      key: 'changeStatusBtn',
      width: 130,
      onCell: () => ({
        onClick: (event: any) => {
          event.preventDefault();
          event.stopPropagation();
        },
      }),
      render: (_, record) => {
        switch (userRole) {
          case 'admin': {
            switch (record.operationStatus) {
              case 'closed': {
                return (
                  <IconsContainerStyled>
                    <UndoOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(250, 140, 22, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler('waiting', 'closed', record.id);
                      }}
                    />
                    <CloseOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(255, 77, 79, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler('canceled', 'closed', record.id);
                      }}
                    />
                  </IconsContainerStyled>
                );
              }
              case 'waiting': {
                return (
                  <IconsContainerStyled>
                    <CheckOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(19, 194, 194, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler('closed', 'waiting', record.id);
                      }}
                    />
                    <CloseOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(255, 77, 79, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler(
                          'canceled',
                          'waiting',
                          record.id
                        );
                      }}
                    />
                  </IconsContainerStyled>
                );
              }
              case 'canceled': {
                return (
                  <IconsContainerStyled>
                    <UndoOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(250, 140, 22, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler(
                          'waiting',
                          'canceled',
                          record.id
                        );
                      }}
                    />
                  </IconsContainerStyled>
                );
              }
              default:
                return <></>;
            }
          }
          case 'manager': {
            switch (record.operationStatus) {
              case 'waiting': {
                return (
                  <IconsContainerStyled>
                    <CheckOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(19, 194, 194, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler('closed', 'waiting', record.id);
                      }}
                    />
                    <CloseOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(255, 77, 79, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler(
                          'canceled',
                          'waiting',
                          record.id
                        );
                      }}
                    />
                  </IconsContainerStyled>
                );
              }
              default:
                return <></>;
            }
          }
          case 'cashier': {
            switch (record.operationStatus) {
              case 'waiting': {
                return (
                  <IconsContainerStyled>
                    <CheckOutlined
                      style={
                        record.parentOperationStatus === 'waiting'
                          ? { color: 'rgba(19, 194, 194, 1)' }
                          : {
                              color: 'rgba(126,122,122,0.88)',
                              cursor: 'not-allowed',
                            }
                      }
                      onClick={() => {
                        if (record.parentOperationStatus !== 'waiting') {
                          return;
                        }
                        changeOperationHandler('closed', 'waiting', record.id);
                      }}
                    />
                  </IconsContainerStyled>
                );
              }
              default:
                return <></>;
            }
          }
          default:
            return <></>;
        }
      },
    },
  ];

  const onRowRedirectHandler = (rowData: MMFTableType) => {
    return {
      onClick: (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/chains/${rowData.operations_chain_id}/${rowData.id}`);
      },
    };
  };

  const amountOfFundsMap: any = {
    input: (
      <Tag color="green" style={{ padding: '0 8px' }}>
        <ArrowDownOutlined />
        <span style={{ marginLeft: 4 }}>{t('shared:тексты.Пополнение')}</span>
      </Tag>
    ),
    output: (
      <Tag color="purple" style={{ padding: '0 8px' }}>
        <ArrowUpOutlined />
        <span style={{ marginLeft: 4 }}>{t('shared:тексты.Списание')}</span>
      </Tag>
    ),
  };

  const accountTypeMap: Record<
    'bank' | 'physical_safe' | 'electronic_wallet',
    string
  > = {
    bank: t('shared:типы.Счёт в банке'),
    physical_safe: t('shared:типы.Физическое хранилище'),
    electronic_wallet: t('shared:типы.Электронный кошелёк'),
  };

  useEffect(() => {
    const table: MMFTableType[] = [];
    accountOperationsData?.map((el: IOperation) => {
      table.push({
        key: el.id,
        operationStatus: el.status,
        index_code: el.index_code,
        amount: el.amount,
        zerosNumber: el?.account?.currency?.zeros_number,
        currency: el.currency_key,
        direction: el.direction,
        accountName: el?.account?.shortname,
        type: el?.account?.type,
        date: el.created_at,
        exchangePointName: el?.exchange_point?.name,
        exchangePointId: el?.exchange_point?.id,
        operations_chain_id: el?.operations_chain_id,
        id: el.id,
        parentOperationStatus: el?.operations_chain?.status,
      });
    });
    setTableData(table);
  }, [accountOperationsData]);

  return (
    <>
      {contextHolder}
      <Modal
        width={400}
        open={openConfirmChangeStatusModal}
        onCancel={() => {
          if (openConfirmChangeStatusModal) {
            setOpenConfirmChangeStatusModal(false);
          }
        }}
        cancelText={t('shared:кнопки.Назад')}
        okText={modalState.okText}
        onOk={confirmChangeStatusHandler}
      >
        <ModalHeaderStyled>
          <ExclamationCircleOutlined
            style={{ fontSize: 24, color: '#FAAD14' }}
          />
          {modalState.title}
        </ModalHeaderStyled>
        <ModalDescriptionStyled>
          {modalState.description}
        </ModalDescriptionStyled>
      </Modal>
      <TableWrapper>
        <TableStyled
          $isScreen350={isScreen350}
          $isScreen1024={isScreen1024}
          style={{ borderRadius: 0 }}
          dataSource={tableData}
          pagination={false}
          columns={operationsColumns as any[]}
          onRow={onRowRedirectHandler}
        />
      </TableWrapper>
    </>
  );
};

export default MMFTab;

const TextWrapper = styled.span`
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ModalHeaderStyled = styled.div`
  font-weight: bold;
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalDescriptionStyled = styled.div`
  font-size: 13px;
  margin-left: 40px;
`;

const IconsContainerStyled = styled.div`
  display: flex;
  gap: 10px 20px;
  align-items: center;

  span {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  span:hover {
    background-color: rgba(182, 182, 182, 0.18);
  }

  svg {
    zoom: 120%;
  }
`;
