import styled from "styled-components";

export const CreateOfferExitModalTitleBlockStyled = styled.div`
    display: flex;
    gap: 16px;
`;

export const CreateOfferExitModalTitleStyled = styled.div`
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.88);
    font-family: "SF Pro Text", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

export const CreateOfferExitModalDescStyled = styled.div`
    color: rgba(0, 0, 0, 0.88);
    font-family: "SF Pro Text", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 39px;
    margin-top: 5px;
`;
