import { useEffect, useState } from 'react';
import { axios } from '../../../../shared/exios';
import { API_URL } from '../../../../packages/keycloak-client/constants';
import { IResponseShell } from '../../../../shared/interfaces';
import { IRequest } from '../types';

const useOfferRequest = (requestId: number) => {
  const [currentRequest, setCurrentRequest] = useState<IRequest>(
    {} as IRequest
  );
  const [isLoading, setIsLoading] = useState(true);

  const getRequest = async (requestId: number) => {
    setIsLoading(true);
    try {
      const filter = {
        field: 'id',
        operator: '=',
        value: requestId,
      };

      const res = await axios.post<IResponseShell<IRequest[]>>(
        `${API_URL}/exchange-point-offer-requests/search`,
        {
          includes: [
            { relation: 'offer.exchange_point.company' },
            { relation: 'history.initiator' },
            { relation: 'cash_user' },
            { relation: 'cash_account' },
            { relation: 'offer.cash_payment_system_currency' },
            { relation: 'offer.crypto_payment_system_currency' },
            { relation: 'operations_chain' },
            { relation: 'client' },
          ],
          filters: [filter],
        }
      );
      return res.data.data[0];
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setRequest = async () => {
    const request = await getRequest(requestId);

    if (request) {
      setCurrentRequest(request);
    }
  };

  useEffect(() => {
    (async function () {
      await setRequest();
    })();
  }, [requestId]);

  return {
    isLoading,
    request: currentRequest,
    refetch: setRequest,
  };
};

export default useOfferRequest;
