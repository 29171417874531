import { Button, Input, InputProps } from '@pankod/refine-antd';
import { CloseOutlined } from '@ant-design/icons';
import { Flex } from '../../../shared/components/styled';
import { FocusEvent, ChangeEvent } from 'react';
import { TRequestForConfirmData } from '../model/useRequestConfirmState';
import { InputStyled } from './OfferRequestConfirm.styles';

type TFieldName = keyof TRequestForConfirmData;

interface IProps {
  value: string | undefined;
  onChange: (value: string) => void;
  onReset: () => void;
  disabled: boolean;
  suffix: InputProps['suffix'];
}

const CustomInputWithClear = ({
  value,
  onChange,
  onReset,
  disabled,
  suffix,
}: IProps) => {
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      onReset();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Flex gap={8}>
      <InputStyled
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        size={'small'}
        disabled={disabled}
        suffix={suffix}
      />
      <Button
        onClick={onReset}
        type={'link'}
        size={'small'}
        icon={<CloseOutlined style={{ color: '#FF4D4F' }} />}
      />
    </Flex>
  );
};

export default CustomInputWithClear;
