import { default as baseAxios } from 'axios';
import {
  API_URL,
  APP_NAME
} from './constants';

import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router";

export const axios = baseAxios.create({ baseURL: API_URL });

async function getImport () {
  const name: string = APP_NAME;
  if (name === 'account') return 'src/state/useAuthState'
  if (name === 'finms') return 'src/state/useAuthState'
  if (name === 'birga') return 'src/useAuthState'
  else {
    return ''
  }
}

export const useCustomCookies = () => {
  const [cookie, setCookie, removeCookie] = useCookies(['token', 'oidc.user', 'email', 'uid', 'refresh_token']);
  const navigate = useNavigate();
  console.log('useCustomCookies', cookie, setCookie, removeCookie, navigate )
  return { cookie, setCookie, removeCookie, navigate };
};

// @ts-ignore
axios.interceptors.request.use(
  (config: any) => {
    const userJson =
      localStorage.getItem(
        `oidc.user`
      ) ||
      sessionStorage.getItem(
        `oidc.user`
      );
    if (!!userJson) {
      const user = JSON.parse(userJson);
      if (user?.access_token) {
        config.headers['Authorization'] = `Bearer ${user?.access_token}`;
      }
    }
    return config;
  },
  // @ts-ignore
  (error: any) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async error => {
    console.log('error?.response full', error?.response)
    console.log('error?.response?.status', error?.response?.status, 'error.response.data.message', error.response.data.message)
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      // if (error.response.data.message === '[Keycloak Guard] Expired token') {
        const modulePath = await getImport();
        console.log('modulePath', modulePath)
        if (modulePath) {
          // @ts-ignore
          const { default: useAuthState } = await import(modulePath);
          console.log('useAuthState', useAuthState)
          const originalRequest = error.config;
          console.log('originalRequest', originalRequest)
          console.log('interceptors.response useAuthState.getState()', useAuthState.getState())
          const { cookie, setCookie, removeCookie, navigate } = useCustomCookies();
          return useAuthState.getState().refreshToken({
            setCookie,
            cookie,
            session: cookie['oidc.user'],
            navigate,
            removeCookie,
            config: originalRequest
          });
        }
      // }
    }
    // @ts-ignore
    return Promise.reject(error);
  },
);
