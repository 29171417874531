import { Button, Select } from "@pankod/refine-antd";
import { Flex } from "shared/components/styled";
import styled from "styled-components";

export const SelectStyled = styled(Select)`
  width: 280px;
  &&& {
    .ant-select-selector {
      height: 32px;
    }
    .ant-select-selection-search-input {
      height: 32px;
    }
    .ant-select-selection-placeholder {
      line-height: 32px;
    }
    .ant-select-selection-item {
      line-height: 32px;
    }
  }
`;

export const SpinWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const NotFoundAddressTextStyled = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #00000040;
`;

export const NotFoundAddressContainerStyled = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MapButton = styled(Button)`
  &&& {
    border: 1px solid #1677ff;
    background: transparent;

    &:hover {
      background: transparent;
      border: 1px solid #4096ff;
    }

    &:active {
      background: transparent;
      border: 1px solid #0958d9;
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.04);
      border: 1px solid #00000026;
    }
  }
  .anticon {
    color: #1677ff;
    &:hover {
      color: #4096ff;
    }
  }
`;

export const FlexWrapperStyled = styled(Flex)`
  .locationSelect {
    margin-bottom: 0;
    width: 156px;
    .ant-select-selector {
      height: 32px;
    }
    .ant-select-selection-placeholder {
      line-height: 32px;
    }
    .ant-select-selection-item {
      line-height: 32px;
    }
    #country_code {
      height: 32px;
    }
    #city {
      height: 32px;
    }
  }
`;
