import { Typography } from '@pankod/refine-antd';
import {
  ECreateExchangeOfferErrors,
  ECreateExchangeOfferOneError,
} from '../../../widgets/CreateOfferForm';
import { useTranslation } from 'react-i18next';

interface IProps {
  errors: (keyof typeof ECreateExchangeOfferErrors)[];
  pointName: string | undefined;
}

const CreateOfferErrorsList = ({ errors, pointName }: IProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {errors.length === 1 ? (
        <Typography.Text>
          {t(`shared:ошибки.${ECreateExchangeOfferOneError[errors[0]]}`)}
        </Typography.Text>
      ) : (
        <>
          <Typography.Text>
            Вы не можете создать оффер от пункта {pointName} по следующим
            причинам:
          </Typography.Text>
          <ul>
            {errors.map((error) => (
              <li key={error}>
                <Typography.Text>
                  {t(`shared:ошибки.${ECreateExchangeOfferErrors[error]}`)}
                </Typography.Text>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CreateOfferErrorsList;
