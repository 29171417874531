import styled from 'styled-components';
import { Link } from '@pankod/refine-react-router-v6';

export const ContainerStyled = styled.div<{ $bordered?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: ${({ $bordered }) => $bordered && '1px solid #0000000f'};
  border-radius: 12px;
  padding: ${({ $bordered }) => $bordered && '24px'};
`;
export const TitleStyled = styled.span`
  color: #000000e0;
  font-weight: 600;
  font-size: 14px;
`;
export const NameStyled = styled.span`
  color: #000000a6;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
`;
export const ValueStyled = styled.span`
  color: #000000e0;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const FieldStyled = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
`;
export const LinkStyled = styled(Link)`
  padding-inline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
