import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'antd';
import { MenuProps } from 'antd/es/menu/menu';
import styles from './index.module.css';
import { Button, ConfigProvider, Typography } from '@pankod/refine-antd';
import { DropdownIconSimpleColor } from '../../icons';
import Icon from '@ant-design/icons';
import './styles.css';
import styled from 'styled-components';
import { useResize } from '../../../hooks/useResize';

interface IDropdown extends DropdownProps {
  defaultValue: string;
  items: MenuProps['items'];
  currentValue: string;
  isActive?: boolean;
  content?: JSX.Element;
  isSelectable?: boolean;
  mode?: 'light' | 'dark';
}

const HeaderDropdown: React.FC<IDropdown> = ({
  mode,
  defaultValue,
  items,
  currentValue,
  isActive,
  content,
  isSelectable,
  ...rest
}) => {
  const [isClosed, setIsClosed] = useState(false);
  const { isScreen1024 } = useResize();

  const color = () => {
    return mode === 'light' ? '#fff' : 'rgba(0, 0, 0, 0.88)';
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inter, sans-serif',
          },
        }}
      >
        <div className={isActive ? styles.activeDropdown : ''}>
          <DropdownStyled
            {...rest}
            menu={{
              items,
              selectable: isSelectable,
              defaultSelectedKeys: [defaultValue],
            }}
            onOpenChange={() => {
              setIsClosed(!isClosed);
            }}
            trigger={isScreen1024 ? ['hover'] : ['click']}
          >
            {content ? (
              content
            ) : (
              <Button
                type="link"
                style={{
                  color: '#404348',
                  padding: '0px',
                  height: 32,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 3,
                  }}
                >
                  {content ? (
                    content
                  ) : (
                    <Typography.Text
                      style={{
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '120%',
                        color: color(),
                      }}
                    >
                      {currentValue}
                    </Typography.Text>
                  )}
                  <Icon
                    component={() =>
                      DropdownIconSimpleColor(
                        isClosed ? 'rgba(0, 0, 0, 0.88)' : undefined
                      )
                    }
                    style={{
                      transform: isClosed
                        ? 'matrix(1, 0, 0, -1, 0, 0)'
                        : undefined,
                      transition: 'transform 0.6s ease-out',
                    }}
                  />
                </div>
              </Button>
            )}
          </DropdownStyled>
        </div>
      </ConfigProvider>
    </>
  );
};

const DropdownStyled = styled(Dropdown)`
  .ant-dropdown-menu-item-selected {
    color: white;
  }
`;

export default HeaderDropdown;
