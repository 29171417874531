import {useParams} from "@pankod/refine-react-router-v6";
import {useMessagesState} from "../modules/ChatModule/states/useMessagesState";
import useOfferRequest from "../../pages/offer-request/model/hooks/useOfferRequest";
import {useEffect, useLayoutEffect, useState} from "react";
import {useChatTokensState} from "../state/useChatTokensState";
import ChatModule from "../modules/ChatModule/ChatModule";
import {calcAuthToken} from "../modules/ChatModule/helpers/calcAuthToken";
import {centrifuge, subscribe} from "../workers/chatWorker";
import {Subscription} from "centrifuge";
import {getCookieByName} from "../helpers/controlCookies";

const ChatOfferRequestWrapper = () => {
    const {id: requestId} = useParams();
    const phexChat = useMessagesState((state) => state.phexChat);
    const getPhexChat = useMessagesState((state) => state.getPhexChat);
    const getPhexChatToken = useChatTokensState((state) => state.getPhexChatToken);
    const getChatInfo = useMessagesState((state: { getChatInfo: any; }) => state.getChatInfo);
    const chatInfo = useMessagesState((state: { chatInfo: any; }) => state.chatInfo);
    const opponent = chatInfo?.participants?.find((participant: { is_opponent: any; }) => !!participant?.is_opponent);
    const wssChannels = useChatTokensState((state) => state.wssChannels);
    const getWSChatToken = useChatTokensState((state) => state.getWSChatToken);
    const searchMessages = useMessagesState((state) => state.searchMessages);
    const totalMessages = useMessagesState((state) => state.totalMessages);
    const currentPage = useMessagesState((state) => state.currentPage);
    const isFetchingMessages = useMessagesState((state) => state.isFetchingMessages);
    const messageLimit = useMessagesState((state) => state.messageLimit);
    const setIsFetchingMessages = useMessagesState((state) => state.setIsFetchingMessages);
    const addMessageToMessages = useMessagesState((state) => state.addMessageToMessages);
    const messages = useMessagesState((state) => state.messages);
    const createAttachment = useMessagesState((state) => state.createAttachment);
    const addMessage = useMessagesState((state) => state.addMessage);
    const changeIsUploadInAttachment = useMessagesState((state) => state.changeIsUploadInAttachment);
    const uploadAttachmentToMinio = useMessagesState((state) => state.uploadAttachmentToMinio);
    const addAttachmentToLastMessage = useMessagesState((state) => state.addAttachmentToLastMessage);
    const [hasReferee, setHasReferee] = useState(false);

    const {request} = useOfferRequest(Number(requestId));

    useLayoutEffect(() => {
        getPhexChatToken().then()
        getWSChatToken().then()
        if (request?.author_uid && requestId) {
            getPhexChat({
                offer_deal_id: String(requestId),
                auth_token: `Bearer ${getCookieByName('token')?.replace('Bearer', '')}`
            }).then()
            searchMessages({
                entity_type: 'offer_deal',
                entity_id: String(requestId),
                client_uid: request?.author_uid,
                auth_token: calcAuthToken({chatType: 'phex'})
            }).then();
            getChatInfo({
                entity_type: 'offer_deal',
                entity_id: String(requestId),
                client_uid: request?.author_uid,
                auth_token: calcAuthToken({chatType: 'phex'})
            }).then()
        }
    }, [request?.author_uid, requestId])

    useEffect(() => {
        if (chatInfo) {
            setHasReferee(chatInfo?.has_referee)
        }
    }, [chatInfo?.has_referee])

    useEffect(() => {
        const subscriptions: (Subscription | undefined)[] = [];
        const connectToChannels = async () => {
            if (wssChannels.length && requestId) {
                for (const channelName of wssChannels) {
                    const subscription = await subscribe({
                        channelName,
                        entityId: String(requestId),
                        hasReferee: hasReferee,
                        addMessageToMessages,
                        addAttachmentToLastMessage,
                        searchMessages: () => searchMessages({
                            entity_type: 'offer_deal',
                            entity_id: String(requestId),
                            client_uid: request?.author_uid,
                            auth_token: calcAuthToken({chatType: 'phex'})
                        })
                    })
                    // @ts-ignore
                    subscriptions.push(subscription);
                }
            }
        };
        connectToChannels().then();

        return () => {
            subscriptions?.forEach((subscription) => {
                subscription?.unsubscribe();
            });
        };
    }, [wssChannels, phexChat, hasReferee, centrifuge])


    useEffect(() => {
        if ((messageLimit * currentPage < totalMessages || currentPage === 1) && request?.author_uid) {
            if (requestId) {
                searchMessages({
                    entity_type: 'offer_deal',
                    entity_id: String(requestId),
                    client_uid: request?.author_uid,
                    auth_token: calcAuthToken({chatType: 'phex'})
                }).then()
            }
        }
    }, [currentPage, totalMessages, messageLimit, requestId, request?.author_uid]);

    useEffect(() => {
        if (centrifuge) {
            centrifuge?.connect();
        }
    }, [centrifuge])


    return (
        <ChatModule
            isShowTimer={false}
            isClosableChat={false}
            currentChat={phexChat}
            isReferee={chatInfo?.has_referee}
            setIsOpenChat={() => {
            }}
            opponent={opponent}
            currentPage={currentPage}
            setIsFetchingMessages={setIsFetchingMessages}
            searchMessages={searchMessages}
            messageLimit={messageLimit}
            totalMessages={totalMessages}
            isFetchingMessages={isFetchingMessages}
            messages={messages}
            addMessage={addMessage}
            changeIsUploadInAttachment={changeIsUploadInAttachment}
            createAttachment={createAttachment}
            uploadAttachmentToMinio={uploadAttachmentToMinio}
            calcEntityId={() => String(requestId)}
            entity_type={'offer_deal'}
            client_uid={phexChat?.author_uid}
            typeTabs={''}
            chatType={'phex'}
        />
    )
}

export default ChatOfferRequestWrapper;
