import BigNumber from 'bignumber.js';

export function truncateNumber(num: number | string, decimalPlaces: number): string {
  num = num && String(num).trim().replace(' ', '').replace(/\s/g, '').replace(',', '.');

  const bigNum = new BigNumber(num);

  if (!bigNum.isNaN() && decimalPlaces >= 0) {

    const truncatedNum = bigNum.toFixed(decimalPlaces);

    return truncatedNum.replace(' ', '');
  }

  return new BigNumber(0).toFixed(decimalPlaces);
}
