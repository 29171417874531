import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {axios} from '../exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {createOptions} from 'shared/helpers/script';

type TUserAccountState = {
  userAccountsData: any;
  userAccountsMeta: any;
  searchUserAccounts: ({setFilters}: any) => void;
  searchUserAccountsWithUid: ({setFilters, uid}: any) => void;
  setUserAccounts: ({
                      userAccountsData,
                      userAccountsMeta,
                    }: TSetUserAccountsProps) => void;
};

type TSetUserAccountsProps = {
  userAccountsData: any;
  userAccountsMeta: any;
};

export const useUserAccountState = create<TUserAccountState>()(
  devtools(
    (set, get) => ({
      userAccountsData: [],
      userAccountsMeta: {},
      searchUserAccountsWithUid: async ({setFilters, uid}: any) => {
        const response = await axios.post(`${API_URL}/user-accounts/search`, {
          limit: 10000,
          page: 1,
          filters: [
            {
              field: 'uid',
              operator: '=',
              value: uid,
            },
          ],
        });

        const options = createOptions(
          response.data.data,
          'useUserAccountsState'
        );
        localStorage.setItem('employees', JSON.stringify(options));
        setFilters({options, selectId: 'employees', isFromRequest: true});
        set(
          produce((draft) => {
            draft.setUserAccounts({
              userAccountsData: response?.data?.data,
              userAccountsMeta: response?.data?.meta,
              uid,
            });
          }),
          false,
          {
            type: 'useUserAccountState => searchUserAccountsWithUid',
          }
        );
      },
      searchUserAccounts: async ({setFilters}: any) => {
        const response = await axios.post(`${API_URL}/user-accounts/search`, {
          limit: 10000,
          page: 1,
        });
        const options = createOptions(
          response.data.data,
          'useUserAccountsState'
        );
        localStorage.setItem('employees', JSON.stringify(options));
        setFilters({options, selectId: 'employees', isFromRequest: true});
        set(
          produce((draft) => {
            draft.searchUserAccounts({
              userAccountsData: response?.data?.data,
              userAccountsMeta: response?.data?.meta,
            });
          }),
          false,
          {
            type: 'useUserAccountState => searchUserAccounts',
          }
        );
      },
      setUserAccounts: ({
                          userAccountsData,
                          userAccountsMeta,
                        }: TSetUserAccountsProps) =>
        set(
          produce((draft) => {
            draft.userAccountsData = userAccountsData;
            draft.userAccountsMeta = userAccountsMeta;
          }),
          false,
          {
            type: 'useUserAccountState => setUserAccounts',
          }
        ),
    }),
    {
      anonymousActionType: 'useUserAccountState action',
      name: 'useUserAccountState',
    }
  )
);
