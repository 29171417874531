const CheckAllIcon = ({checked = false}: { checked?: boolean }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.96963 4.96967C9.26253 4.67678 9.7374 4.67678 10.0303 4.96967C10.3196 5.25897 10.3231 5.72582 10.0409 6.01947L6.04873 11.0097C6.04297 11.0169 6.03682 11.0238 6.03029 11.0303C5.7374 11.3232 5.26253 11.3232 4.96963 11.0303L2.32319 8.38388C2.03029 8.09099 2.03029 7.61612 2.32319 7.32322C2.61608 7.03033 3.09095 7.03033 3.38385 7.32322L5.47737 9.41674L8.94974 4.9921C8.95592 4.98424 8.96256 4.97674 8.96963 4.96967Z"
            fill={checked ? 'rgba(88, 191, 146, 1)' : 'rgba(251, 251, 255, 1)'}
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.04921 10.1099L8.96963 11.0303C9.26253 11.3232 9.7374 11.3232 10.0303 11.0303C10.0368 11.0238 10.043 11.0169 10.0487 11.0097L14.0409 6.01947C14.3231 5.72582 14.3196 5.25897 14.0303 4.96967C13.7374 4.67678 13.2625 4.67678 12.9696 4.96967C12.9626 4.97674 12.9559 4.98424 12.9497 4.9921L9.47737 9.41674L8.99202 8.9314L8.04921 10.1099Z"
            fill={checked ? 'rgba(88, 191, 146, 1)' : 'rgba(251, 251, 255, 1)'}
        />
    </svg>
);

export {CheckAllIcon};
