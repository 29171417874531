import { ConfigProvider, MenuProps } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { ContainerStyled, DropdownStyled } from './LinksDropdown.styles';
import { linksDropdownThemes } from './themes';
import './styles.css';
import { DefaultIcon } from './DefaultIcon';
interface LinksDropdownProps {
  itemWidth?: number;
  items: MenuProps['items'];
  selected: string[];
  theme: keyof typeof linksDropdownThemes;
}
export const LinksDropdown = (props: LinksDropdownProps) => {
  const { itemWidth = 280, items = [], selected = [], theme } = props;
  const themes = {
    finms: {
      color: '#ffffff',
      hoverIcon: '#ffffff1a',
    },
    account: {
      color: '#ffffff',
      hoverIcon: '#ffffff1a',
    },
    finmsmap: {
      color: '#000000A6',
      hoverIcon: '#F6FAFF',
    },
    exchange: {
      color: '#000000A6',
      hoverIcon: '#F6FAFF',
    },
  };
  return (
    <ConfigProvider theme={linksDropdownThemes[theme]}>
      <DropdownStyled
        rootClassName={theme === 'account' ? '' : 'linkDropdown'}
        menu={{
          items: items?.map((item) => {
            if (item) {
              return {
                ...item,
                style: {
                  padding: '11px 16px',
                  minWidth: itemWidth,
                  borderBottom: '1px solid #474755',
                  borderRadius: '4px',
                  ...item.style,
                },
              };
            } else {
              return item;
            }
          }),
          selectable: false,
          defaultSelectedKeys: selected,
        }}
      >
        <ContainerStyled
          $hoverColor={themes[theme as keyof typeof themes].hoverIcon}
        >
          <DefaultIcon color={themes[theme as keyof typeof themes]?.color} />
        </ContainerStyled>
      </DropdownStyled>
    </ConfigProvider>
  );
};
