import {TFilterSetType, TFilterSimple, TFilterSimpleWithId} from "../../../pages/offers/model/types";
import {Select} from "@pankod/refine-antd";
import {DefaultTFuncReturn} from "i18next";
import {FilterContainerStyled, LabelStyled} from "./OfferFilter.styles";
import {SelectProps} from "rc-select/lib/Select";
import {useTranslation} from "react-i18next";
import type {CustomTagProps} from "rc-select/lib/BaseSelect";
import React from "react";
import BlueTag from "../../../shared/components/ui/tags/BlueTag";

interface OfferFilterProps {
  options: TFilterSimple[] | TFilterSimpleWithId[];
  setFilter: (values: number[] | string, type: TFilterSetType) => void;
  isMultiple?: boolean;
  nameDefaultProp?: DefaultTFuncReturn;
  isDisabled?: boolean;
  type: TFilterSetType;
  label?: DefaultTFuncReturn;
  search?:boolean;
}

type TOptions = { id: number, value: string, label: string, checked: boolean } | {
  id: string,
  value: string,
  label: string,
  checked: boolean
}
const tagRender = (props: CustomTagProps) => {
  const {label, closable, onClose} = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <BlueTag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{marginRight: 4}}
    >
      {label}
    </BlueTag>
  );
};
export const OfferFilter = (props: OfferFilterProps) => {
  const {options,search, setFilter, nameDefaultProp, isDisabled, type, label, isMultiple = false} = props;
  const {t} = useTranslation(['shared'])
  const opts: TOptions[] = [
    ...(nameDefaultProp ? [{
      id: 'all',
      value: nameDefaultProp,
      label: nameDefaultProp,
      checked: !options.some((option) => option.checked)
    }] : []),


    ...options.map(option => ({
      id: (option as TFilterSimpleWithId).id,
      value: option.name,
      label: option.t ? t(option.t + option.label) : option.label,
      checked: option.checked
  })) ]
  const handleChange = (value: string, option: TOptions | TOptions[]) => {
    if (isMultiple && Array.isArray(option)) {
      setFilter(
        option.slice(-1)[0]?.value === nameDefaultProp
          ? [Infinity]
          : option.map((opt: { id: number | string; }) => +opt.id ),
        type
      )
    } else if (!Array.isArray(option)) {
      setFilter(option.value, type)
    }
  };
  const selectedValue = opts.find((opt) => opt.checked)?.label || (nameDefaultProp && !options.some((opt) => opt.checked) ? "all" : undefined);
  const selectedValues = isMultiple && opts.filter(opt => opt.checked);
  return (
    <FilterContainerStyled>
      <LabelStyled>{label}</LabelStyled>
      <Select
        showSearch={search}
        value={isMultiple ? selectedValues as SelectProps['value'] : selectedValue}
        style={{width: '248px'}}
        onChange={handleChange}
        options={opts}
        mode={isMultiple ? 'multiple' : undefined}
        disabled={isDisabled}
        tagRender={tagRender}
        maxTagCount={'responsive'}
      />
    </FilterContainerStyled>
  )
}
