import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IOfferRequest as INormalizeOfferRequest } from './types';
import { produce } from 'immer';
import { IOfferRequest } from '../../../shared/types/offers';
import dayjs, { Dayjs } from 'dayjs';
import { IStaff } from '../../../shared/interfaces';
import { IAccount } from '../../ExchangePoints/interface';
import { useUserState } from '../../../shared/state/useUserState';

const INITIAL_DATE = dayjs().add(3, 'hour');

export type TRequestForConfirmData = Pick<
  IOfferRequest,
  | 'crypto_commission_amount'
  | 'commission_percent'
  | 'cash_account_owner_type'
  | 'cash_account_id'
  | 'cash_personal_user_account_uid'
> & {
  exchange_date: Dayjs;
  exchange_time: Dayjs;
  exchange_address: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  house_number: string | null;
  latitude: string | null;
  longitude: string | null;
};

interface IRequestConfirmStateProperties {
  requestData: TRequestForConfirmData | undefined;
  offerRequestStaticData: INormalizeOfferRequest | undefined;
  exchangePointStaffs: IStaff[];
  exchangePointAccounts: IAccount[];
  formErrors: {
    address: boolean;
  };
}

interface IRequestConfirmState extends IRequestConfirmStateProperties {
  setRequestDataFromOffer: (offerRequest: INormalizeOfferRequest) => void;
  setOfferRequestStaticData: (offerRequest: INormalizeOfferRequest) => void;
  changeRequestDataField: <
    T extends TRequestForConfirmData,
    K extends keyof TRequestForConfirmData
  >(
    field: K,
    value: T[K]
  ) => void;
  setStaffs: (staffs: IStaff[]) => void;
  setAccounts: (accounts: IAccount[]) => void;
  validateForm: () => boolean;
  resetForm: () => void;
}

const initialStateProperties: IRequestConfirmStateProperties = {
  requestData: {
    cash_account_owner_type: 'exchange_point',
    exchange_date: INITIAL_DATE,
    exchange_time: INITIAL_DATE,
    exchange_address: null,
    country: null,
    state: null,
    city: null,
    street: null,
    house_number: null,
    latitude: null,
    longitude: null,
  } as TRequestForConfirmData,
  formErrors: {
    address: false,
  },
  offerRequestStaticData: undefined,
  exchangePointStaffs: [],
  exchangePointAccounts: [],
};

export const useRequestConfirmState = create<IRequestConfirmState>()(
  devtools((set, get) => ({
    ...initialStateProperties,
    setRequestDataFromOffer: (offerRequest) => {
      set(
        produce((draft) => {
          draft.requestData.commission_percent = offerRequest.commissionPercent;
          draft.requestData.crypto_commission_amount =
            offerRequest.cryptoCommissionAmount;
          draft.requestData.country = offerRequest.offer?.countryCode;
          draft.requestData.state = offerRequest.offer?.state;
          draft.requestData.city = offerRequest.offer?.city;
        })
      );
    },

    setOfferRequestStaticData: (offerRequest) =>
      set({
        offerRequestStaticData: offerRequest,
      }),
    changeRequestDataField: (field, value) => {
      set(
        produce((draft) => {
          draft.requestData[field] = value;
        })
      );
    },
    setStaffs: (staffs) => {
      set(
        produce((draft) => {
          draft.exchangePointStaffs = staffs;

          if (staffs.length) {
            draft.requestData.cash_personal_user_account_uid =
              useUserState.getState().userApi.uid;
          }
        })
      );
    },
    setAccounts: (accounts) => {
      set(
        produce((draft) => {
          draft.exchangePointAccounts = accounts;

          if (!accounts.length) {
            draft.requestData.cash_account_owner_type = 'personal_account';
          } else {
            draft.requestData.cash_account_id = accounts[0].id;
          }
        })
      );
    },
    validateForm: () => {
      const errors = {
        address: !get().requestData?.exchange_address,
      };
      set({
        formErrors: errors,
      });

      return Object.values(errors).every((error) => !error);
    },

    resetForm: () => {
      set({
        ...initialStateProperties,
      });
    },
  }))
);
