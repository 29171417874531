export type formatSecondsType = {
  dDisplay: string;
  hDisplay: string;
  mDisplay: string;
};

export const formatSecondsToTime = (seconds: number): formatSecondsType => {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);

  const dDisplay = d.toString().padStart(2, '0');
  const hDisplay = h.toString().padStart(2, '0');
  const mDisplay = m.toString().padStart(2, '0');

  return {
    dDisplay: dDisplay,
    hDisplay: hDisplay,
    mDisplay: mDisplay,
  };
};

