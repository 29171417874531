import styled from "styled-components";
import {Table} from "antd";

export const ContainerStyled = styled.div`
`
export const EmptyOffersStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
  width: 100%;
  gap: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 16px;
  svg {
    height: 70px;
    width: 55px;
    color: rgba(0, 0, 0, 0.25);
  }
`

export const ButtonsContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
