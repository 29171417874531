import { axios } from '../../../../shared/exios';
import { API_URL } from '../../../../packages/keycloak-client/constants';

interface IGetCourse {
  primary_currency_key: string;
  secondary_currency_key: string;
}

export const getCourse = async ({
  primary_currency_key,
  secondary_currency_key,
}: IGetCourse): Promise<string | undefined> => {
  try {
    const params = new URLSearchParams({
      crypto_currency_code: primary_currency_key,
      cash_currency_code: secondary_currency_key,
    });

    const { data: coursesData } = await axios.get<{ exchange_rate: string }>(
      `${API_URL}/exchange-point-offers/get-exchange-rate`,
      {
        params,
      }
    );

    if (coursesData) {
      return coursesData.exchange_rate;
    }
  } catch (err) {
    console.error(err);
  }
  return undefined;
};
