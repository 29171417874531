import React, { useState } from 'react';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import { axios } from '../../../../../exios';
import { ModalKeys } from '../../model/enums';
import { Modal, Input } from 'antd';
import { useModalState } from '../../model/useModalState';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { Button, notification } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  CommentBlockStyled,
  CommentTitleStyled,
  DisabledReasonStyled,
  HelperTextStyled,
  ModalBodyStyled,
  ModalTitleStyled,
  ReasonContentStyled,
  ReasonTitleStyled,
} from './ModalCancelTransaction.styles';

export const ModalCancelTransaction = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const { TextArea } = Input;

  const [value, setValue] = useState('');

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  const navigate = useNavigate();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalCancelTransaction)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalCancelTransaction).options
  );

  const navigateToCurrentRequestHandler = () => {
    closeModal(ModalKeys.ModalCancelTransaction);
    navigate(`/offers/request/${modalOptions.id}`);
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      await axios.put(
        `${API_URL}/exchange-point-offer-requests/${modalOptions.id}/cancel`,
        {
          reason: value || '',
        }
      );
      modalOptions?.refetchData();
      notification.info({
        message: t(
          `${
            modalOptions.status === 'pending'
              ? 'Сделка отклонена'
              : 'Сделка отменена'
          }`
        ),
        icon: <InfoCircleFilled style={{ color: 'rgba(22, 119, 255, 1)' }} />,
        description: (
          <div>
            {t('Сделка')}
            <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
              {modalOptions.name}
            </ButtonAsLinkStyled>
            {t(
              `на ${modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'} USDT ${
                modalOptions.status === 'pending' ? 'отклонена' : 'отменена'
              }`
            )}
          </div>
        ),
        style: {
          borderLeft: '6px solid rgba(22, 119, 255, 1)',
          width: '400px',
        },
      });
    } catch {
      console.error('An error occurred while updating the transaction status');
      // notification.error({
      //   message: 'Заготовка для уведомления об ошибке',
      //   style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      // });
    } finally {
      closeModal(ModalKeys.ModalCancelTransaction);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalCancelTransaction);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={636}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled>
          {modalOptions.status === 'pending'
            ? t(
                `Отклонить сделку на ${
                  modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
                }`
              )
            : t(
                `Отменить сделку на ${
                  modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
                }`
              )}
          {modalOptions.status === 'ready'
            ? '?'
            : ' ' + modalOptions.name + '?'}
        </ModalTitleStyled>
        {modalOptions.status === 'pending' && (
          <>
            <CommentBlockStyled>
              <CommentTitleStyled>{t('Причина отмены')}</CommentTitleStyled>
              <TextArea
                showCount
                maxLength={5000}
                onChange={onChangeHandler}
                placeholder={String(t('shared:тексты.Введите комментарий'))}
                value={value}
              />
            </CommentBlockStyled>
            <HelperTextStyled>
              {t(
                'В случае отклонения запятая действия по сделке больше не будут доступны точка'
              )}
            </HelperTextStyled>
          </>
        )}

        {modalOptions.status === 'warranty_collateral' && (
          <>
            <CommentTitleStyled>
              {t(
                'Сделка будет отменена запятая вы не сможете вернуть её в работу точка Данное'
              )}
            </CommentTitleStyled>
            <DisabledReasonStyled>
              <ReasonTitleStyled>{t('Причина отмены')}</ReasonTitleStyled>
              <ReasonContentStyled>
                {t(
                  'Гарант не депонирован в установленное время не менее чем за 2 часа до встречи'
                )}
              </ReasonContentStyled>
            </DisabledReasonStyled>
          </>
        )}

        {modalOptions.status === 'ready' && (
          <>
            <CommentTitleStyled>
              {t('Сделка')}
              <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
                {modalOptions.name}
              </ButtonAsLinkStyled>
              {t(
                'будет отменена запятая вы не сможете вернуть её в работу точка Данное'
              )}
            </CommentTitleStyled>
            <DisabledReasonStyled>
              <ReasonTitleStyled>{t('Причина отмены')}</ReasonTitleStyled>
              <ReasonContentStyled>
                {t(
                  'Оплата не предоставлена вовремя не менее чем пол часа с момента назначенной встречи точка'
                )}
              </ReasonContentStyled>
            </DisabledReasonStyled>
          </>
        )}

        <ButtonGroupStyled>
          <Button onClick={handleCloseModal}>{t('shared:кнопки.Назад')}</Button>
          <Button onClick={handleChangeOfferRequestStatus} type="primary">
            {t(
              `${
                modalOptions.status === 'pending' ? 'Отклонить' : 'Отменить'
              } сделку`
            )}
          </Button>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </Modal>
  );
};
