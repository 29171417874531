import React from 'react';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import { axios } from '../../../../../exios';
import { ModalKeys } from '../../model/enums';
import { Modal } from 'antd';
import { useModalState } from '../../model/useModalState';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { Button, notification } from 'antd';
import { ExclamationCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import {
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalTitleStyled,
} from './ModalCancelTransactionFromConfirm.styles';

export const ModalCancelTransactionFromConfirm = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const navigate = useNavigate();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalCancelTransactionFromConfirm)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) =>
      state.getModal(ModalKeys.ModalCancelTransactionFromConfirm).options
  );

  const navigateToCurrentRequestHandler = () => {
    closeModal(ModalKeys.ModalCancelTransactionFromConfirm);
    navigate(`/offers/request/${modalOptions.id}`);
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      await axios.put(
        `${API_URL}/exchange-point-offer-requests/${modalOptions.id}/cancel`,
        {
          reason: '',
        }
      );
      notification.info({
        message: t('Сделка отклонена'),
        icon: <InfoCircleFilled style={{ color: 'rgba(22, 119, 255, 1)' }} />,
        description: (
          <div>
            {t('Сделка')}
            <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
              {modalOptions.name}
            </ButtonAsLinkStyled>
            {t(
              `на ${
                modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
              } отклонена`,
              { currency: modalOptions.currency }
            )}
          </div>
        ),
        style: {
          borderLeft: '6px solid rgba(22, 119, 255, 1)',
          width: '400px',
        },
      });
    } catch {
      console.error('An error occurred while updating the transaction status');
      // notification.error({
      //   message: 'Заготовка для уведомления об ошибке',
      //   style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      // });
    } finally {
      closeModal(ModalKeys.ModalCancelTransactionFromConfirm);
      navigate('/offers');
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalCancelTransactionFromConfirm);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={400}
      closable={false}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled>
          <ExclamationCircleOutlined
            style={{ fontSize: 24, color: '#FAAD14' }}
          />
          <span>{t('Отклонить сделку вопрос')}</span>
        </ModalTitleStyled>
        <ModalDescriptionStyled>
          {t(
            'Вы уверены запятая что хотите отклонить сделку вопрос Отменить данное действие будет невозможно точка'
          )}
        </ModalDescriptionStyled>
        <ButtonGroupStyled>
          <Button onClick={handleCloseModal}>
            {t('shared:кнопки.Отмена')}
          </Button>
          <Button onClick={handleChangeOfferRequestStatus} type="primary">
            {t(`Отклонить сделку`)}
          </Button>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </Modal>
  );
};
