import styled, { css } from 'styled-components';

export const TextWrapper = styled.div<{ $isExpanded: boolean; $rows: number }>`
  line-height: 1.5em;
  display: inline;
  white-space: pre-wrap;
  ${({ $isExpanded, $rows }) =>
    !$isExpanded &&
    css`
      overflow: hidden;
    `}
`;
