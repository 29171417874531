import styled from "styled-components";
import {Typography} from "antd";

export const ChatHeaderContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0 16px 24px;
    border-bottom: 1px solid #413D50;
`;

export const ChatHeaderSellerInfoStyled = styled.div`
    display: flex;
    gap: 8px;
`;

export const TextStyled = styled(Typography)`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FBFBFF;
`
