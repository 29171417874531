import styled from 'styled-components';
import { Typography } from 'antd';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`;
export const TitleStyled = styled(Typography.Title)`
  &&& {
    font-size: 24px;
    margin: 0;
  }
`;
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const GridRowStyled = styled.div`
  display: flex;
  justify-content: end;
`;
