import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IUseOfferInfoState, OfferStatusType } from './types';

export const useOfferInfoState = create<IUseOfferInfoState>()(
  devtools(
    (set, get) => ({
      isBuyingCrypto: false,
      setIsBuyingCrypto: (isBuyingCrypto: boolean) => {
        set({isBuyingCrypto: isBuyingCrypto})
      },
      offerStatus: 'active',
      setOfferStatus: (status: OfferStatusType) => {
        set({offerStatus: status})
      }
    })
  )
)
