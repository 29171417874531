import React from 'react';
import styled from 'styled-components';

const HeaderCellDescription: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }) => {
  return (
    <div>
      <MainTitle>{title}</MainTitle>
      <DescriptionTitle>{description}</DescriptionTitle>
    </div>
  );
};

export default HeaderCellDescription;

const MainTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
`;

const DescriptionTitle = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0;
`;
