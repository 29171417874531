import { useCustom } from '@pankod/refine-core';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { useState } from 'react';
import { IAccount } from '../../ExchangePoints/interface';
import { MetaType } from '../../accountsList/AccountType';

type PropsType = {
  id: string | undefined;
};

export const useAccount = ({ id }: PropsType) => {
  const [accountData, setAccountData] = useState<IAccount | null>(null);
  const [userCompanyData, setUserCompanyData] = useState<any>(null);

  const { refetch, isLoading, isFetching } = useCustom<{
    data: IAccount[];
    meta: MetaType;
  }>({
    url: `${API_URL}/accounts/search`,
    method: 'post',
    config: {
      payload: {
        filters: [
          {
            field: 'id',
            operator: '=',
            value: id,
          },
        ],
      },
    },
    queryOptions: {
      enabled: !!id,
      onSuccess: (res) => {
        setAccountData(res.data.data[0]);
      },
    },
  });

  const { data } = useCustom<{
    data: any;
    meta: MetaType;
  }>({
    url: `${API_URL}/me?include=role`,
    method: 'get',
    queryOptions: {
      enabled: !!id,
      onSuccess: (res: any) => {
        setUserCompanyData(res.data[0].company);
      },
    },
  });

  return {
    refetchAccountData: refetch,
    accountData,
    userCompanyData,
    isLoading,
    isFetching,
  };
};
