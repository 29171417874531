import {PropsWithChildren} from 'react';
import {ChatContainerStyled} from './assets/styles';
import {ChatProvider} from '../context';
import {IChatRepository, IChatSocket} from '../../interfaces';

interface IChatContainerProps extends PropsWithChildren {
}

const ChatContainer = (props: IChatContainerProps) => {
    return (
        <ChatProvider socket={{} as IChatSocket} repository={{} as IChatRepository}>
            <ChatContainerStyled>
                {props.children}
            </ChatContainerStyled>
        </ChatProvider>
    );
};

export {ChatContainer};
