import { Tabs } from 'antd';
import { Button } from '@pankod/refine-antd';
import { GridRowStyled } from './Offers.styles';
import { useTranslation } from 'react-i18next';
import { TNavigation } from '../model/types';
import { DownOutlinedStyled } from './NavigationTabs.styles';

interface NavigationTabsProps {
  isFiltersCollapsed: boolean;
  activeTab: TNavigation;
  setIsCollapsed: (value: boolean) => void;
  setActiveTab: (value: TNavigation) => void;
}

export const NavigationTabs = (props: NavigationTabsProps) => {
  const { setIsCollapsed, isFiltersCollapsed, activeTab, setActiveTab } = props;
  const { t } = useTranslation(['shared']);
  const handleCollapse = () => {
    setIsCollapsed(!isFiltersCollapsed);
  };
  const handleTab = (value: string) => {
    setActiveTab(value as TNavigation);
  };
  const tabs = [
    {
      label: t('shared:тексты.Офферы'),
      key: 'offers',
      children: '',
      onClick: null,
    },
    {
      label: t('shared:тексты.Сделки на обмен'),
      key: 'exchange',
      children: '',
      onClick: null,
    },
  ];
  return (
    <GridRowStyled>
      {/* <Tabs
        onChange={handleTab}
        size="large"
        items={tabs}
        activeKey={activeTab}
      /> */}
      {activeTab === 'offers' && (
        <Button
          onClick={handleCollapse}
          icon={
            <DownOutlinedStyled
              rotate={isFiltersCollapsed && activeTab === 'offers'}
            />
          }
          type="text"
        >
          {t('shared:тексты.Все фильтры')}
        </Button>
      )}
      {activeTab === 'exchange' && (
        <Button
          onClick={handleCollapse}
          icon={
            <DownOutlinedStyled color={'rgba(0, 0, 0, 0.25)'} rotate={true} />
          }
          type="text"
        >
          {t('shared:тексты.Все фильтры')}
        </Button>
      )}
    </GridRowStyled>
  );
};
