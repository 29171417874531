import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Draft, produce } from 'immer';
import {
  TActiveFilter,
  TFiltersData,
  TFilterSetType,
  TFilterSimple,
  TFilterSimpleWithId,
  TOffers,
  TOffersData,
} from './types';
import { IExchangePoint, IMeta } from '../../../interfaces';

interface IOfferState {
  isFixCost: boolean;
  isFiltersCollapsed: boolean;
  directionFilters: TFilterSimple[];
  statusFilters: TFilterSimple[];
  clientsFilters: TFilterSimple[]
  accountsFilters: TFilterSimple[]
  fiatCurrFilters: TFilterSimple[];
  ccyCurrFilters: TFilterSimple[];
  pointsFilters: TFilterSimpleWithId[];
  resetAll: () => void;
  setFilterInitial: (data: TFiltersData[]) => void;
  setFilter: (values: number[] | string, type: TFilterSetType) => void;
  setFilterCollapsed: (values: number | string, type: TFilterSetType) => void;
  setCost: (value: boolean) => void;
  setIsCollapsed: (value: boolean) => void;
  setOffers: (offers: TOffersData) => void;
  offers: TOffers[];
  setMeta: (value: number) => void;
  meta: IMeta;
  points: IExchangePoint[];
  recalcActiveFilters: () => void;
  activeFilters: TActiveFilter[];
}

export const useOffersState = create<IOfferState>()(
  devtools((set, get) => ({
    offers: [],
    meta: {} as IMeta,
    activeFilters: [],
    points: [],
    isFixCost: false,
    isFiltersCollapsed: true,
    directionFilters: [
      {
        name: 'buy',
        label: 'Покупка',
        t: 'shared:тексты.',
        checked: false,
      },
      {
        name: 'sell',
        label: 'Продажа',
        t: 'shared:тексты.',
        checked: false,
      },
    ],
    statusFilters: [
      {
        name: 'active',
        label: 'Активен',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        name: 'done',
        label: 'Закрыт',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        name: 'declined',
        label: 'Отменен',
        t: 'shared:статусы.',
        checked: false,
      },
    ],
    pointsFilters: [],
    clientsFilters: [],
    accountsFilters: [],
    fiatCurrFilters: [],
    ccyCurrFilters: [
      {
        name: 'USDTTRC20',
        checked: true,
        label: 'USDT',
      },
    ],
    resetAll: () =>
      set(
        produce((draft: Draft<IOfferState>) => {
          draft.directionFilters = draft.directionFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.statusFilters = draft.statusFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.pointsFilters = draft.pointsFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.fiatCurrFilters = draft.fiatCurrFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.isFixCost = false;
          // draft.ccyCurrFilters = draft.ccyCurrFilters.map(el => ({
          //   ...el,
          //   checked: false
          // }))
        }),
        false,
        {
          type: 'useOffersState => resetAll',
        }
      ),
    setFilterInitial: (data) =>
      set(
        produce((draft: Draft<IOfferState>) => {
          data.forEach((obj) => {
            if (obj.type === 'points') {
              const selectedFilters = draft.pointsFilters
                .filter((point) => point.checked)
                .map((point) => point.id);
              draft.points = obj.arr;
              draft.pointsFilters = obj.arr.map((point) => ({
                id: point.id,
                name: point.name,
                label: point.name,
                checked: selectedFilters.includes(point.id),
              }));
            }
            if (obj.type === 'fiat') {
              const fiatCurrFilters = draft.fiatCurrFilters
                .filter((fiatCurr) => fiatCurr.checked)
                .map((fiatCurr) => fiatCurr.name);
              draft.fiatCurrFilters = obj.arr.map((fiat) => ({
                name: fiat.code,
                label: fiat.currency_key,
                checked: fiatCurrFilters.includes(fiat.code),
              }));
            }
            // if (obj.type === 'ccy') {
            //   draft.ccyCurrFilters = obj.arr.map(ccy => ({
            //       name: ccy.code,
            //       label: ccy.currency_key,
            //       checked: false
            //     }))
            //
            // }
          });
        }),
        false,
        {
          type: 'useOffersState => setFilters',
        }
      ),
    setFilter: (value, type) =>
      set(
        produce((draft: Draft<IOfferState>) => {
          if (type === 'pointsFilters' && Array.isArray(value)) {
            draft.pointsFilters = draft.pointsFilters.map((elem) => ({
              ...elem,
              checked: value.includes(elem.id),
            }));
          } else if (type !== 'pointsFilters') {
            draft[type] = draft[type].map((elem) => ({
              ...elem,
              checked: elem.name === value,
            }));
          }
        }),
        false,
        {
          type: 'useOffersState => setFilter',
        }
      ),
    setFilterCollapsed: (value, type) =>
      set(
        produce((draft: Draft<IOfferState>) => {
          if (type === 'pointsFilters') {
            draft.pointsFilters = draft.pointsFilters.map((elem) => ({
              ...elem,
              checked: elem.id === value ? false : elem.checked,
            }));
          }
          if (type !== 'pointsFilters') {
            draft[type] = draft[type].map((elem) => ({
              ...elem,
              checked: elem.name === value ? false : elem.checked,
            }));
          }
        }),
        false,
        {
          type: 'useOffersState => setFilter',
        }
      ),
    setCost: (value: boolean) => {
      set({ isFixCost: value });
    },
    setIsCollapsed: (value: boolean) => {
      set({ isFiltersCollapsed: value });
    },
    setOffers: (offers) =>
      set(
        produce((draft) => {
          draft.offers = offers.data;
          draft.meta = {
            ...draft.meta,
            total: offers.meta.total,
            last_page: offers.meta.last_page,
          };
        })
      ),
    setMeta: (pagination) =>
      set(
        produce((draft) => {
          draft.meta = { ...draft.meta, current_page: pagination };
        })
      ),
    recalcActiveFilters: () =>
      set(
        produce((draft: Draft<IOfferState>) => {
          const arr = [];
          const ccy = draft.ccyCurrFilters.find((cur) => cur.checked);
          if (ccy) {
            arr.push({
              field: 'crypto_currency_code',
              operator: '=',
              value: ccy.name,
            });
          }
          const cash = draft.fiatCurrFilters.find((cur) => cur.checked);
          if (cash) {
            arr.push({
              field: 'cash_currency_code',
              operator: '=',
              value: cash.name,
            });
          }
          // const status = draft.statusFilters.filter(st => st.checked).map(st => st.name)
          // if(status.length){
          //   arr.push({field: "status",
          //     operator: "in",
          //     value: status
          //   })
          // }
          // const status = draft.statusFilters.filter((st: { checked: boolean; }) => st.checked).map((st: { name: string; }) => st.name)
          // if(status.length){
          //   arr.push({field: "status",
          //     operator: "in",
          //     value: status
          //   })
          // }
          const status = draft.statusFilters.find((st) => st.checked);
          if (status) {
            arr.push({ field: 'status', operator: '=', value: status.name });
          }
          const points = draft.pointsFilters
            .filter((st) => st.checked)
            .map((st) => st.id);
          if (points.length) {
            arr.push({
              field: 'exchange_point_id',
              operator: 'in',
              value: points,
            });
          }
          const direction = draft.directionFilters.find((cur) => cur.checked);
          if (direction) {
            arr.push({
              field: 'is_buying_crypto',
              operator: '=',
              value: direction.name === 'buy',
            });
          }
          const isFixCost = draft.isFixCost;
          if (isFixCost) {
            arr.push({
              field: 'crypto_commission_amount',
              operator: '=',
              value: '0',
            });
          }
          draft.meta.current_page = 1;
          draft.activeFilters = arr;
        })
      ),
  }))
);
