import { getTime } from './getTime';

export const getTimeWithDate = (date: Date) => {
  const leadingZero = (value: number) => (value >= 10 ? value : `0${value}`);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${leadingZero(+day)}.${leadingZero(+month)}.${year} ${getTime(date)}`;
};
