import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {axios} from '../exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {createOptions} from 'shared/helpers/script';

type TAccountState = {
  accountsData: any;
  accountsMeta: any;
  searchAccounts: ({setFilters}: any) => void;
  searchAccountsForExchangePoints: () => any;
  setAccounts: ({accountsData, accountsMeta}: TSetAccountsProps) => void;
};

type TSetAccountsProps = {
  accountsData: any;
  accountsMeta: any;
};

export const useAccountState = create<TAccountState>()(
  devtools(
    (set, get) => ({
      accountsData: [],
      accountsMeta: {},
      searchAccounts: async ({setFilters}: any) => {
        const response = await axios.post(`${API_URL}/accounts/search`, {
          limit: 10000,
          page: 1,
        });
        const options = createOptions(response.data.data, 'useAccountState');
        localStorage.setItem('accounts', JSON.stringify(options));
        setFilters({options, selectId: 'accounts', isFromRequest: true});
        set(
          produce(async (draft) => {
            draft.setAccounts({
              accountsData: response.data.data,
              // @ts-ignore
              accountsMeta: response.data.meta,
            });
          }),
          false,
          {
            type: 'useAccountState => searchAccounts',
          }
        );
      },
      searchAccountsForExchangePoints: () => {
        return axios
          .post(`${API_URL}/accounts/search`, {
            limit: 10000,
            page: 1,
          })
          .then((response) => {
            const options: { key: number; label: string; value: number }[] =
              createOptions(
                response.data.data,
                'useAccountStateForExchangePoints'
              );
            return options;
          })
          .catch((error) => {
            console.error('Error fetching accounts:', error);
          });
      },
      setAccounts: ({accountsData, accountsMeta}: TSetAccountsProps) => {
        set(
          produce((draft) => {
            draft.accountsData = accountsData;
            draft.accountsMeta = accountsMeta;
          }),
          false,
          {
            type: 'useAccountState => setAccounts',
          }
        );
      },
    }),
    {
      anonymousActionType: 'useAccountState action',
      name: 'accountState',
    }
  )
);
