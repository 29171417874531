import styled from "styled-components";
import { Button, Tabs } from '@pankod/refine-antd';
import { Breadcrumb } from 'antd';

export const OfferBreadcrumbStyled = styled(Breadcrumb)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
`
export const OfferContainerStyled = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: none;
  background-color: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 24px;
`
export const OfferHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 4px;
`
export const OfferTitleStyled = styled.div`
  color: rgba(0, 0, 0, 0.88);
  font-size: 24px;
  font-weight: 600;
`
export const OfferActionButtonStyled = styled(Button)`
  margin-left: auto;
`
export const OfferIndexStyled = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  margin-bottom: 24px;
`
export const OfferBodyStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  > div:nth-child(2) {
    width: 28%;
  }
  margin-bottom: 32px;
`;
export const OfferColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 36%;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
    > div:nth-child(1) {
        margin-bottom: 8px;
    }
`
export const OfferRowStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
export const OfferCommentRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  > div:nth-child(2) {
      word-wrap: anywhere;
  }
`
export const ButtonStyled = styled(Button)`
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
  height: 20px;
`
export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
  }
`
export const EmptyOfferTransactionsStyled = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`
export const EmptyOfferTitleStyled = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
`

export const WrapperTableStyled = styled.div`
  padding-top: 22px;

  .ant-table {
    border-radius: 24px;
  }

  table {
    border: none;

    thead tr:last-child th {
        border-radius: 0px !important;
    }

    tbody tr:last-child td {
      border-bottom: none;
      border-bottom-right-radius: 20px;
    }
  }
`

export const WrapperTabsStyled = styled.div`
  margin-top: -44px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-top: none;
  background-color: white;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`

export const PaginationWrapper = styled.div`
  margin-top: 20px;

  .ant-pagination {
    display: flex;
    justify-content: flex-end;
  }
`;

export const LabelTabStyled = styled.span`
  display: inline-block;
  width: 150px;
  text-align: center;
`;

export const WrapperLoaderStyled = styled.div`
  height: 140px;
`;
