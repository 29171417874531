import {TVChartContainer} from './ui/TVChartContainer';
import styled from 'styled-components';
import FakeAntLabel from '../../shared/components/ui/labels/FakeAntLabel';
import {Row, Select, Space} from '@pankod/refine-antd';
import {AdaptiveFullScreenLoader} from '../../shared/components/full-screen-loader';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useCurrencyPairs from '../currencyPairs/useCurrencyPairs';
import {customInfinityScroll} from '../../shared/helpers/script';
import {DEFAULT_NUMBER, DEFAULT_SEARCH_PAGE_AMOUNT,} from '../../shared/helpers/constants';
import {CurrencyType} from '../currencyPairs/CurrencyType';
import {axios} from '../../shared/exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useChartState} from './model/chartState';
import CourseHistoryBreadcrumb from './ui/CourseHistoryBreadcrumb';
import { useCookiesCustom } from '../../shared/hooks/useCookiesCustom';

const SelectOption = (item: CurrencyType) => {
  return (
    <p style={{margin: 0, color: 'rgba(0, 0, 0, 0.88)'}}>
      {item.status === 'active' ? (
        <CheckCircleFilled style={{color: 'rgba(19, 194, 194, 1)'}}/>
      ) : (
        <CloseCircleFilled style={{color: '#FF7875'}}/>
      )}
      <span style={{marginLeft: '8px', fontWeight: 600}}>
        {item.shortname}
      </span>
      <span style={{margin: 0, fontSize: '12px'}}> {item.name}</span>
    </p>
  );
};

const CourseHistory = () => {
  const {cookie} = useCookiesCustom();
  const {t} = useTranslation(['currencyPairs', 'shared']);
  const mainCurrencySelect = useChartState((state) => state.mainCurrencySelect);
  const lastCurrencySelect = useChartState((state) => state.lastCurrencySelect);
  const setSelectState = useChartState((state) => state.setSelectState);
  const resetState = useChartState((state) => state.resetState);

  const [allCurrencies, setAllCurrencies] = useState<CurrencyType[]>([]);
  const [allCurrenciesPage, setAllCurrenciesPage] = useState(DEFAULT_NUMBER);

  const {
    companyCurrency,
    secondaryCurrenciesData,
    isSecondaryCurrencyFetching,
    isAllSecondaryCurrencyFetched,
  } = useCurrencyPairs({
    page: mainCurrencySelect.page,
    currencyKey: mainCurrencySelect.currencyKey,
    secondaryCurrencyKey: lastCurrencySelect.currencyKey,
    secondarySearch: lastCurrencySelect.search,
    secondaryCurrencyPage: lastCurrencySelect.page,
    showUnusedCurrencies: false,
  });

  const fetchAllCurrencies = useMemo(async () => {
    setSelectState('main', 'isLoading', true);
    try {
      const filters = mainCurrencySelect.search
        ? []
        : [
          {
            field: 'id',
            operator: '!=',
            value: companyCurrency?.id || '',
          },
        ];

      const response = await axios.post(`${API_URL}/currency/search`, {
        page: allCurrenciesPage,
        limit: 15,
        search: {value: mainCurrencySelect.search, case_sensitive: false},
        filters,
      }, {
        headers: {
          Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}`,
        },
      });
      const currencies = response.data.data.map((item: CurrencyType) => ({
        label: SelectOption(item),
        value: item.shortname,
        key: item.id,
      }));
      const companyCurrencySelect = {
        label: companyCurrency ? SelectOption(companyCurrency) : '',
        value: companyCurrency?.shortname,
        key: companyCurrency?.id,
      };
      if (allCurrenciesPage === DEFAULT_NUMBER) {
        !!mainCurrencySelect.search
          ? setAllCurrencies(currencies)
          : setAllCurrencies([companyCurrencySelect, ...currencies]);
        setSelectState('main', 'total', response.data.meta.total);
      } else {
        setAllCurrencies((prevCurrencies) => [
          ...prevCurrencies,
          ...currencies,
        ]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSelectState('main', 'isLoading', false);
    }
  }, [
    SelectOption,
    allCurrenciesPage,
    mainCurrencySelect.search,
    companyCurrency,
  ]);

  const onMainCurrencyClick = useCallback(async () => {
    !allCurrencies.length && (await fetchAllCurrencies);
  }, [allCurrencies, fetchAllCurrencies]);

  const onChangeMainCurrency = (value: string) => {
    setSelectState('main', 'page', 1);
    setSelectState('main', 'currencyKey', value);
  };

  const clearPrimarySearch = () => {
    setAllCurrenciesPage(1);
    setSelectState('main', 'search', '');
  };

  let searchTimer: ReturnType<typeof setTimeout>;
  const onMainCurrencySearch = (value: string) => {
    clearTimeout(searchTimer);
    if (value === '') {
      setAllCurrenciesPage(1);
      setSelectState('main', 'search', value);
      return;
    }
    searchTimer = setTimeout(() => {
      setAllCurrenciesPage(1);
      setSelectState('main', 'search', value);
    }, 1000);
  };

  const onMainCurrencyScroll = useCallback(
    async (event: any) => {
      customInfinityScroll(event) &&
      mainCurrencySelect.total >
      DEFAULT_SEARCH_PAGE_AMOUNT * allCurrenciesPage &&
      setAllCurrenciesPage(allCurrenciesPage + 1);
      if (allCurrenciesPage !== DEFAULT_NUMBER) {
        await fetchAllCurrencies;
      }
    },
    [allCurrenciesPage, fetchAllCurrencies, mainCurrencySelect.total]
  );
  let searchSecondaryTimer: ReturnType<typeof setTimeout>;
  const onSecondaryCurrencySearch = (value: string) => {
    clearTimeout(searchSecondaryTimer);
    if (value === '') {
      setSelectState('last', 'page', 1);
      setSelectState('last', 'search', value);
      return;
    }
    searchSecondaryTimer = setTimeout(() => {
      setSelectState('last', 'page', 1);
      setSelectState('last', 'search', value);
    }, 1000);
  };

  const onSecondaryCurrencyScroll = useCallback(
    (event: any) => {
      customInfinityScroll(event) &&
      !isAllSecondaryCurrencyFetched &&
      !isSecondaryCurrencyFetching &&
      setSelectState('last', 'page', lastCurrencySelect.page + 1);
    },
    [isSecondaryCurrencyFetching, isAllSecondaryCurrencyFetched]
  );

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);
  return (
    <CourseHistoryWrapper>
      <CourseHistoryBreadcrumb/>
      <CourseHistoryBody>
        <Space size={16}>
          <SelectWrapper>
            <FakeAntLabel
              htmlFor="main_currency"
              title="main_currency"
              text={t('shared:тексты.Основная валюта')}
            />
            <Select
              size={'middle'}
              loading={mainCurrencySelect.isLoading}
              onClick={onMainCurrencyClick}
              onChange={onChangeMainCurrency}
              showSearch
              id="main_currency"
              onBlur={clearPrimarySearch}
              onSearch={onMainCurrencySearch}
              filterOption={(input, option) => true}
              onPopupScroll={onMainCurrencyScroll}
              style={{width: 260, fontWeight: 600}}
              options={allCurrencies}
              defaultValue={mainCurrencySelect.currencyKey}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {mainCurrencySelect.isLoading && (
                    <Row style={{padding: '8px 0 2px'}}>
                      <AdaptiveFullScreenLoader/>
                    </Row>
                  )}
                </>
              )}
            />
          </SelectWrapper>
          <SelectWrapper>
            <FakeAntLabel
              htmlFor="secondary_currency"
              title="secondary_currency"
              text={t('Дополнительная валюта')}
            />
            <Select
              onChange={(value) => setSelectState('last', 'currencyKey', value)}
              allowClear
              id="secondary_currency"
              showSearch
              loading={isSecondaryCurrencyFetching}
              onSearch={onSecondaryCurrencySearch}
              filterOption={() => true}
              onPopupScroll={onSecondaryCurrencyScroll}
              style={{width: 260, fontWeight: 600}}
              options={secondaryCurrenciesData.map((item) => ({
                label: SelectOption(item as any),
                value: item.shortname,
                key: item.id,
              }))}
              defaultValue={lastCurrencySelect.currencyKey}
              placeholder={t('shared:тексты.Выберите валюту')}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {isSecondaryCurrencyFetching && (
                    <Row style={{padding: '8px 0 2px'}}>
                      <AdaptiveFullScreenLoader/>
                    </Row>
                  )}
                </>
              )}
            />
          </SelectWrapper>
        </Space>
        <ChartWrapper>
          <TVChartContainer/>
        </ChartWrapper>
      </CourseHistoryBody>
    </CourseHistoryWrapper>
  );
};

export default CourseHistory;

const CourseHistoryWrapper = styled.div`
  height: 100%;
`;

const CourseHistoryBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-select-open .ant-select-selection-item p {
    opacity: 0.5;
  }
`;
